import { all, fork, Effect, take } from "redux-saga/effects";
import { UsersAuthData } from "@nagano_crm/shared/auth/user.interface";
import { PayloadAction } from "typesafe-actions";

import { ordersSaga } from "./order/order.saga";
import { authSagas } from "./auth/auth.saga";
import { nomenclatureSaga } from "./nomenclatures/nomenclatures.saga";
import { errorSaga } from "./error/error.saga";
import { customerSaga } from "./customer/customer.saga";
import { userSagas } from "./user/user.saga";
import { setIsAuth } from "./auth/auth.actions";
import { kitchenSaga } from "./kitchen/kitchen.saga";

import { versionInfo } from "~/app/utils/version";

function* runCrmSaga(): Generator {
  while (true) {
    const { payload }: PayloadAction<string, UsersAuthData> = yield take(setIsAuth);

    if (payload) {
      yield all([
        fork(authSagas),
        fork(userSagas),
        fork(ordersSaga),
        fork(nomenclatureSaga),
        fork(customerSaga),
        fork(errorSaga)
      ]);
    }
  }
}

export default function* rootSaga(): Generator<Effect, void, void> {
  console.info(versionInfo());
  yield all([fork(authSagas), fork(runCrmSaga), fork(kitchenSaga)]);
}
