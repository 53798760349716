import { Effect, all, call, cancel, cancelled, put, take, fork, takeEvery } from "redux-saga/effects";
import { EventChannel, Action, Task } from "redux-saga";
import { Socket } from "socket.io-client";
import { InitCoreUser, UserBase, UserCreate, UserUpdate } from "@nagano_crm/shared/auth/user.interface";
import { PayloadAction } from "@reduxjs/toolkit";

import { createSocketEventsUser, setUserSocket, userSocket } from "./user.socket";
import userActions from "./user.actions";
import userService from "./user.service";

function* initCore(): Generator {
  const { users, roles }: InitCoreUser = (yield call(userService.initCore)) as InitCoreUser;

  yield put(userActions.setUserList(users));
  yield put(userActions.setRoleList(roles));
}

function* socketWatchers(): Generator {
  const socketEventsUser: EventChannel<any> = (yield call(createSocketEventsUser)) as EventChannel<any>;

  while (true) {
    const action: Action<any> = (yield take(socketEventsUser)) as Action<any>;

    yield put(action);
  }
}

function* connectSocketUserSaga(): Generator<Effect, void, Task | Socket> {
  let task: Task | null = null;

  try {
    yield call(setUserSocket);

    task = (yield fork(socketWatchers)) as Task;
  } finally {
    if (yield cancelled()) {
      userSocket.close();
      if (task !== null) {
        yield cancel(task);
      }
    }
  }
}

function* updateUser(data: UserUpdate): Generator {
  const user: UserUpdate | undefined = (yield call(userService.update, data)) as UserUpdate | undefined;

  if (user) {
    yield put(userActions.successUpdateUser(user));
  }
}

function* createUser(data: UserCreate): Generator {
  const user: UserBase | undefined = (yield call(userService.create, data)) as UserBase | undefined;

  if (user) {
    yield put(userActions.successCreateUser(user));
  }
}

function* deleteUser(id: string): Generator {
  const result: { status: string; data: any } = (yield call(userService.deleteOne, id)) as {
    status: string;
    data: any;
  };

  if (result.status === "ok") {
    yield put(userActions.handleDeleteUser(id));
  }
}

function* userWatchers(): Generator {
  yield all([
    takeEvery(userActions.handleConnectSocket, () => initCore()),
    takeEvery(userActions.createUser, ({ payload }: PayloadAction<UserCreate, any>) => createUser(payload)),
    takeEvery(userActions.updateUser, ({ payload }: PayloadAction<UserUpdate>) => updateUser(payload)),
    takeEvery(userActions.deleteUser, ({ payload }: PayloadAction<string>) => deleteUser(payload))
  ]);
}

export function* userSagas(): Generator {
  yield all([fork(connectSocketUserSaga), fork(userWatchers)]);
}
