import { Selector, createSelector } from "reselect";
import { ORDER_STATUSES, OrderBase, OrderList } from "@nagano_crm/shared/orders/order.types";
import { OrderNomenclatureBase } from "@nagano_crm/shared/orders/order-nomenclatures.types";

import { ApplicationStore } from "../application.store";

export const selectOrdersForKitchen: () => Selector<ApplicationStore, string[]> = () =>
  createSelector(
    (state: ApplicationStore): OrderList => state.kitchen.list,
    orders => {
      return orders.filter(order => order.status === ORDER_STATUSES.IN_PROGRESS).map(order => order.uid);
    }
  );

export const selectOrdersKitchenInWork: () => Selector<ApplicationStore, string[]> = (): Selector<
  ApplicationStore,
  string[]
> =>
  createSelector(
    (state: ApplicationStore) => state.kitchen.ordersInWork,
    ordersInWork => {
      return [...ordersInWork];
    }
  );

export const selectReadyNomenclatures: () => Selector<
  ApplicationStore,
  { [orderUids: string]: string[] }
> = (): Selector<ApplicationStore, { [orderUids: string]: string[] }> =>
  createSelector(
    (state: ApplicationStore) => state.kitchen.readyNomenclatures,
    readyNomenclatures => {
      return { ...readyNomenclatures };
    }
  );

export const selectOrderBySelectOrderUid: Selector<ApplicationStore, Partial<OrderBase> | null> = createSelector(
  [
    (state: ApplicationStore): OrderList => state.order.list,
    (state: ApplicationStore): string | null => state.order.currentOrderUid
  ],
  (orders, currentOrderUid) => {
    if (currentOrderUid === null) {
      return null;
    }

    const order: OrderBase | undefined = orders.find(order => order.uid === currentOrderUid);

    if (order === undefined) {
      return {};
    }

    return order;
  }
);

export const selectOrderKitchenNomenclatures: (
  orderUid: string
) => Selector<ApplicationStore, OrderNomenclatureBase[] | null> = orderUid =>
  createSelector(
    (state: ApplicationStore): OrderList => state.kitchen.list,
    orders => {
      if (orderUid === null) {
        return null;
      }

      const order: OrderBase | undefined = orders.find(order => order.uid === orderUid);

      if (order === undefined) {
        return null;
      }

      return (order.nomenclatures as OrderNomenclatureBase[]) || [];
    }
  );

export const makeSelectKitchenOrderByOrderUid: () => Selector<ApplicationStore, OrderBase | undefined> = (): Selector<
  ApplicationStore,
  OrderBase | undefined
> =>
  createSelector(
    [
      (state: ApplicationStore): OrderList => state.kitchen.list,
      (_: ApplicationStore, orderUid: string): string => orderUid
    ],
    (orders: OrderList, orderUid: string) => orders.find(order => order.uid == orderUid)
  );
