import { UserApi, AUTH_ROUTES } from "@nagano_crm/shared/auth/user.api";
import { UserData, UsersAuthData } from "@nagano_crm/shared/auth/user.interface";

import { apiFetch } from "~/app/utils/api-fetch";

class UserService implements UserApi {
  me(this: void): Promise<UserData> {
    return apiFetch(AUTH_ROUTES.me());
  }

  auth(this: void, userAuthData: UsersAuthData): Promise<void> {
    return apiFetch(AUTH_ROUTES.auth(), { body: JSON.stringify(userAuthData), method: "POST" });
  }

  register(this: void, userAuthData: UsersAuthData): Promise<void> {
    return apiFetch(AUTH_ROUTES.register(), { body: JSON.stringify(userAuthData), method: "POST" });
  }
}

export const userService: UserService = new UserService();
