import { eventChannel } from "redux-saga";
import { Socket } from "socket.io-client";
import { CustomerBase } from "@nagano_crm/shared/customer/customers.types";

import { setSocketErrorAction } from "../error/error.actions";
import { SocketError } from "../error/error.store";
import { manager } from "../socket.utils";

import customerActions from "./customer.actions";

export const createSocketEventsCustomer: any = (): any =>
  eventChannel(emit => {
    socketCustomer.on("connect", () => {
      emit(customerActions.handleConnectedCustomerSocket());
    });

    socketCustomer.on("createOne", (data: CustomerBase) => {
      emit(customerActions.handleCustomerCreate(data));
    });

    socketCustomer.on("updateOne", (data: CustomerBase) => {
      emit(customerActions.handleCustomerUpdate(data));
    });

    socketCustomer.on("deleteOne", (data: string) => {
      emit(customerActions.handleCustomerDelete(data));
    });

    socketCustomer.on("exception", (data: SocketError) => {
      emit(setSocketErrorAction(data));
    });

    return (): void => {
      socketCustomer.off("connect");
      socketCustomer.off("createOne");
      socketCustomer.off("updateOne");
      socketCustomer.off("deleteOne");
      socketCustomer.off("exception");
    };
  });

export let socketCustomer: Socket;

export function connectSocketCustomer(): Socket {
  if (!socketCustomer) {
    socketCustomer = manager.socket(`/customer`);
  }

  return socketCustomer;
}
