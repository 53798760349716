import { PayloadAction, createReducer, isAnyOf } from "@reduxjs/toolkit";
import { UserBase, RoleBase, UserUpdate } from "@nagano_crm/shared/auth/user.interface";
import { Reducer, combineReducers } from "redux";

import userActions from "./user.actions";

export interface UserStore {
  users: UserBase[];
  roles: RoleBase[];
  currentUid: string | null;
  isSidebarOpen: boolean;
}

export const UserReducer: Reducer<UserStore> = combineReducers({
  users: createReducer<UserBase[]>([], builder =>
    builder
      .addCase(userActions.setUserList, (_, { payload }: PayloadAction<UserBase[]>) => payload)
      .addCase(userActions.handleDeleteUser, (state, { payload }: PayloadAction<string>) =>
        state.filter(user => user.uid !== payload)
      )
      .addMatcher(
        isAnyOf(userActions.handleCreateUser, userActions.successCreateUser),
        (state, { payload }: PayloadAction<UserBase>) => {
          if (state.find(user => user.uid === payload.uid)) {
            return state;
          } else {
            return [...state, payload];
          }
        }
      )
      .addMatcher(
        isAnyOf(userActions.handleUpdateUser, userActions.successUpdateUser),
        (state, { payload }: PayloadAction<UserUpdate>) =>
          state.map(user => (user.uid === payload.uid ? { ...user, ...payload } : user))
      )
  ),
  roles: createReducer<RoleBase[]>([], builder =>
    builder.addCase(userActions.setRoleList, (_, { payload }: PayloadAction<RoleBase[]>) => payload)
  ),
  currentUid: createReducer<string | null>(null, builder =>
    builder
      .addCase(userActions.changeCurrentUser, (_, { payload }: PayloadAction<string | null>) => payload)
      .addCase(userActions.createNewUser, () => "")
      .addMatcher(
        isAnyOf(userActions.successCreateUser, userActions.successUpdateUser, userActions.handleDeleteUser),
        () => null
      )
  ),
  isSidebarOpen: createReducer<boolean>(false, builder =>
    builder
      .addCase(userActions.changeCurrentUser, (_, { payload }: PayloadAction<string | null>) => !!payload)
      .addCase(userActions.createNewUser, () => true)
      .addMatcher(
        isAnyOf(userActions.successCreateUser, userActions.successUpdateUser, userActions.deleteUser),
        () => false
      )
  )
});
