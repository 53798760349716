import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";
import {
  NomenclatureCategoryCreate,
  NomenclatureCategoriesList,
  NomenclatureCategoryBase,
  NomenclatureCategoryUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";

//Handlers Socket
const handleNomenclatureCategoryCreate: ActionCreatorWithPayload<NomenclatureCategoryBase> =
  createAction<NomenclatureCategoryBase>("nomenclatureCategory/handleCreate");
const handleNomenclatureCategoryUpdate: ActionCreatorWithPayload<NomenclatureCategoryBase> =
  createAction<NomenclatureCategoryBase>("nomenclatureCategory/handleUpdate");
const handleNomenclatureCategoryDelete: ActionCreatorWithPayload<string> = createAction<string>(
  "nomenclatureCategory/handleDelete"
);

//Set data
const setNomenclatureCategoriesList: ActionCreatorWithPayload<NomenclatureCategoriesList> =
  createAction<NomenclatureCategoriesList>("nomenclatureCategory/setList");
const resetListNomenclatureCategories: ActionCreatorWithoutPayload = createAction<void>("nomenclatureCategory/reset");

//Actions
const changeCurrentNomenclatureCategory: ActionCreatorWithPayload<string | null> = createAction<string | null>(
  "nomenclatureCategory/changeCurrent"
);
const createNomenclatureCategory: ActionCreatorWithPayload<NomenclatureCategoryCreate> =
  createAction<NomenclatureCategoryCreate>("nomenclatureCategory/create");

const updateNomenclatureCategory: ActionCreatorWithPayload<NomenclatureCategoryUpdate> =
  createAction<NomenclatureCategoryUpdate>("nomenclatureCategory/update");

const deleteNomenclatureCategory: ActionCreatorWithPayload<string> =
  createAction<string>("nomenclatureCategory/delete");

const createNewNomenclatureCategory: ActionCreatorWithoutPayload = createAction<void>("nomenclatureCategory/createNew");

export default {
  handleNomenclatureCategoryCreate,
  handleNomenclatureCategoryUpdate,
  handleNomenclatureCategoryDelete,
  setNomenclatureCategoriesList,
  resetListNomenclatureCategories,
  changeCurrentNomenclatureCategory,
  createNomenclatureCategory,
  updateNomenclatureCategory,
  deleteNomenclatureCategory,
  createNewNomenclatureCategory
};
