import { CustomerBase } from "@nagano_crm/shared/customer/customers.types";
import { PayloadAction, createReducer, combineReducers, Reducer, isAnyOf } from "@reduxjs/toolkit";
import { OrderList } from "@nagano_crm/shared/orders/order.types";

import customerActions from "./customer.actions";

export interface CustomerStore {
  list: Record<string, CustomerBase>;
  currentCustomerUid: string | null;
  ordersCustomer: OrderList;
  isSidebarOpen: boolean;
  resultSearchByPhone: CustomerBase[];
}

export const customerReducer: Reducer<CustomerStore> = combineReducers({
  list: createReducer<Record<string, CustomerBase>>({}, builder =>
    builder
      .addCase(customerActions.resetListCustomer, () => {})
      .addCase(customerActions.setCustomerList, (_, { payload }: PayloadAction<CustomerBase[]>) =>
        payload.reduce(
          (acc, item) => {
            acc[item.uid] = item;
            return acc;
          },
          {} as Record<string, CustomerBase>
        )
      )
      .addCase(customerActions.handleCustomerCreate, (state, { payload }: PayloadAction<CustomerBase>) => ({
        ...state,
        [payload.uid]: payload
      }))
      .addCase(customerActions.handleCustomerUpdate, (state, { payload }: PayloadAction<CustomerBase>) => ({
        ...state,
        [payload.uid]: { ...state[payload.uid], ...payload }
      }))
      .addCase(customerActions.handleCustomerDelete, (state, { payload }: PayloadAction<{ uid: string }>) => {
        const newState: Record<string, CustomerBase> = { ...state };
        delete newState[payload.uid];
        return newState;
      })
  ),
  currentCustomerUid: createReducer<string | null>(null, builder =>
    builder
      .addCase(customerActions.changeCurrentCustomer, (_, { payload }: PayloadAction<string | null>) => payload)
      .addCase(customerActions.handleClickNewCustomer, () => "")
  ),
  ordersCustomer: createReducer<OrderList>([], builder =>
    builder
      .addCase(customerActions.setOrdersCustomer, (_, { payload }: PayloadAction<OrderList>) => payload)
      .addMatcher(
        isAnyOf(
          customerActions.createCustomer,
          customerActions.updateCustomer,
          customerActions.deleteCustomer,
          customerActions.handleClickNewCustomer
        ),
        () => []
      )
  ),
  isSidebarOpen: createReducer<boolean>(false, builder =>
    builder
      .addCase(
        customerActions.changeCurrentCustomer,
        (_: boolean, { payload }: PayloadAction<string | null>) => !!payload
      )
      .addCase(customerActions.handleClickNewCustomer, () => true)
      .addMatcher(
        isAnyOf(customerActions.createCustomer, customerActions.updateCustomer, customerActions.deleteCustomer),
        () => false
      )
  ),
  resultSearchByPhone: createReducer<CustomerBase[]>([], builder =>
    builder
      .addCase(customerActions.setResultSearchByPhone, (_, { payload }: PayloadAction<CustomerBase[]>) => payload)
      .addCase(customerActions.resetResultSearchByPhone, () => [])
  )
});
