import {
  OrderDeliveryBase,
  OrderDeliveryCreate,
  OrderDeliveryUpdate
} from "@nagano_crm/shared/orders/order-delivery.types";
import { OrderNomenclatureBase, OrderNomenclatureCreate } from "@nagano_crm/shared/orders/order-nomenclatures.types";
import { ORDER_STATUSES, OrderBase, OrderCreate, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import classNames from "classnames";
import { ru } from "date-fns/locale";
import dayjs, { extend as dayjsExtend } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import _ from "lodash";
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { useDispatch, useSelector } from "react-redux";
import { UserBase } from "@nagano_crm/shared/auth/user.interface";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";
import { CustomerBase } from "@nagano_crm/shared/customer/customers.types";
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from "react-dadata";

import { DropDownList, TargetDropdown } from "../../dropdown-list/dropdown-list.component";
import { SidebarCoreComponent } from "../sidebar-core/sidebar-core.component";
import useValidation, { UseValidationResult } from "../../input-validation/useValidation";
import PhoneInput from "../../phone-input/phone-input.component";
import useIsFormsFieldsEmpty from "../../check-fields/useIsFormsFieldsEmpty";

import { OrderMenuComponent } from "./order-menu/order-menu.component";
import DatePickerButton from "./date-picker-button.component";
import { OnAddNomenclatureProps } from "./order-menu/order-menu.types";
import styles from "./order.module.scss";
import { DadataState } from "./order.types";

import { ApplicationStore } from "~/app/application.store";
import { changeCurrentOrderAction, createOrderAction, updateOrderAction } from "~/app/order/order.actions";
import customerActions from "~/app/customer/customer.actions";
import { defaultOrder, selectOrderBySelectOrderUid } from "~/app/order/order.selector";
import styleTypography from "~/public/styles/typography.module.scss";
import childStyles from "~/public/styles/child.module.scss";
import { CITIES, ORDER_STATUS_INFO, TRANSLATE_DELIVERY_TYPE } from "~/typings/constants";
import CountPersonIcon from "~/view/assets/count-person-icon";
import "react-dadata/dist/react-dadata.css";
import { selectCouriersUser } from "~/app/user/user.selector";
import { formatPhoneNumber } from "~/app/utils/helpers";

dayjsExtend(isToday);
dayjsExtend(isTomorrow);

export const OrderSidebar: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const excludedElementRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

  const order: Partial<OrderBase> | null = useSelector(selectOrderBySelectOrderUid);
  const methodPayments: MethodPayment[] = useSelector((state: ApplicationStore) => state.order.methodPayments);
  const customerByPhone: CustomerBase[] = useSelector((state: ApplicationStore) => state.customer.resultSearchByPhone);

  if (!order) {
    return <></>;
  }

  const [dadataState, setDadataState]: [DadataState, Dispatch<SetStateAction<DadataState>>] = useState<DadataState>(
    {} as DadataState
  );

  const [orderChangeForm, setOrderChangeForm]: [
    OrderUpdate | OrderCreate,
    React.Dispatch<React.SetStateAction<OrderUpdate | OrderCreate>>
  ] = useState<OrderUpdate | OrderCreate>({} as OrderUpdate | OrderCreate);

  const [orderForm, setOrderForm]: [
    OrderBase | OrderUpdate | OrderCreate,
    React.Dispatch<React.SetStateAction<OrderBase | OrderUpdate | OrderCreate>>
  ] = useState<OrderBase | OrderUpdate | OrderCreate>({ ...order, ...orderChangeForm });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  useEffect(() => {
    if (!order.uid) {
      return;
    }
    console.log(orderChangeForm);
    setIsFormChanged(!useIsFormsFieldsEmpty(orderChangeForm));
  }, [orderChangeForm]);

  useEffect(() => {
    if (!order.uid) {
      setOrderChangeForm(defaultOrder as OrderUpdate);
    } else {
      setOrderChangeForm({} as OrderUpdate);
    }
  }, [order.uid]);

  useEffect(() => {
    setOrderForm({ ...order, ...orderChangeForm });
  }, [orderChangeForm, order]);

  const userCouriers: UserBase[] = useSelector(selectCouriersUser);

  useEffect(() => {
    if (!_.isEmpty(orderChangeForm)) {
      const updateTotal: number =
        orderForm?.nomenclatures?.reduce(
          (acc: number, nomenclature: OrderNomenclatureBase | OrderNomenclatureCreate) => {
            return acc + nomenclature.price * nomenclature.quantity;
          },
          0
        ) ?? 0;

      if (orderForm.total !== updateTotal) {
        setOrderChangeForm({ ...orderChangeForm, total: updateTotal });
      }
    }
  }, [orderForm?.nomenclatures]);

  //Вынести в отдельный файл
  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = useMemo(
    () => [
      {
        name: "phone",
        value: orderForm.customer?.phone,
        error: "Введите номер телефона"
      },
      { name: "name", value: orderForm.customer?.name, error: "Введите имя клиента" },
      {
        name: "items",
        value: orderForm?.nomenclatures?.length ? "items" : "",
        error: "Добавьте хотя бы одно блюдо"
      },
      ...(orderForm.delivery
        ? [
            {
              name: "courier",
              value: orderForm.delivery?.courier?.courierUid,
              error: "Выберите курьера"
            },
            { name: "city", value: orderForm.delivery?.city, error: "Введите город" },
            {
              name: "street",
              value: orderForm.delivery?.street,
              error: "Введите улицу и номер дома"
            }
          ]
        : [])
    ],
    [orderForm]
  );
  //енд Вынести в отдельный файл

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    orderForm
  );

  const handleUpdateStatus: (data: ORDER_STATUSES | null) => void = useCallback(
    (data: ORDER_STATUSES | null) => {
      if ((order as OrderUpdate).uid && data !== null) {
        dispatch(updateOrderAction({ uid: (order as OrderUpdate).uid, status: data }));
      }
    },
    [orderForm]
  );

  const handleSaveOrder: (orderUpdate: OrderUpdate | OrderCreate) => void = useCallback(
    (orderUpdate: OrderUpdate | OrderCreate) => {
      setShowErrors(true);
      if (validationErrors.length) {
        return;
      }

      if (!_.isEmpty(orderUpdate)) {
        if ((order as OrderUpdate).uid) {
          dispatch(updateOrderAction({ uid: order.uid, ...orderUpdate } as OrderUpdate));
        } else {
          dispatch(createOrderAction(orderUpdate as OrderCreate));
        }
      }
      dispatch(customerActions.resetResultSearchByPhone());
      dispatch(changeCurrentOrderAction(null));
    },
    [dispatch, validationErrors]
  );

  const handleCloseSidebar: () => void = useCallback(() => {
    dispatch(customerActions.resetResultSearchByPhone());
    dispatch(changeCurrentOrderAction(null));
  }, [dispatch]);

  const setAvailableTimesDelivery: () => TargetDropdown[] = useCallback(() => {
    const availableTimesDelivery: TargetDropdown[] = [];
    let startMinute: number = 0;
    for (let hours: number = isTodayDelivery ? dayjs().hour() + 1 : 0; hours < 24; hours++) {
      for (
        let minutes: number = isTodayDelivery && hours === dayjs().hour() + 1 ? dayjs().minute() : startMinute;
        minutes < 60;
        minutes += 15
      ) {
        const hourString: string = hours < 10 ? "0" + hours : "" + hours;
        const minuteString: string = minutes < 10 ? "0" + minutes : "" + minutes;
        const time: string = hourString + ":" + minuteString;

        if (minutes + 15 > 60) {
          startMinute = minutes + 15 - 60;
        }

        availableTimesDelivery.push({ name: time, value: time });
      }
    }
    return availableTimesDelivery;
  }, [orderForm?.deliveryAt]);

  const handleChangeCustomer: (target: { name: string; value: string }) => void = (target: {
    name: string;
    value: string;
  }): void => {
    setOrderChangeForm({
      ...orderChangeForm,
      customer: { ...orderForm.customer, [target.name]: target.value }
    } as OrderUpdate | OrderCreate);
  };

  const handleSelectCustomer: (data: { name: string; value: string | null }) => void = (data): void => {
    const customer: CustomerBase | undefined = customerByPhone.find(customer => customer.uid === data.value);
    setOrderChangeForm({
      ...orderChangeForm,
      customer: {
        ...orderForm.customer,
        ...customer
      }
    } as OrderUpdate | OrderCreate);
  };

  const handleChange: (target: { name: string; value: string | Date | null | number }) => void = (target: {
    name: string;
    value: string | Date | null | number;
  }): void => {
    setOrderChangeForm(prevState => {
      if (target.name === "methodPaymentUid") {
        const state: OrderUpdate | OrderCreate = { ...prevState };
        delete state.paymentChange;

        return {
          ...state,
          paymentChange: null,
          [target.name]: target.value
        } as OrderUpdate | OrderCreate;
      }
      if (target.name === "paymentChange") {
        return {
          ...prevState,
          [target.name]: target.value !== null ? Number(target.value) : null
        } as OrderUpdate | OrderCreate;
      }
      if (target.name === "numberPersons") {
        const value: number = Number(target.value);
        return value && value < 100
          ? {
              ...prevState,
              [target.name]: value
            }
          : prevState;
      }
      if (target.name === "deliveryType") {
        setDadataState({} as DadataState);
        return {
          ...prevState,
          [target.name]: target.value,
          delivery:
            target.value === "pickup"
              ? null
              : prevState?.delivery ?? ({ city: CITIES[0].name } as OrderDeliveryCreate | OrderDeliveryUpdate)
        } as OrderUpdate | OrderCreate;
      } else if (target.name === "deliveryAt") {
        return {
          ...prevState,
          [target.name]: dayjs(target.value).isToday()
            ? dayjs(target.value).set("hour", dayjs().hour()).add(1, "hour").set("minute", dayjs().minute()).toDate()
            : target.value ?? dayjs().toDate()
        } as OrderUpdate | OrderCreate;
      } else if (target.name === "deliveryAtTime") {
        return {
          ...prevState,
          deliveryAt: dayjs(prevState?.deliveryAt)
            .set("hour", Number((target.value as string).split(":")[0] ?? 0))
            .set("minute", Number((target.value as string).split(":")[1] ?? 0))
            .toDate()
        } as OrderUpdate | OrderCreate;
      } else {
        return {
          ...prevState,
          [target.name]: target.value
        } as OrderUpdate | OrderCreate;
      }
    });
  };

  const handleChangeDelivery: (target: { name: string; value: string | null }) => void = (target: {
    name: string;
    value: string | null;
  }): void => {
    setOrderChangeForm(prevState => {
      if (target.name === "courier") {
        const userCourier: UserBase | undefined = userCouriers.find(courier => courier.uid === target.value);
        if (!userCourier) {
          return {
            ...prevState,
            delivery: {
              ...orderForm.delivery,
              courier: null
            }
          } as OrderUpdate | OrderCreate;
        }
        return {
          ...prevState,
          delivery: {
            ...orderForm.delivery,
            courier: { name: userCourier?.name, courierUid: userCourier?.uid }
          }
        } as OrderUpdate | OrderCreate;
      } else {
        return {
          ...prevState,
          delivery: {
            ...(prevState.delivery ?? ({} as OrderDeliveryBase | OrderDeliveryCreate)),
            [target.name]: target.value
          }
        } as OrderUpdate | OrderCreate;
      }
    });
  };

  const handleDadataChange: (name: string, suggestion: DaDataSuggestion<DaDataAddress> | undefined) => void = (
    name: string,
    suggestion: DaDataSuggestion<DaDataAddress> | undefined
  ): void => {
    if (suggestion) {
      handleChangeDelivery({ name, value: suggestion.value });
      setDadataState({ ...dadataState, [name]: suggestion });
    }
  };

  const handleAddressChange: (e: ChangeEvent<HTMLInputElement>) => void = (e): void => {
    handleChangeDelivery({ name: e.target.name, value: e.target.value });
  };

  const handleAddOneNomenclature: (data: OnAddNomenclatureProps) => void = (data: OnAddNomenclatureProps) => {
    const currentNomenclatures: (OrderNomenclatureBase | OrderNomenclatureCreate)[] = [
      ...(orderForm.nomenclatures ?? [])
    ];

    const existingNomenclatureIndex: number = currentNomenclatures.findIndex(
      nomenclature => nomenclature.nomenclatureUid === data.nomenclatureUid
    );

    const updatedNomenclatures: (OrderNomenclatureBase | OrderNomenclatureCreate)[] =
      existingNomenclatureIndex !== -1
        ? currentNomenclatures.map((nomenclature, index) =>
            index === existingNomenclatureIndex
              ? { ...nomenclature, quantity: nomenclature.quantity + 1 }
              : nomenclature
          )
        : [...currentNomenclatures, { ...data, quantity: 1 }];

    setOrderChangeForm({
      ...orderChangeForm,
      nomenclatures: updatedNomenclatures
    });
  };

  const handleDeleteOneNomenclature: (uid: string) => void = (uid: string) => {
    setOrderChangeForm(prevState => {
      if (prevState) {
        const updatedNomenclatures: (OrderNomenclatureBase | OrderNomenclatureCreate)[] = (
          prevState.nomenclatures ??
          order.nomenclatures ??
          []
        ).filter(nomenclature => nomenclature.nomenclatureUid !== uid);

        return {
          ...prevState,
          nomenclatures: updatedNomenclatures
        } as OrderUpdate | OrderCreate;
      }

      return prevState;
    });
  };

  const handleChangeNomenclatureQuantity: (uid: string, quantity: number) => void = (uid: string, quantity: number) => {
    setOrderChangeForm(prevState => {
      if (prevState && quantity > 0 && quantity < 100) {
        const updatedNomenclatures: (OrderNomenclatureBase | OrderNomenclatureCreate)[] = (
          prevState.nomenclatures ??
          order.nomenclatures ??
          []
        ).map(nomenclature =>
          nomenclature.nomenclatureUid === uid ? { ...nomenclature, quantity: quantity } : nomenclature
        );

        return {
          ...prevState,
          nomenclatures: updatedNomenclatures
        } as OrderUpdate | OrderCreate;
      }

      return prevState;
    });
  };

  const handleQuantityKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => number = (e): number => {
    if (e.key === "ArrowUp") {
      return 1;
    } else if (e.key === "ArrowDown") {
      return -1;
    }
    return 0;
  };

  const handleClickNextStatus: () => void = useCallback(() => {
    setShowErrors(true);
    if (validationErrors.length) {
      return;
    }
    handleChange({ name: "status", value: nextStatusLabel });
    handleSaveOrder({ ...orderChangeForm, status: nextStatusLabel } as OrderUpdate);
  }, [validationErrors]);

  const dateDeliveryAt: string = dayjs(orderForm.deliveryAt ?? dayjs().add(1, "hour")).format("HH:mm");

  const currentStatus: string = useMemo(
    () => ORDER_STATUS_INFO[(orderForm as OrderBase).status]?.label,
    [orderForm.status]
  );

  const nextStatusLabel: ORDER_STATUSES | null = useMemo(
    () => ORDER_STATUS_INFO[(orderForm as OrderBase).status]?.nextStatus,
    [orderForm.status]
  );

  const isTodayDelivery: boolean = dayjs(orderForm.deliveryAt).isToday();

  const isDisableChangeOrder: boolean = orderForm.status !== ORDER_STATUSES.NEW;

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveOrder(orderChangeForm as OrderUpdate)}
      actionOnClickClose={(): void => handleCloseSidebar()}
      fullSidebar={orderForm.status === ORDER_STATUSES.NEW}
    >
      <div className={styles.orderSidebarWrapper}>
        {orderForm.status === ORDER_STATUSES.NEW && <OrderMenuComponent onAdd={handleAddOneNomenclature} />}
        <div className={styles.formOrder}>
          <div>
            <div className={styles.formOrderHeader}>
              <div className={styles.numberOrder}>Заказ {order.orderNumber ?? ""}</div>
              <DropDownList
                displayValue={currentStatus}
                value={orderForm.status ?? ""}
                data={Object.keys(ORDER_STATUS_INFO)
                  .filter(() => currentStatus !== ORDER_STATUS_INFO[ORDER_STATUSES.NEW].label)
                  .map(key => {
                    const orderStatus: ORDER_STATUSES = key as ORDER_STATUSES;
                    return { name: ORDER_STATUS_INFO[orderStatus].label, value: key };
                  })}
                name="status"
                handleChange={(data): void => {
                  handleUpdateStatus(data.value as ORDER_STATUSES);
                  handleChange(data);
                }}
              />
              <div className={styles.readyTimeOrder}>
                <DatePicker
                  dateFormat="dd.MM.yy"
                  locale={ru}
                  minDate={new Date()}
                  selected={dayjs(orderForm.deliveryAt ?? dayjs().add(1, "hour")).toDate()}
                  onChange={(date): void => handleChange({ name: "deliveryAt", value: date })}
                  customInput={<DatePickerButton />}
                  popperPlacement="bottom-end"
                />
                к
                <DropDownList
                  displayValue={dateDeliveryAt}
                  value={dateDeliveryAt}
                  data={setAvailableTimesDelivery()}
                  name="deliveryAtTime"
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className={classNames(styles.customerInputBoxes)}>
              <div className={classNames(styles.dflex, styles.flexDirectionRow, styles.gap4)}>
                <div className={classNames(styles.inputBox, styles.customerPhone)} ref={excludedElementRef}>
                  <PhoneInput
                    name="phone"
                    placeholder="Телефон"
                    value={formatPhoneNumber(orderForm.customer?.phone)}
                    onChange={(e): void => handleChangeCustomer(e.target)}
                    disabled={isDisableChangeOrder}
                    autoComplete={"off"}
                    handleChangeValue={(data): void => handleSelectCustomer(data)}
                  />
                  {getErrorMessage("phone") && <span className={styles.inputError}>{getErrorMessage("phone")}</span>}
                  {/* <DropDownList
                    displayValue={"Customer"}
                    value={""}
                    data={customerByPhone.map(customer => {
                      return { name: customer.name, value: customer.uid, phone: customer.phone };
                    })}
                    name="customer"
                    handleChange={(data): void => handleSelectCustomer(data)}
                    displayDropdown={openCustomersDropdown}
                    excludedRef={excludedElementRef}
                    displayFields={["phone", "name"]}
                    classNames={{
                      phone: styles.dropdownPhoneField,
                      name: styles.dropdownNameField,
                      fieldsWrapper: styles.dropdownFieldsWrapper,
                      selectValue: styles.dropdownSelectValue,
                      wrapper: styles.dropdownWrapper,
                      dropdownValues: styles.dropdownValues
                    }}
                  /> */}
                </div>
                <div className={classNames(styles.inputBox)}>
                  <input
                    name="name"
                    placeholder="Фамилия Имя"
                    value={orderForm.customer?.name ?? ""}
                    onChange={(e): void => handleChangeCustomer(e.target)}
                    disabled={isDisableChangeOrder}
                  />
                  {getErrorMessage("name") && <span className={styles.inputError}>{getErrorMessage("name")}</span>}
                </div>
              </div>
              <div className={classNames(childStyles.d_flex, childStyles.gap_1)}>
                <div className={classNames(styles.inputBox)}>
                  <input
                    name="comment"
                    placeholder="Комментарий"
                    value={orderForm.comment ?? ""}
                    onChange={(e): void => handleChange(e.target)}
                  />
                </div>
                <div className={classNames(styles.inputBox, styles.bonuses)}>
                  <input name="bonuses" placeholder="Бонусы" value="" disabled={true} />
                </div>
              </div>
            </div>
            <div className={classNames(styles.deliveryInputBoxes)}>
              <div className={classNames(styleTypography.fs_26, styles.selectTypeDelivery)}>
                <DropDownList
                  displayValue={TRANSLATE_DELIVERY_TYPE[orderForm.deliveryType ?? ""]}
                  value={orderForm.deliveryType ?? ""}
                  data={[
                    { name: "Самовывоз", value: "pickup" },
                    { name: "Доставка", value: "delivery" }
                  ]}
                  name="deliveryType"
                  handleChange={handleChange}
                  disabled={isDisableChangeOrder}
                />
                {orderForm.deliveryType === "delivery" && (
                  <div className={styles.deliveryCourier}>
                    <div>курьер</div>
                    <DropDownList
                      value={orderForm.delivery?.courier?.courierUid ?? ""}
                      data={[
                        { name: "Выберите курьера", value: "" },
                        ...userCouriers.map(courier => {
                          return { name: courier.name, value: courier.uid };
                        })
                      ]}
                      name="courier"
                      handleChange={handleChangeDelivery}
                      disabled={isDisableChangeOrder}
                    />
                  </div>
                )}
                {getErrorMessage("courier") && <span className={styles.inputError}>{getErrorMessage("courier")}</span>}
              </div>

              {orderForm.deliveryType === "delivery" && (
                <div className={styles.deliveryInfoInputsBox}>
                  <div className={classNames(childStyles.d_flex, childStyles.gap_1)}>
                    <DropDownList
                      displayValue={orderForm?.delivery?.city ?? ""}
                      value={orderForm?.delivery?.city ?? ""}
                      data={[
                        ...CITIES.map(city => {
                          return { name: city.name, value: city.name };
                        })
                      ]}
                      name="city"
                      handleChange={handleChangeDelivery}
                      disabled={isDisableChangeOrder}
                      classNames={{
                        selectValue: styles.dropdownCityField
                      }}
                    />
                    {/* <AddressSuggestions
                    token="e39760cce754f1e614cecb368265cfcc92e58d54"
                    value={dadataState?.city}
                    defaultQuery={orderForm.delivery?.city ?? ""}
                    onChange={(suggestion): void => handleDadataChange("city", suggestion)}
                    filterRestrictValue={true}
                    filterFromBound="city"
                    filterToBound="settlement"
                    containerClassName={classNames(styles.inputBox)}
                    filterLocations={[{ region: "свердловская" }]}
                    inputProps={{
                      placeholder: "Город",
                      name: "city",
                      disabled: isDisableChangeOrder,
                      onChange: handleAddressChange
                    }}
                  /> */}
                    <AddressSuggestions
                      token="e39760cce754f1e614cecb368265cfcc92e58d54"
                      value={dadataState?.street}
                      defaultQuery={orderForm.delivery?.street ?? ""}
                      onChange={(suggestion): void => handleDadataChange("street", suggestion)}
                      filterFromBound="street"
                      filterToBound="house"
                      filterLocations={
                        orderForm?.delivery?.city
                          ? [{ city: orderForm?.delivery?.city }]
                          : [{ city_type_full: "город" }]
                      }
                      filterRestrictValue={true}
                      containerClassName={classNames(styles.inputBox)}
                      inputProps={{
                        placeholder: "Улица и дом",
                        name: "street",
                        disabled: isDisableChangeOrder,
                        onChange: handleAddressChange
                      }}
                    />
                  </div>
                  <div className={classNames(styles.dflex, styles.flexDirectionRow, styles.gap4)}>
                    <div className={classNames(styles.inputBox)}>
                      <input
                        name="entrance"
                        placeholder="Подъезд"
                        value={orderForm.delivery?.entrance ?? ""}
                        onChange={(e): void => handleChangeDelivery(e.target)}
                        disabled={isDisableChangeOrder}
                      />
                    </div>
                    <div className={classNames(styles.inputBox)}>
                      <input
                        name="floor"
                        placeholder="Этаж"
                        value={orderForm.delivery?.floor ?? ""}
                        onChange={(e): void => handleChangeDelivery(e.target)}
                        disabled={isDisableChangeOrder}
                      />
                    </div>
                    <div className={classNames(styles.inputBox)}>
                      <input
                        name="flat"
                        placeholder="Квартира"
                        value={orderForm.delivery?.flat ?? ""}
                        onChange={(e): void => handleChangeDelivery(e.target)}
                        disabled={isDisableChangeOrder}
                      />
                    </div>
                  </div>
                  {getErrorMessage("street") && (
                    <span className={classNames(styles.inputError)}>{getErrorMessage("street")}</span>
                  )}
                  {/* {getErrorMessage("house") && <span className={styles.inputError}>{getErrorMessage("house")}</span>} */}
                </div>
              )}
            </div>
          </div>
          <div className={styles.orderInfoWrapper}>
            <div className={styles.orderNomenclaturesWrapper}>
              {!orderForm.nomenclatures?.length && (
                <div className={styles.textEmpty}>Добавьте первое блюдо в заказ</div>
              )}
              {orderForm.nomenclatures?.length && orderForm.nomenclatures?.length > 0 && (
                <div className={styles.nomenclaturesTableWrapper}>
                  <div className={styles.header}>
                    <div className={styles.name}>Наименование</div>
                    <div className={styles.price}>Цена</div>
                    <div className={styles.count}>Кол-во</div>
                    <div className={styles.total}>Стоимость</div>
                    <div className={styles.delete}>
                      <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M1 1L9 8.9209M1 9L9 1.0791"></path>
                      </svg>
                    </div>
                  </div>
                  {orderForm.nomenclatures?.map((nomenclature, index) => {
                    return (
                      <div className={styles.contentRow} key={index}>
                        <div className={styles.name}>{nomenclature.name ?? ""}</div>
                        <div className={styles.price}>{Number(nomenclature.price).toLocaleString("ru-RU")}&nbsp;₽</div>
                        <div className={classNames(styles.count, styles.quantityWrapper)}>
                          <button
                            className={styles.quantityButton}
                            onClick={(): void =>
                              handleChangeNomenclatureQuantity(nomenclature.nomenclatureUid, nomenclature.quantity - 1)
                            }
                            disabled={isDisableChangeOrder}
                          >
                            -
                          </button>
                          <input
                            name="nomenclatureQuantity"
                            type="text"
                            value={nomenclature.quantity}
                            className={styles.quantityInput}
                            onChange={(e): void =>
                              handleChangeNomenclatureQuantity(nomenclature.nomenclatureUid, parseInt(e.target.value))
                            }
                            onKeyDown={(e): void =>
                              handleChangeNomenclatureQuantity(
                                nomenclature.nomenclatureUid,
                                nomenclature.quantity + handleQuantityKeyPress(e)
                              )
                            }
                            disabled={isDisableChangeOrder}
                          />
                          <button
                            className={classNames(styles.quantityButton, styles.rightButton)}
                            onClick={(): void =>
                              handleChangeNomenclatureQuantity(nomenclature.nomenclatureUid, nomenclature.quantity + 1)
                            }
                            disabled={isDisableChangeOrder}
                          >
                            +
                          </button>
                        </div>
                        <div className={styles.total}>
                          {(nomenclature.price * nomenclature.quantity).toLocaleString("ru-RU")}&nbsp;₽
                        </div>
                        {!isDisableChangeOrder && (
                          <div
                            className={styles.deleteIcon}
                            onClick={(): void => handleDeleteOneNomenclature(nomenclature.nomenclatureUid)}
                          >
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                              <path d="M1 1L9 8.9209M1 9L9 1.0791" stroke="#BDBDBF"></path>
                            </svg>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {getErrorMessage("items") && <span className={styles.inputError}>{getErrorMessage("items")}</span>}
            <div className={styles.orderPriceWrapper}>
              <div className={styles.orderPrice}>
                <div>Заказ на {orderForm.total?.toLocaleString("ru-RU") ?? 0} ₽</div>
                <div className={classNames(styles.count, styles.countPersonWrapper)}>
                  <CountPersonIcon />
                  <button
                    className={styles.quantityButton}
                    onClick={(): void =>
                      handleChange({
                        name: "numberPersons",
                        value: (orderForm.numberPersons ?? 1) - 1
                      })
                    }
                    disabled={isDisableChangeOrder}
                  >
                    -
                  </button>
                  <input
                    name="numberPersons"
                    type="text"
                    value={orderForm.numberPersons?.toString() ?? "1"}
                    className={styles.quantityInput}
                    onChange={(e): void => handleChange({ name: "numberPersons", value: parseInt(e.target.value) })}
                    onKeyDown={(e): void =>
                      handleChange({
                        name: "numberPersons",
                        value: (orderForm.numberPersons ?? 1) + handleQuantityKeyPress(e)
                      })
                    }
                    disabled={isDisableChangeOrder}
                  />
                  <button
                    className={styles.quantityButton}
                    onClick={(): void =>
                      handleChange({
                        name: "numberPersons",
                        value: (orderForm.numberPersons ?? 1) + 1
                      })
                    }
                    disabled={isDisableChangeOrder}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.orderPay}>
                <div>Оплата</div>
                <DropDownList
                  displayValue={
                    methodPayments.find(payment => payment.uid === orderForm.methodPaymentUid)?.paymentType ?? ""
                  }
                  value={orderForm.methodPaymentUid ?? ""}
                  data={methodPayments.map(payment => {
                    return { name: payment.paymentType, value: payment.uid };
                  })}
                  name="methodPaymentUid"
                  handleChange={handleChange}
                  styles={{ bottom: "0" }}
                />
                <div>
                  {methodPayments.find(methodPayment => methodPayment.uid === orderForm.methodPaymentUid)
                    ?.isPaymentChange && (
                    <div className={styles.paymentChange}>
                      , сдача с
                      <DropDownList
                        displayValue={orderForm.paymentChange?.toLocaleString("ru-RU")}
                        value={orderForm.paymentChange?.toLocaleString("ru-RU") ?? null}
                        data={[
                          { name: "0", value: null },
                          { name: "500", value: "500" },
                          { name: "1000", value: "1000" },
                          { name: "5000", value: "5000" }
                        ]}
                        name="paymentChange"
                        handleChange={handleChange}
                        styles={{ bottom: "0" }}
                      />{" "}
                      ₽
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.orderButtons}>
              {nextStatusLabel && (
                <button
                  onClick={handleClickNextStatus}
                  className={classNames(styles.btn, styles.primary)}
                  // disabled={isNextButtonDisabled}
                >
                  {ORDER_STATUS_INFO[nextStatusLabel].label}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
