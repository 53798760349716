import { Effect, all, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { UserData, UsersAuthData } from "@nagano_crm/shared/auth/user.interface";

import { ApplicationStore } from "../application.store";

import { authenticate, handleRunCheckIsAuthAction, setIsAuth, setUserAction } from "~/app/auth/auth.actions";
import { userService } from "~/app/auth/user.service";
import { setLocalErrorAction } from "~/app/error/error.actions";
import { apiCall } from "~/app/utils/api-call";
import { FetchError } from "~/app/utils/api-fetch";

function* getAuthUser(): Generator<Effect | Generator, void, UserData> {
  try {
    const user: UserData = yield apiCall(userService.me);
    yield put(setUserAction(user));
    yield put(setIsAuth(true));
  } catch (e) {
    yield put(setIsAuth(false));
    if (!(e instanceof FetchError) || e.status !== 401) {
      console.error(e);
      yield put(
        setLocalErrorAction({
          message: e instanceof Error ? e.message : "Unknown error, while fetching user",
          details: e
        })
      );
    }
  }
}

function* authUser(): Generator<Effect | Generator, void, UserData | UsersAuthData> {
  try {
    const userAuthData: UsersAuthData = (yield select(
      (state: ApplicationStore) => state.auth.userAuthData
    )) as UsersAuthData;

    const user: UserData = (yield apiCall(userService.auth, userAuthData)) as UserData;
    yield put(setUserAction(user));
    yield put(setIsAuth(true));
    // if (user) {
    //   window.location.reload();
    // }
  } catch (e) {
    yield put(setIsAuth(false));
    if (!(e instanceof FetchError) || e.status !== 401) {
      console.error(e);
      yield put(
        setLocalErrorAction({
          message: e instanceof Error ? e.message : "Unknown error, while fetching user",
          details: e
        })
      );
    }
  }
}

export function* checkAuthSaga(): Generator<Effect | Generator, void, void> {
  try {
    yield fork(getAuthUser);
  } catch (e) {
    console.error(e);
  }
}

export function* authWatchers(): Generator<Effect | Generator, void, void> {
  yield all([takeEvery(handleRunCheckIsAuthAction, () => checkAuthSaga()), takeLatest(authenticate, authUser)]);
}

export function* authSagas(): Generator<Effect | Generator, void, void> {
  yield fork(authWatchers);
}
