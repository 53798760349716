import React, { memo, useCallback } from "react";
import classNames from "classnames";

import styles from "./nomenclature-item.module.scss";
import { NomenclatureItemProps } from "./nomenclature-item.types";

import childStyles from "~/public/styles/child.module.scss";

export const NomenclatureItem: React.FC<NomenclatureItemProps> = memo((props: NomenclatureItemProps) => {
  const handleNomenclatureClick: (event: React.MouseEvent) => void = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (props.onNomenclatureClick) {
        props.onNomenclatureClick(props.uid);
      }
    },
    [props.onNomenclatureClick, props.uid]
  );

  return (
    <div
      className={classNames(styles.nomenclatureItem, { [styles.active]: props.isSelected })}
      onClick={handleNomenclatureClick}
    >
      <div className={classNames(childStyles.text_m_size, childStyles.tight, styles.nomenclatureTitle)}>
        {props.title}
      </div>
      <div className={classNames(childStyles.d_flex, childStyles.gap_3)}>
        <div className={classNames(styles.nomenclatureComment, childStyles.text_xs_size, childStyles.tight)}>
          {props.comment}
          Тестовый комментарий
        </div>
        <div className={classNames(childStyles.title_m_size, styles.nomenclatureQuantity)}>{props.quantity}</div>
      </div>
    </div>
  );
});

NomenclatureItem.displayName = "OrderItemComponent";
