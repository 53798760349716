import { Selector, createSelector } from "reselect";
import { SellingPointBase } from "@nagano_crm/shared/settings/settings.types";

import { ApplicationStore } from "../application.store";

export class EmptySellingPointData {
  static createEmptySellingPointData(): SellingPointBase {
    return {
      name: "",
      phone: "",
      director: null,
      workingHours: "",
      address: ""
    };
  }
}

export const selectSellingPointList: () => Selector<
  ApplicationStore,
  { [sellingPoints: string]: string[] }
> = (): Selector<ApplicationStore, { [sellingPoints: string]: string[] }> =>
  createSelector(
    (state: ApplicationStore) => state.settings.sellingPointsList,
    sellingPoints => {
      return { ...sellingPoints };
    }
  );

export const selectSellingPointByCurrentUid: Selector<ApplicationStore, SellingPointBase | null> = createSelector(
  [
    (state: ApplicationStore): Record<string, SellingPointBase> => state.settings.sellingPointsList,
    (state: ApplicationStore): string | null => state.settings.currentSellingPointUid
  ],
  (sellingPoints, currentSellingPointUid) => {
    if (currentSellingPointUid === null) {
      return null;
    }

    const sellingPoint: SellingPointBase | undefined = sellingPoints[currentSellingPointUid];

    if (sellingPoint === undefined) {
      return EmptySellingPointData.createEmptySellingPointData();
    }

    return sellingPoint;
  }
);
