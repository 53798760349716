import React from "react";

const ArrowIcon: React.FC = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="40.9412"
      height="8.48889"
      transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 30.4844 58.584)"
      fill="black"
    />
    <rect
      width="41.9094"
      height="8.48889"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 29.7988)"
      fill="black"
    />
  </svg>
);
export default ArrowIcon;
