import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ORDER_STATUSES, OrderBase } from "@nagano_crm/shared/orders/order.types";
import moment from "moment";
import classNames from "classnames";

import { OrderItemProps } from "./order-item.types";
import styles from "./order-item.module.scss";

import { ApplicationStore } from "~/app/application.store";
import childStyles from "~/public/styles/child.module.scss";
import { makeSelectKitchenOrderByOrderUid } from "~/app/kitchen/kitchen.selector";

export const OrderItemComponent: React.FC<OrderItemProps> = memo((props: OrderItemProps) => {
  const selectOrderByOrderUid: (state: ApplicationStore, orderUid: string) => OrderBase | undefined = useMemo(
    makeSelectKitchenOrderByOrderUid,
    []
  );

  const orderItem: OrderBase | undefined = useSelector((state: ApplicationStore) =>
    selectOrderByOrderUid(state, props.uid)
  );

  if (!orderItem) {
    return;
  }

  const dateDeliveryAt: string = moment(orderItem.deliveryAt).format("HH:mm");

  const handleOrderClick: (event: React.MouseEvent) => void = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (props.onOrderClick) {
        props.onOrderClick(orderItem.uid, orderItem.status);
      }
    },
    [props.onOrderClick, orderItem.uid]
  );

  const [remainingTime, setRemainingTime]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");

  useEffect(() => {
    if (!orderItem) {
      return;
    }

    const updateRemainingTime: () => void = (): void => {
      const deliveryMoment: moment.Moment = moment(orderItem.deliveryAt);
      const now: moment.Moment = moment();
      const duration: moment.Duration = moment.duration(deliveryMoment.diff(now));
      const minutes: number = duration.asMinutes();

      if (minutes < 60) {
        setRemainingTime(`${Math.ceil(minutes)} мин`);
      } else {
        setRemainingTime(">60 мин");
      }
    };

    updateRemainingTime();
    const intervalId: NodeJS.Timeout = setInterval(updateRemainingTime, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const totalNomenclatureQuantity: number = useMemo(() => {
    return orderItem.nomenclatures.reduce((total, item) => total + item.quantity, 0);
  }, [orderItem.nomenclatures]);

  const orderStatus: string =
    orderItem.status === ORDER_STATUSES.IN_PROGRESS && !props.isOrderInWork
      ? "В очереди"
      : props.isOrderInWork
      ? "В работе"
      : "";

  return (
    <div
      className={classNames(styles.orderItem, {
        [styles.selectedOrderItem]: props.isSelected,
        [styles.inWork]: props.isOrderInWork
      })}
      onClick={handleOrderClick}
    >
      <div className={classNames(childStyles.d_flex, childStyles.justify_content_between, childStyles.h_100)}>
        <div className={classNames(childStyles.d_flex, childStyles.flex_column, childStyles.justify_content_between)}>
          <div className={styles.orderNumber}>{orderItem.orderNumber}</div>
        </div>
        <div
          className={classNames(
            childStyles.d_flex,
            childStyles.flex_column,
            childStyles.align_items_end,
            childStyles.gap_2
          )}
        >
          <div className={classNames(childStyles.align_self_end)}>
            <div className={classNames(childStyles.text_m_size)}>{orderStatus}</div>
            <div className={classNames(childStyles.text_m_size, childStyles.text_align_right)}>
              {totalNomenclatureQuantity} шт.
            </div>
          </div>
          <div className={classNames(childStyles.d_flex, childStyles.flex_column, childStyles.gap_1)}>
            {/* <div className={classNames(childStyles.text_s_size)}>Осталось: </div> */}
          </div>
        </div>
      </div>
      {props.isSelected && (
        <div className={classNames(childStyles.d_flex, childStyles.flex_column, childStyles.gap_4)}>
          <div
            className={classNames(styles.orderTime, childStyles.text_m_size, {
              [childStyles.text_l_size]: props.isSelected
            })}
          >
            {dateDeliveryAt}
          </div>
          <div
            className={classNames(childStyles.text_s_size, {
              [childStyles.title_m_size]: props.isSelected
            })}
          >
            {remainingTime}
          </div>
        </div>
      )}
      {!props.isSelected && (
        <div className={classNames(childStyles.d_flex, childStyles.gap_2)}>
          <div
            className={classNames(styles.orderTime, childStyles.text_m_size, {
              [childStyles.title_m_size]: props.isSelected
            })}
          >
            {dateDeliveryAt}
          </div>
          <div
            className={classNames(childStyles.text_s_size, childStyles.align_self_end, {
              [childStyles.text_m_size]: props.isSelected
            })}
          >
            Осталось: {remainingTime}
          </div>
        </div>
      )}
    </div>
  );
});

OrderItemComponent.displayName = "OrderItemComponent";
