type AnyObject = Record<string, any>;

const useIsFormsFieldsEmpty: (obj: AnyObject | null) => boolean = (obj: AnyObject | null): boolean => {
  if (obj === null) {
    return true;
  }
  return Object.values(obj).every(
    value =>
      value === null ||
      value === undefined ||
      value === "" ||
      value === 0 ||
      value === "0" ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && Object.keys(value).length === 0)
  );
};

export default useIsFormsFieldsEmpty;
