import { Effect, all, call, cancel, cancelled, fork, put, take, takeEvery } from "redux-saga/effects";
import {
  NomenclatureBase,
  NomenclatureCreate,
  NomenclatureUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import { Action } from "redux";
import { EventChannel, Task } from "redux-saga";
import {
  NomenclatureCategoryCreate,
  NomenclatureCategory,
  NomenclatureCategoryUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";
import { Socket } from "socket.io-client";
import { PayloadAction } from "@reduxjs/toolkit";

import nomenclaturesActions from "./nomenclatures.actions";
import {
  createNomenclatureCategoryService,
  createNomenclatureService,
  deleteNomenclatureCategoryService,
  deleteNomenclatureService,
  fetchNomenclatureCategoriesCore,
  fetchNomenclatureCore,
  updateNomenclatureCategoryService,
  updateNomenclatureService
} from "./nomenclatures.service";
import {
  connectSocketNomenclature,
  createSocketEventsNomenclatures,
  socketNomenclature
} from "./nomenclatures.sockets";
import nomenclaturesCategoryActions from "./nomenclatures-category.actions";
// import { createSocketEventsNomenclatures } from "./nomenclatures.sockets";

function* connectionNomenclatureSocket(): Generator {
  const response: NomenclatureBase[] = (yield call(fetchNomenclatureCore)) as NomenclatureBase[];
  const responseCategory: NomenclatureCategory[] = (yield call(
    fetchNomenclatureCategoriesCore
  )) as NomenclatureCategory[];

  yield put(nomenclaturesActions.setNomenclatureList(response));
  yield put(nomenclaturesCategoryActions.setNomenclatureCategoriesList(responseCategory));
}

function* socketWatchers(): Generator {
  const socketEventsNomenclatures: EventChannel<any> = (yield call(
    createSocketEventsNomenclatures
  )) as EventChannel<any>;

  while (true) {
    const action: Action<any> = (yield take(socketEventsNomenclatures)) as Action<any>;

    yield put(action);
  }
}

function* connectSocketNomenclatureSaga(): Generator<Effect, void, Task | Socket> {
  let task: Task | null = null;

  try {
    yield call(connectSocketNomenclature);

    task = (yield fork(socketWatchers)) as Task;
  } finally {
    if (yield cancelled()) {
      socketNomenclature.close();
      if (task !== null) {
        yield cancel(task);
      }
    }
  }
}

function* nomenclatureWatchers(): Generator {
  yield all([
    takeEvery(nomenclaturesActions.handleConnectedSocket, () => connectionNomenclatureSocket()),
    //Nomenclatures
    takeEvery(nomenclaturesActions.createNomenclature, ({ payload }: PayloadAction<NomenclatureCreate>) =>
      createNomenclatureService(payload)
    ),
    takeEvery(nomenclaturesActions.updateNomenclature, ({ payload }: PayloadAction<NomenclatureUpdate>) =>
      updateNomenclatureService(payload)
    ),
    takeEvery(nomenclaturesActions.deleteNomenclature, ({ payload }: PayloadAction<string>) =>
      deleteNomenclatureService(payload)
    ),

    //Nomenclatures Category
    takeEvery(
      nomenclaturesCategoryActions.createNomenclatureCategory,
      ({ payload }: PayloadAction<NomenclatureCategoryCreate>) => createNomenclatureCategoryService(payload)
    ),
    takeEvery(
      nomenclaturesCategoryActions.updateNomenclatureCategory,
      ({ payload }: PayloadAction<NomenclatureCategoryUpdate>) => updateNomenclatureCategoryService(payload)
    ),
    takeEvery(nomenclaturesCategoryActions.deleteNomenclatureCategory, ({ payload }: PayloadAction<string>) =>
      deleteNomenclatureCategoryService(payload)
    )
  ]);
}

export function* nomenclatureSaga(): Generator {
  yield all([fork(connectSocketNomenclatureSaga), fork(nomenclatureWatchers)]);
}
