import { CustomerBase } from "@nagano_crm/shared/customer/customers.types";
import React, { Dispatch, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { ApplicationStore } from "../../../app/application.store";

import styles from "./customer.module.scss";

import { CustomerSidebar } from "~/view/components/sidebars/customer/customer.component";
import customerActions from "~/app/customer/customer.actions";
import SearchIcon from "~/view/assets/search-icon";
import { formatPhoneNumber } from "~/app/utils/helpers";

export const CustomerPageComponent: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const customers: Record<string, CustomerBase> = useSelector((state: ApplicationStore) => state.customer.list);

  const isOpenCustomerSidebar: boolean = useSelector((state: ApplicationStore) => state.customer.isSidebarOpen);

  const changeCurrentCustomer: (customerUid: string) => void = useCallback(
    (customerUid: string) => {
      dispatch(customerActions.changeCurrentCustomer(customerUid));
    },
    [dispatch]
  );

  return (
    <div className={styles.customerPageWrapper}>
      <div className={styles.titlePageSearchInput}>
        <input type="text" placeholder="Клиенты" disabled />
        <SearchIcon />
      </div>
      <div className={classNames(styles.customerTableWrapper, styles.tableWrapper)}>
        <div className={styles.headerTable}>
          <div className={classNames(styles.cellTable, styles.name)}>ФИО</div>
          <div className={classNames(styles.cellTable, styles.phone)}>Телефон</div>
          <div className={classNames(styles.cellTable, styles.email)}>Эл. почта</div>
          <div className={classNames(styles.cellTable, styles.dateBirth)}>Дата рождения</div>
          <div className={classNames(styles.cellTable, styles.countOrders)}>Заказов</div>
          <div className={classNames(styles.cellTable, styles.bonuses)}>Бонусы</div>
        </div>
        {Object.values(customers).map(customer => (
          <div key={customer.uid} className={styles.rowTable} onClick={(): void => changeCurrentCustomer(customer.uid)}>
            <div className={classNames(styles.cellTable, styles.name)}>{customer.name}</div>
            <div className={classNames(styles.cellTable, styles.phone)}>{formatPhoneNumber(customer.phone)}</div>
            <div className={classNames(styles.cellTable, styles.email)}>{customer.email}</div>
            <div className={classNames(styles.cellTable, styles.dateBirth)}></div>
            <div className={classNames(styles.cellTable, styles.countOrders, styles.center)}>
              {customer.countOrders}
            </div>
            <div className={classNames(styles.cellTable, styles.bonuses)}></div>
          </div>
        ))}
      </div>

      <div className={styles.btnOrderNew} onClick={(): void => dispatch(customerActions.handleClickNewCustomer())}>
        +
      </div>

      {isOpenCustomerSidebar && <CustomerSidebar />}
    </div>
  );
};
