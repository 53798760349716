import { Socket } from "socket.io-client";
import { eventChannel } from "redux-saga";
import { UserBase } from "@nagano_crm/shared/auth/user.interface";

import { setSocketErrorAction } from "../error/error.actions";
import { SocketError } from "../error/error.store";
import { manager } from "../socket.utils";

import userActions from "./user.actions";

export const createSocketEventsUser: any = (): any =>
  eventChannel(emit => {
    userSocket.on("exception", (data: SocketError) => {
      emit(setSocketErrorAction(data));
    });

    userSocket.on("connect", () => {
      emit(userActions.handleConnectSocket());
    });

    userSocket.on("create", (data: UserBase) => {
      emit(userActions.handleCreateUser(data));
    });

    userSocket.on("update", (data: UserBase) => {
      emit(userActions.handleUpdateUser(data));
    });

    userSocket.on("delete", (data: string) => {
      emit(userActions.handleDeleteUser(data));
    });

    return (): void => {
      userSocket.off("exception");
      userSocket.off("connect");
      userSocket.off("createUser");
      userSocket.off("updateUser");
    };
  });

export let userSocket: Socket;

export const setUserSocket: () => Socket = (): Socket => {
  if (!userSocket) {
    userSocket = manager.socket(`/user`);
  }

  return userSocket;
};
