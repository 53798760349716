import { RoleBase, UserBase, UserCreate, UserUpdate } from "@nagano_crm/shared/auth/user.interface";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import userActions from "~/app/user/user.actions";
import { selectUserByCurrentUid } from "~/app/user/user.selector";
import { SidebarCoreComponent } from "~/view/components/sidebars/sidebar-core/sidebar-core.component";
import sidebarStyles from "~/view/components/sidebars/sidebar.module.scss";
import { ApplicationStore } from "~/app/application.store";
import { DropDownList } from "~/view/components/dropdown-list/dropdown-list.component";
import useValidation, { UseValidationResult } from "~/view/components/input-validation/useValidation";
import useIsFormsFieldsEmpty from "~/view/components/check-fields/useIsFormsFieldsEmpty";
import PhoneInput from "~/view/components/phone-input/phone-input.component";

export const UserSidebar: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const userData: Partial<UserBase> | UserCreate | null = useSelector(selectUserByCurrentUid);
  const rolesList: RoleBase[] = useSelector((state: ApplicationStore) => state.user.roles);

  // if (!userData) {
  //   return <></>;
  // }

  const [userChangeForm, setUserChangeForm]: [
    UserUpdate | UserCreate,
    React.Dispatch<React.SetStateAction<UserUpdate | UserCreate>>
  ] = useState<UserUpdate | UserCreate>({} as UserUpdate | UserCreate);

  const [userForm, setUserForm]: [
    UserUpdate | UserCreate,
    React.Dispatch<React.SetStateAction<UserUpdate | UserCreate>>
  ] = useState<UserUpdate | UserCreate>({ ...userData, ...userChangeForm });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  useEffect(() => {
    setUserForm({ ...userData, ...userChangeForm });
  }, [userChangeForm, userData]);

  useEffect(() => {
    setIsFormChanged(!useIsFormsFieldsEmpty(userChangeForm));
  }, [userChangeForm]);

  const [showPassword, setShowPassword]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);

  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = [
    { name: "name", value: userForm?.name, error: "Введите имя" },
    { name: "login", value: userForm?.login, error: "Введите логин" },
    { name: "email", value: userForm?.email, error: "Введите email" }
  ];

  if (!(userForm as UserBase).uid) {
    requiredFields.push({
      name: "password",
      value: userForm?.password,
      error: "Введите пароль"
    });
  }

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    userForm
  );

  const handleCloseSidebar: () => void = useCallback(() => dispatch(userActions.changeCurrentUser(null)), [dispatch]);

  const handleSaveUser: (user: UserCreate | UserUpdate) => void = useCallback(
    (user: UserCreate | UserUpdate) => {
      setShowErrors(true);
      if (_.isEmpty(user) || validationErrors.length) {
        return;
      }
      if ((user as UserBase).uid) {
        dispatch(userActions.updateUser({ uid: (user as UserBase).uid, ...user } as UserUpdate));
      } else {
        dispatch(userActions.createUser(user as UserCreate));
      }
    },
    [dispatch, validationErrors]
  );

  const toggleShowPassword: () => void = () => {
    setShowPassword(!showPassword);
  };

  const handleChange: (target: { name: string; value: string | null }) => void = target => {
    setIsFormChanged(true);
    setUserChangeForm(prevState => {
      if ((target.name === "password" || target.name === "login") && target.value === "") {
        const state: UserCreate | UserUpdate = { ...prevState };
        delete state[target.name as keyof typeof state];
        return state;
      }
      if ((userForm as UserBase)[target.name as keyof UserBase] === target.value) {
        const state: UserCreate | UserUpdate = { ...prevState };
        delete state[target.name as keyof typeof state];
        return state;
      }
      return {
        ...prevState,
        [target.name]: target.value
      } as UserUpdate | UserCreate;
    });
  };

  const handleDeleteUser: (userUid: string) => void = useCallback(
    (userUid: string) => {
      dispatch(userActions.deleteUser(userUid));
    },
    [dispatch]
  );

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveUser(userForm)}
      actionOnClickClose={handleCloseSidebar}
      actionOnClickDelete={
        (userForm as UserBase)?.uid ? (): void => handleDeleteUser((userForm as UserBase).uid) : undefined
      }
    >
      <div className={sidebarStyles.sidebarWrapper}>
        <header>
          <input
            type="text"
            name="name"
            placeholder="ФИО"
            value={userForm.name ?? ""}
            onChange={(e): void => handleChange(e.target)}
          />
          {getErrorMessage("name") && <span>{getErrorMessage("name")}</span>}
        </header>
        <div className={sidebarStyles.mainWrapper}>
          <div className={sidebarStyles.inputWrappers}>
            <div className={sidebarStyles.inputWrapper}>
              <div className={sidebarStyles.inputLabel}>Телефон</div>
              <div className={sidebarStyles.inputBox}>
                <PhoneInput
                  type="text"
                  name="phone"
                  placeholder="Введите телефон"
                  value={userForm.phone ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e.target)}
                  disableSearch={true}
                />
                {getErrorMessage("phone") && <span>{getErrorMessage("phone")}</span>}
              </div>
            </div>
            <div className={sidebarStyles.inputWrapper}>
              <div className={sidebarStyles.inputLabel}>Роль</div>
              <div className={sidebarStyles.inputBox}>
                <DropDownList
                  data={[
                    { name: "", value: null },
                    ...rolesList
                      .map(role => {
                        return { name: role.name, value: role.uid };
                      })
                      .reverse()
                  ]}
                  displayValue={rolesList.find(role => role.uid === userForm.role_uid)?.name ?? "Выберите роль"}
                  value={userForm.role_uid ?? ""}
                  handleChange={(data): void => handleChange(data)}
                  name="role_uid"
                />
              </div>
            </div>
            <div className={sidebarStyles.inputWrapper}>
              <div className={sidebarStyles.inputLabel}>Логин</div>
              <div className={sidebarStyles.inputBox}>
                <input
                  type="text"
                  name="login"
                  placeholder="Введите логин"
                  value={userForm.login ?? ""}
                  onChange={(e): void => handleChange(e.target)}
                />
                {getErrorMessage("login") && <span>{getErrorMessage("login")}</span>}
              </div>
            </div>
            <div className={sidebarStyles.inputWrapper}>
              <div className={sidebarStyles.inputLabel}>Пароль</div>
              <div className={sidebarStyles.inputBox}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Введите пароль"
                  value={userForm.password ?? ""}
                  autoComplete={"new-password"}
                  onChange={(e): void => handleChange(e.target)}
                />
                {getErrorMessage("password") && <span>{getErrorMessage("password")}</span>}
                {userForm.password && userForm.password.length > 0 && (
                  <div onClick={toggleShowPassword}>{showPassword ? "Скрыть" : "Показать"}</div>
                )}
              </div>
            </div>
            <div className={sidebarStyles.inputWrapper}>
              <div className={sidebarStyles.inputLabel}>Эл. почта</div>
              <div className={sidebarStyles.inputBox}>
                <input
                  type="text"
                  name="email"
                  placeholder="Введите почту"
                  value={userForm.email ?? ""}
                  onChange={(e): void => handleChange(e.target)}
                />
                {getErrorMessage("email") && <span>{getErrorMessage("email")}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
