import { takeLatest, Effect } from "redux-saga/effects";
import { notification } from "antd";
// import axios from "axios";
import { PayloadAction } from "typesafe-actions";

import { setSocketErrorAction } from "~/app/error/error.actions";
import { SocketError } from "~/app/error/error.store";
// import { fetchErrorMessage } from "~/app/utils/api-fetch.messages";

function displayNetworkError({ payload: error }: PayloadAction<string, SocketError>): void {
  const description: string = "";
  // if (axios.isAxiosError(error.details) && error.details?.config?.url) {
  //   if (error.details.response?.status === 401) {
  //     return;
  //   }
  //   const url: URL = new URL(error.details.config.url, window.location.href);
  //   description = url.pathname;
  // }

  notification.error({
    message: `${error.message} (${String(error.error)})`,
    description,
    placement: "topRight",
    duration: 3
  });
}

export function* errorSaga(): Generator<Effect, void, void> {
  yield takeLatest(setSocketErrorAction, displayNetworkError);
}
