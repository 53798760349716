import React, { Dispatch, SetStateAction, memo, useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { UserBase } from "@nagano_crm/shared/auth/user.interface";
import { ORDER_STATUSES } from "@nagano_crm/shared/orders/order.types";

import { DropDownList } from "../../../components/dropdown-list/dropdown-list.component";
import typography from "../../../../public/styles/typography.module.scss";
import { Filter } from "../../../components/filter/filter.component";

import { OrderItemComponent } from "./order-item/order-item.component";
import styles from "./orders-table.module.scss";

import { selectOrderCountByStatus, selectOrdersByFilters } from "~/app/order/order.selector";
import SearchIcon from "~/view/assets/search-icon";
import { FilterData } from "~/view/components/filter/filter.types";
import { selectCouriersUser } from "~/app/user/user.selector";
import { ORDER_STATUS_INFO } from "~/typings/constants";

import("dayjs/locale/ru");

export const OrdersTableComponent: React.FC = memo(() => {
  dayjs.locale("ru");
  const [currentCourier, setCurrentCourier]: [string, Dispatch<SetStateAction<string>>] = useState<string>(String);
  const [activeFilters, setActiveFilters]: [FilterData[], Dispatch<SetStateAction<FilterData[]>>] = useState<
    FilterData[]
  >([]);

  const ordersWithFilters: Record<string, string[]> = useSelector(selectOrdersByFilters(activeFilters, currentCourier));

  const filterCount: { total: number; orderCountByStatus: Record<string, number> } = useSelector(
    selectOrderCountByStatus(activeFilters, currentCourier)
  );
  const filteredCouriers: { name: string; uid: string }[] = useSelector(selectCouriersUser);
  const couriers: UserBase[] = useSelector(selectCouriersUser);

  const filterOptions: FilterData[] = [
    ...Object.keys(ORDER_STATUS_INFO).map(key => {
      const orderStatus: ORDER_STATUSES = key as ORDER_STATUSES;
      const count: number = filterCount.orderCountByStatus[key] || 0;
      return { label: ORDER_STATUS_INFO[orderStatus].label, value: key, count: count };
    }),
    { label: "Все", value: "ALL", count: filterCount.total, isDefault: true },
    { label: "Дата", isDateFilter: true }
  ];

  const handleFilterChange: (selectedFilters: FilterData[]) => void = selectedFilters => {
    setActiveFilters(selectedFilters);
  };

  const handleCourierChange: (target: { name: string; value: string | null }) => void = target => {
    setCurrentCourier(String(target.value));
  };

  return (
    <div className={styles.orderTableWrapper}>
      <div className={styles.titlePageSearchInput}>
        <input type="text" placeholder="Все заказы" disabled />
        <SearchIcon />
      </div>
      <Filter
        className={classNames(styles.orderTableFilter)}
        filters={filterOptions}
        activeFilters={activeFilters}
        onFilterChange={handleFilterChange}
        isSingleSelect={true}
      />
      <div className={classNames(styles.headerTable, typography.fs_20)}>
        <div className={styles.order}>Заказ</div>
        <div className={styles.price}>Цена</div>
        <div className={styles.customer}>Клиент</div>
        <div className={styles.delivery}>Адрес</div>
        <div className={styles.courier}>
          <DropDownList
            data={[
              { name: "Все", value: "" },
              ...filteredCouriers.map(courier => {
                return { name: courier.name, value: courier.uid };
              })
            ]}
            displayValue={couriers.find(courier => courier.uid === currentCourier)?.name ?? "Курьер"}
            value={currentCourier ?? ""}
            handleChange={(data): void => handleCourierChange(data)}
            name="courierUid"
          />
        </div>
        <div className={styles.comment}>Комментарий</div>
      </div>
      {Object.entries(ordersWithFilters).map(([date, orderUids]: [string, string[]]) => {
        const dateOrders: Dayjs = dayjs(date, "MM.DD.YYYY");
        return (
          <div key={date}>
            <div className={styles.dateOrders}>
              {dateOrders.isToday() && `Сегодня, `} {dateOrders.format("D MMMM").toString()}
            </div>
            {orderUids.map(orderUid => (
              <OrderItemComponent uid={orderUid} key={orderUid} />
            ))}
          </div>
        );
      })}
    </div>
  );
});

OrdersTableComponent.displayName = "OrdersTableComponent";
