import { EventChannel, Task } from "redux-saga";
import { all, call, cancel, cancelled, Effect, fork, put, select, take, takeEvery } from "redux-saga/effects";
import { Action, PayloadAction } from "typesafe-actions";
import { OrderList, OrderUpdate, OrderCreate } from "@nagano_crm/shared/orders/order.types";
import { Socket } from "socket.io-client";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";
import dayjs from "dayjs";

import { ApplicationStore } from "../application.store";

import {
  createOrderAction,
  fetchOrdersByDateAction,
  handleConnectedOrderSocketAction,
  setMethodPayments,
  setOrderList,
  setRequestedOrdersDate,
  updateOrderAction
} from "./order.actions";
import { createOrderService, fetchOrderCore, getMethodPaymentsService, updateOrderService } from "./order.service";
import { connectSocketOrders, createSocketEventsOrders, socketOrders } from "./order.sockets";

function* connectSocketOrderSaga(): Generator<Effect, void, Task | Socket> {
  let task: Task | null = null;

  try {
    yield call(connectSocketOrders);

    task = (yield fork(socketWatchers)) as Task;
  } finally {
    if (yield cancelled()) {
      socketOrders.close();
      if (task !== null) {
        yield cancel(task);
      }
    }
  }
}

export function* getOrdersSaga(date?: string): Generator {
  const requestedDates: string[] = (yield select((store: ApplicationStore) => store.order.requestedDates)) as string[];
  if (!date || !requestedDates.includes(date)) {
    const orders: OrderList = (yield call(fetchOrderCore, date)) as OrderList;

    yield put(setOrderList(orders));
    yield put(setRequestedOrdersDate(date ?? dayjs(new Date()).format("YYYY-MM-DD")));
  }
}

export function* socketWatchers(): Generator<Effect | Generator, void> {
  const socketEventsOrders: EventChannel<any> = (yield call(createSocketEventsOrders)) as EventChannel<any>;

  while (true) {
    const action: Action<any> = (yield take(socketEventsOrders)) as Action<any>;

    yield put(action);
  }
}

function* connectionSocket(): Generator<Effect | Generator, void> {
  const methodPayments: any = (yield call(getMethodPaymentsService)) as MethodPayment[];

  yield put(setMethodPayments(methodPayments));
}

function* ordersWatchers(): Generator<Effect | Generator, void> {
  yield all([
    takeEvery(handleConnectedOrderSocketAction, () => connectionSocket()),
    takeEvery(createOrderAction, ({ payload }: PayloadAction<string, OrderCreate>) => createOrderService(payload)),
    takeEvery(updateOrderAction, ({ payload }: PayloadAction<string, OrderUpdate>) => updateOrderService(payload)),
    takeEvery(fetchOrdersByDateAction, ({ payload }: PayloadAction<string, string>) => getOrdersSaga(payload))
  ]);
}

export function* ordersSaga(): Generator<Effect | Generator, void> {
  yield all([fork(connectSocketOrderSaga), fork(ordersWatchers)]);
}
