import { OrderBase, OrderUpdate } from "@nagano_crm/shared/orders/order.types";

import { apiFetch } from "../utils/api-fetch";

import { KitchenWorkplace } from "./kitchen.store";
import { socketKitchen } from "./kitchen.sockets";

export const fetchOrdersWorkplace: (workplace: KitchenWorkplace) => Promise<OrderBase[]> = async workplace => {
  return (await socketKitchen.emitWithAck("getOrders", { workplaceUid: workplace?.uid })) as OrderBase[];
};
export const updateOrderService: (data: OrderUpdate) => void = (data: OrderUpdate) => {
  socketKitchen.emit("updateOrder", data);
};

// export const fetchOrderCore: (date?: string) => Promise<OrderList> = async (date?: string) => {
//   return (await socketOrders.emitWithAck("getOrders", { date })) as OrderList;
// };

// export const updateOrderService: (data: OrderUpdate) => void = (data: OrderUpdate) => {
//   socketOrders.emit("update", data);
// };

// export const updateStatusOrderService: (data: OrderUpdateStatus) => void = (data: OrderUpdateStatus) => {
//   socketOrders.emit("updateStatus", data);
// };

// export const fetchOrdersByDateService: (data: string) => Promise<OrderList> = async (data: string) => {
//   return (await socketOrders.emitWithAck("getOrdersByDate", data)) as OrderList;
// };

class KitchenService {
  me(this: void): Promise<KitchenWorkplace> {
    return apiFetch("/kitchen/workplace/auth");
  }

  auth(this: void, workplace: KitchenWorkplace): Promise<KitchenWorkplace> {
    return apiFetch("/kitchen/workplace/auth", { body: JSON.stringify(workplace), method: "POST" });
  }
}

export const kitchenService: KitchenService = new KitchenService();
