import { UserBase, UserCreate } from "@nagano_crm/shared/auth/user.interface";
import { Selector, createSelector } from "reselect";

import { ApplicationStore } from "../application.store";

// const createEmptyUserData: () => UserCreate = () => {
//   return {
//     login: "",
//     name: "",
//     password: "",
//     role_uid: ""
//   };
// };

export const makeSelectCouriersUserByUid: () => Selector<ApplicationStore, UserBase | undefined> = (): Selector<
  ApplicationStore,
  UserBase | undefined
> =>
  createSelector(
    [(state: ApplicationStore): UserBase[] => state.user.users, (_: ApplicationStore, uid: string): string => uid],
    (users, uid) => users.find(user => user.uid === uid && user.role_uid === "a5aa77bc-095f-401a-97d9-0657a2849307")
  );

export const selectCouriersUser: Selector<ApplicationStore, UserBase[]> = createSelector(
  [(state: ApplicationStore): UserBase[] => state.user.users],
  users => users.filter(user => user.role_uid === "a5aa77bc-095f-401a-97d9-0657a2849307")
);

// export const makeSelectOrderByOrderUid: () => Selector<ApplicationStore, OrderBase | undefined> = (): Selector<
//   ApplicationStore,
//   OrderBase | undefined
// > =>
//   createSelector(
//     [
//       (state: ApplicationStore): OrderList => state.order.list,
//       (_: ApplicationStore, orderUid: string): string => orderUid
//     ],
//     (orders: OrderList, orderUid: string) => orders.find(order => order.uid == orderUid)
//   );

export const selectUserByCurrentUid: Selector<ApplicationStore, Partial<UserBase | UserCreate> | null> = createSelector(
  [
    (state: ApplicationStore): UserBase[] => state.user.users,
    (state: ApplicationStore): string | null => state.user.currentUid
  ],
  (users, currentUid) => {
    if (currentUid === null) {
      return null;
    }

    const user: UserBase | undefined = users.find(user => user.uid === currentUid);

    if (user === undefined) {
      return {};
    }

    return user;
  }
);
