import React, { RefObject, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./dropdown-list.module.scss";

export interface TargetDropdown {
  name: string;
  value: string | null;

  [key: string]: string | number | null;
}

interface DropDownProps {
  displayValue?: string;
  value: string | null;
  data: TargetDropdown[];
  name: string;
  disabled?: boolean;
  color?: "default" | "accent";
  styles?: React.CSSProperties;
  buttonStyles?: React.CSSProperties;
  handleChange: (data: TargetDropdown) => void;
  classNames?: { [key: string]: string };
}

export const DropDownList: React.FC<DropDownProps> = (props: DropDownProps) => {
  const [isOpen, setIsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleChangeValue: (data: TargetDropdown) => void = data => {
    if (props.value !== data.value) {
      props.handleChange({ name: data.name, value: data.value });
    }
    toggleDropdown();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const toggleDropdown: () => void = (): void => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={classNames(
        styles.dropDownWrapper,
        props.color === "default" && styles.colorDefault,
        props.classNames?.wrapper
      )}
      ref={ref}
    >
      <div
        className={classNames(styles.selectValue, props.disabled ? styles.disabled : "", props.classNames?.selectValue)}
        onClick={toggleDropdown}
        style={props.buttonStyles}
      >
        {props.displayValue ??
          (props.value === null ? props.data[0].name : props.data.find(item => item.value === props.value)?.name)}
      </div>
      {props.data.length > 1 && (
        <div
          className={classNames(styles.dropDownValues, isOpen ? styles.active : "", props.classNames?.dropdownValues)}
          style={props.styles}
        >
          {props.data.map((value, index) => (
            <div
              className={classNames(styles.value, props.classNames?.fieldsWrapper)}
              onClick={(): void => handleChangeValue({ name: props.name, value: value.value })}
              key={index}
            >
              <div className={styles.value}>{value.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
