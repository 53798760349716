import { OrderBase, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

import { KitchenWorkplace } from "./kitchen.store";

const runCheckIsSignInWorkplace: ActionCreatorWithoutPayload = createAction<void>("workplace/checkIsSignIn");
const signInWorkplace: ActionCreatorWithPayload<KitchenWorkplace> = createAction<KitchenWorkplace>("workplace/signIn");
const setWorkplace: ActionCreatorWithPayload<KitchenWorkplace> = createAction<KitchenWorkplace>("workplace/set");
const setIsSignInWorkplace: ActionCreatorWithPayload<boolean> = createAction<boolean>("workplace/setIsSignIn");

const setOrders: ActionCreatorWithPayload<OrderBase[]> = createAction<OrderBase[]>("kitchen/setOrders");
const setOrderInWorkAction: ActionCreatorWithPayload<string> = createAction<string>("workplace/setOrderInWork");
const setReadyNomenclatures: ActionCreatorWithPayload<{ orderUid: string; nomenclatureUid: string }> = createAction<{
  orderUid: string;
  nomenclatureUid: string;
}>("workplace/setReadyNomenclatures");

const updateOrder: ActionCreatorWithPayload<OrderUpdate> = createAction<OrderUpdate>("kitchen/updateOrder");

const handleCreateOrder: ActionCreatorWithPayload<OrderBase> = createAction<OrderBase>("orders/handleCreate");
const handleUpdateOrder: ActionCreatorWithPayload<OrderUpdate> = createAction<OrderUpdate>("orders/handleUpdate");

export default {
  runCheckIsSignInWorkplace,
  signInWorkplace,
  setWorkplace,
  setIsSignInWorkplace,
  setOrders,
  setOrderInWorkAction,
  setReadyNomenclatures,
  updateOrder,
  handleCreateOrder,
  handleUpdateOrder
};
