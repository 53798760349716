import React, { FC, ReactElement } from "react";

import { ErrorPageProps } from "~/view/error/error-page/error-page.types";
import styles from "~/view/error/error-page/error-page.module.scss";

const ErrorPage: FC<ErrorPageProps> = ({ message }: ErrorPageProps): ReactElement => (
  <div className={styles.root}>
    <b className={styles.title}>{message}</b>
  </div>
);

export default ErrorPage;
