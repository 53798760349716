import { OrderCreate, OrderList, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";

import { socketOrders } from "./order.sockets";

export const fetchOrderCore: (date?: string) => Promise<OrderList> = async (date?: string) => {
  return (await socketOrders.emitWithAck("getOrders", { date })) as OrderList;
};

// export const getListOrdersService: () => void = () => {
//   socketOrders.emit("fetchListOrders");
// };

export const getMethodPaymentsService: () => Promise<MethodPayment> = async () => {
  return (await socketOrders.emitWithAck("getMethodPayments")) as MethodPayment;
};

export const createOrderService: (data: OrderCreate) => void = (data: OrderCreate) => {
  socketOrders.emit("create", data);
};

export const updateOrderService: (data: OrderUpdate) => void = (data: OrderUpdate) => {
  socketOrders.emit("update", data);
};

export const fetchOrdersByDateService: (data: string) => Promise<OrderList> = async (data: string) => {
  return (await socketOrders.emitWithAck("getOrdersByDate", data)) as OrderList;
};

export const fetchOrdersByCustomerService: (cutomerUid: string) => Promise<OrderList> = async (cutomerUid: string) => {
  return (await socketOrders.emitWithAck("getOrdersByCustomer", { uid: cutomerUid })) as OrderList;
};
