import React, { Dispatch, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { SidebarCoreComponent } from "../sidebar-core/sidebar-core.component";
import styles from "../sidebar.module.scss";
import settingsStyles from "../settings/settings.module.scss";

import { SellingPoints } from "./selling-points/selling-points.component";

import childStyles from "~/public/styles/child.module.scss";
import settingsActions from "~/app/settings/settings.actions";

export const Settings: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  // const isSidebarOpen: boolean = useSelector((state: ApplicationStore) => state.settings.isSidebarOpen);
  const [isSellingPointsOpen, setIsSellingPointsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState(false);

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(settingsActions.handleClickCloseSettingsSidebar()),
    [dispatch]
  );

  return (
    <SidebarCoreComponent actionOnClickClose={handleCloseSidebar}>
      <div className={classNames(styles.sidebarWrapper, settingsStyles.sidebarWrapper)}>
        <header>
          {/* <input type="text" name="name" placeholder="ФИО" value={""} onChange={(e): void => console.log(1)} /> */}
          Настройки
        </header>
        <div className={styles.mainWrapper}>
          <div
            onClick={(): void => {
              setIsSellingPointsOpen(true);
            }}
            className={classNames(childStyles.title_s_size, childStyles.cursor_pointer)}
          >
            Подразделения
          </div>
        </div>
      </div>
      {isSellingPointsOpen && <SellingPoints />}
    </SidebarCoreComponent>
  );
};
