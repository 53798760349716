import React, { PureComponent, ReactElement } from "react";

import ErrorPage from "~/view/error/error-page/error-page.component";
import { GlobalErrorComponentProps } from "~/view/error/global/global-error.types";

interface GlobalErrorState {
  hasError: boolean;
}

export class GlobalErrorComponent extends PureComponent<GlobalErrorComponentProps, GlobalErrorState> {
  state: GlobalErrorState = {
    hasError: false
  };

  static getDerivedStateFromError(error: Error): GlobalErrorState {
    console.error("Unhandled react error", error);
    return { hasError: true };
  }

  render(): ReactElement {
    if (this.state.hasError) {
      return <ErrorPage message="Извините, что-то пошло не так." />;
    }
    const { error, children }: GlobalErrorComponentProps = this.props;
    if (error !== null) {
      return <ErrorPage message={error.title} />;
    }
    return <>{children}</>;
  }
}
