import React, { RefObject, useRef, useState } from "react";
import classNames from "classnames";

import styles from "./sidebar-core.module.scss";
import { SibebarCoreProps } from "./sidebar-core.types";

import CloseIcon from "~/view/assets/close-icon";
import ConfirmationPopup from "~/view/components/confirm-popup/confirm-popup.component";
export const SidebarCoreComponent: React.FC<SibebarCoreProps> = (props: SibebarCoreProps) => {
  const sidebarRef: RefObject<HTMLDivElement> = useRef(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState(false);

  // const handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent): void => {
  //   if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
  //     props.actionOnClickClose();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const handleDeleteClick: () => void = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete: () => void = () => {
    setShowDeleteConfirmation(false);
    if (props.actionOnClickDelete) {
      props.actionOnClickDelete();
    }
  };

  const handleCloseClick: () => void = () => {
    if (props.isFormChanged) {
      setShowCloseConfirmation(true);
    } else {
      if (props.actionOnClickClose) {
        props.actionOnClickClose();
      }
    }
  };

  const handleConfirmClose: () => void = () => {
    setShowCloseConfirmation(false);
    if (props.actionOnClickClose) {
      props.actionOnClickClose();
    }
  };

  return (
    <>
      <div
        className={classNames(styles.sidebarCoreWrapper, props.fullSidebar ? styles.fullSidebar : "")}
        ref={sidebarRef}
      >
        <div className={styles.actionButtonsWrapper}>
          {props.actionOnClickDelete && (
            <div className={styles.deleteButton} onClick={handleDeleteClick}>
              Удалить
            </div>
          )}
          {props.actionOnClickSave && (
            <div className={styles.saveButton} onClick={props.actionOnClickSave}>
              Сохранить
            </div>
          )}
          <div className={styles.closeButton} onClick={handleCloseClick}>
            <CloseIcon />
          </div>
        </div>
        {props.children}
      </div>

      <ConfirmationPopup
        isOpen={showDeleteConfirmation}
        onConfirm={handleConfirmDelete}
        onCancel={(): void => setShowDeleteConfirmation(false)}
        title="Подтвердите удаление"
        description="Вы уверены, что хотите удалить этот элемент? Это действие нельзя отменить."
        confirmText="Удалить"
        cancelText="Отмена"
      />

      <ConfirmationPopup
        isOpen={showCloseConfirmation}
        onConfirm={handleConfirmClose}
        onCancel={(): void => setShowCloseConfirmation(false)}
        title="Подтвердите закрытие"
        description="Вы уверены, что хотите закрыть окно без сохранения изменений?"
        confirmText="Закрыть"
        cancelText="Отмена"
      />
    </>
  );
};

export default styles;
