import { SellingPointBase } from "@nagano_crm/shared/settings/settings.types";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";

const handleClickCloseSettingsSidebar: ActionCreatorWithoutPayload = createAction<void>("settings/clickClose");

const handleClickOpenSettingsSidebar: ActionCreatorWithoutPayload = createAction<void>("settings/clickOpen");

const handleClickNewSellingPoint: ActionCreatorWithoutPayload = createAction<void>("settings/clickNewPoint");

const handleClickOpenSellingPointsSidebar: ActionCreatorWithoutPayload = createAction<void>("settings/clickOpenPoints");

const handleClickCloseSellingPointsSidebar: ActionCreatorWithoutPayload =
  createAction<void>("settings/clickClosePoints");

const handleClickOpenSellingPointSidebar: ActionCreatorWithoutPayload = createAction<void>("settings/clickOpenPoint");
const handleClickCloseSellingPointSidebar: ActionCreatorWithoutPayload = createAction<void>("settings/clickClosePoint");

const setSellingPointList: ActionCreatorWithPayload<SellingPointBase[]> =
  createAction<SellingPointBase[]>("sellingPoint/setList");
const resetListSellingPoint: ActionCreatorWithoutPayload = createAction<void>("sellingPoint/reset");
const handleSellingPointCreate: ActionCreatorWithPayload<SellingPointBase> =
  createAction<SellingPointBase>("sellingPoint/handleCreate");
const handleSellingPointUpdate: ActionCreatorWithPayload<SellingPointBase> =
  createAction<SellingPointBase>("sellingPoint/handleUpdate");
const handleSellingPointDelete: ActionCreatorWithPayload<string> = createAction<string>("sellingPoint/handleDelete");

const changeCurrentSellingPoint: ActionCreatorWithPayload<string | null, string> = createAction<string | null>(
  "sellingPoint/changeCurrent"
);

const createSellingPoint: ActionCreatorWithPayload<SellingPointBase> =
  createAction<SellingPointBase>("sellingPoint/create");

const updateSellingPoint: ActionCreatorWithPayload<SellingPointBase> =
  createAction<SellingPointBase>("sellingPoint/update");

const deleteSellingPoint: ActionCreatorWithPayload<string> = createAction<string>("sellingPoint/delete");

export default {
  handleClickCloseSettingsSidebar,
  handleClickOpenSettingsSidebar,
  handleClickNewSellingPoint,
  handleClickOpenSellingPointsSidebar,
  handleClickCloseSellingPointsSidebar,
  setSellingPointList,
  resetListSellingPoint,
  handleSellingPointCreate,
  handleSellingPointUpdate,
  handleSellingPointDelete,
  changeCurrentSellingPoint,
  createSellingPoint,
  updateSellingPoint,
  deleteSellingPoint,
  handleClickOpenSellingPointSidebar,
  handleClickCloseSellingPointSidebar
};
