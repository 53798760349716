import { Socket } from "socket.io-client";
import { eventChannel } from "redux-saga";
import { NomenclatureBase } from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import { NomenclatureCategoryBase } from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";

import { manager } from "../socket.utils";

import nomenclaturesActions from "./nomenclatures.actions";
import nomenclaturesCategoryActions from "./nomenclatures-category.actions";

export const createSocketEventsNomenclatures: any = (): any =>
  eventChannel(emit => {
    socketNomenclature.on("connect", () => {
      emit(nomenclaturesActions.handleConnectedSocket());
    });

    //Nomenclature
    socketNomenclature.on("createOne", (data: NomenclatureBase) => {
      emit(nomenclaturesActions.handleNomenclatureCreate(data));
    });

    socketNomenclature.on("updateOne", (data: NomenclatureBase) => {
      emit(nomenclaturesActions.handleNomenclatureUpdate(data));
    });

    socketNomenclature.on("updateMore", (data: NomenclatureBase[]) => {
      emit(nomenclaturesActions.handleNomenclatureUpdateMore(data));
    });

    socketNomenclature.on("deleteOne", (data: string) => {
      emit(nomenclaturesActions.handleNomenclatureDelete(data));
    });

    //Nomenclature Categories
    socketNomenclature.on("createOneCategory", (data: NomenclatureCategoryBase) => {
      emit(nomenclaturesCategoryActions.handleNomenclatureCategoryCreate(data));
    });

    socketNomenclature.on("updateOneCategory", (data: NomenclatureCategoryBase) => {
      emit(nomenclaturesCategoryActions.handleNomenclatureCategoryUpdate(data));
    });
    socketNomenclature.on("deleteOneCategory", (data: string) => {
      emit(nomenclaturesCategoryActions.handleNomenclatureCategoryDelete(data));
    });

    return (): void => {
      socketNomenclature.off("connect");
      socketNomenclature.off("createOne");
      socketNomenclature.off("updateOne");
      socketNomenclature.off("deleteOne");
      socketNomenclature.off("createOneCategory");
      socketNomenclature.off("updateOneCategory");
      socketNomenclature.off("deleteOneCategory");
    };
  });

export let socketNomenclature: Socket;

export function connectSocketNomenclature(): Socket {
  if (!socketNomenclature) {
    socketNomenclature = manager.socket(`/nomenclature`);
  }

  return socketNomenclature;
}
