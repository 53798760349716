import { OrderBase, OrderList, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import { PayloadAction, createReducer, combineReducers, Reducer } from "@reduxjs/toolkit";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";
import { OrderDeliveryBase } from "@nagano_crm/shared/orders/order-delivery.types";
import _ from "lodash";

import { mergeAndSortByDeliveryAt } from "../utils/helpers";

import {
  changeCurrentOrderAction,
  createNewOrderAction,
  fetchOrdersByDateAction,
  handleOrderCreate,
  handleOrderUpdate,
  resetListOrdersActions,
  setMethodPayments,
  setOrderList,
  setRequestedOrdersDate
} from "./order.actions";

import { FilterData } from "~/view/components/filter/filter.types";

export interface OrderStore {
  list: OrderList;
  currentOrderUid: string | null;
  isOpenSidebarOrder: boolean;
  methodPayments: MethodPayment[];
  requestedDates: string[];
  filters: FilterData[];
}

export const orderReducer: Reducer<OrderStore> = combineReducers({
  list: createReducer<OrderList>([], builder => {
    builder
      .addCase(resetListOrdersActions, () => [])
      .addCase(setOrderList, (state, { payload }: PayloadAction<OrderList>) =>
        mergeAndSortByDeliveryAt([...state, ...payload])
      )
      .addCase(handleOrderCreate, (state, { payload }: PayloadAction<OrderBase>) =>
        mergeAndSortByDeliveryAt([...state, payload])
      )
      .addCase(handleOrderUpdate, (state, { payload }: PayloadAction<OrderUpdate>) =>
        mergeAndSortByDeliveryAt(
          state.map(order => {
            return order.uid === payload.uid
              ? {
                  ...order,
                  ...payload,
                  delivery:
                    !_.isEmpty({ ...order.delivery, ...payload.delivery }) && payload.deliveryType !== "pickup"
                      ? ({ ...order.delivery, ...payload.delivery } as OrderDeliveryBase | null)
                      : null
                }
              : order;
          })
        )
      );
  }),
  currentOrderUid: createReducer<string | null>(null, builder => {
    builder
      .addCase(changeCurrentOrderAction, (_: string | null, { payload }: PayloadAction<string | null>) => payload)
      .addCase(createNewOrderAction, () => "");
  }),
  isOpenSidebarOrder: createReducer<boolean>(false, builder => {
    builder
      .addCase(changeCurrentOrderAction, (_: boolean, { payload }: PayloadAction<string | null>) => !!payload)
      .addCase(createNewOrderAction, () => true);
  }),
  methodPayments: createReducer<MethodPayment[]>([], builder => {
    builder.addCase(setMethodPayments, (_, { payload }: PayloadAction<MethodPayment[]>) => payload);
  }),
  requestedDates: createReducer<string[]>([], builder => {
    builder.addCase(setRequestedOrdersDate, (state, { payload }: PayloadAction<string>) => {
      if (state.indexOf(payload) === -1) {
        return [...state, payload];
      }
      return state;
    });
  }),
  filters: createReducer<FilterData[]>([], builder => {
    builder.addCase(fetchOrdersByDateAction, (state, { payload }: PayloadAction<string>) => {
      return [...state, { label: payload, value: payload }];
    });
  })
});
