import { useState, useEffect } from "react";

export interface Field {
  name: string;
  value: string | undefined | null;
  error: string;
}

export interface ValidationError {
  name: string;
  error: string;
}

export interface UseValidationResult {
  validationErrors: ValidationError[];
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationError[]>>;
  showErrors: boolean;
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  getErrorMessage: (fieldName: string) => string | undefined;
  validateFieldsWithErrors: (fields: Field[]) => ValidationError[];
}

const useValidation: (fields: Field[], formState: any) => UseValidationResult = (fields, formState) => {
  const [validationErrors, setValidationErrors]: [
    ValidationError[],
    React.Dispatch<React.SetStateAction<ValidationError[]>>
  ] = useState<ValidationError[]>([]);
  const [showErrors, setShowErrors]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);

  const validateFieldsWithErrors: (fields: Field[]) => ValidationError[] = (fields: Field[]): ValidationError[] => {
    return fields.filter(field => {
      if (!field.value || field.value.trim() === "") {
        return true;
      }
      if (field.name === "email") {
        const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(field.value)) {
          field.error = "Неверный формат почты";
          return true;
        }
      }
      return false;
    });
  };

  useEffect(() => {
    setValidationErrors(validateFieldsWithErrors(fields));
  }, [formState]);

  const getErrorMessage: (fieldName: string) => string | undefined = (fieldName: string): string | undefined => {
    if (!showErrors) {
      return undefined;
    }
    const error: ValidationError | undefined = validationErrors.find(error => error.name === fieldName);
    return error ? error.error : undefined;
  };

  return {
    validationErrors,
    setValidationErrors,
    showErrors,
    setShowErrors,
    getErrorMessage,
    validateFieldsWithErrors
  };
};

export default useValidation;
