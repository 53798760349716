import { ORDER_STATUSES, OrderList } from "@nagano_crm/shared/orders/order.types";

export const mergeAndSortByDeliveryAt: (data: OrderList) => OrderList = (data: OrderList): OrderList => {
  return data.sort((a, b) => Number(new Date(a.deliveryAt)) - Number(new Date(b.deliveryAt)));
};

export const sortByStatus: (data: OrderList) => OrderList = (data: OrderList): OrderList => {
  return data.sort((a, b) => {
    if (a.status === ORDER_STATUSES.NEW && b.status !== ORDER_STATUSES.NEW) {
      return -1;
    }
    if (a.status !== ORDER_STATUSES.NEW && b.status === ORDER_STATUSES.NEW) {
      return 1;
    }
    return 0;
  });
};

export const formatPhoneNumber: (phoneNumber: string | undefined) => string = (phoneNumber): string => {
  if (!phoneNumber) {
    return "";
  }
  const cleaned: string = ("" + phoneNumber).replace(/\D/g, "");
  const match: RegExpMatchArray | null = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+7 (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
};
