import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import styles from "./auth.module.scss";

import { ApplicationStore } from "~/app/application.store";
import { KitchenWorkplace } from "~/app/kitchen/kitchen.store";
import kitchenActions from "~/app/kitchen/kitchen.actions";

interface AuthKitchen {
  login: string;
}

export const AuthKitchenComponent: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const workplace: KitchenWorkplace | null = useSelector((state: ApplicationStore) => state.kitchen.workplace);
  const isSignInWorkplace: boolean | null = useSelector((state: ApplicationStore) => state.kitchen.isSignInWorkpalce);

  useEffect(() => {
    dispatch(kitchenActions.runCheckIsSignInWorkplace());
  }, []);

  const [auth, setAuth]: [AuthKitchen, React.Dispatch<React.SetStateAction<AuthKitchen>>] = useState<AuthKitchen>(
    {} as AuthKitchen
  );

  const handleChange: (target: { name: string; value: string }) => void = target => {
    setAuth({ ...auth, [target.name]: target.value });
  };

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void = e => {
    e.preventDefault();
    dispatch(kitchenActions.signInWorkplace(auth));
  };

  if (isSignInWorkplace === null) {
    return <></>;
  }

  if (!workplace && !isSignInWorkplace) {
    return (
      <div className={styles.authWrap}>
        <div className={styles.container}>
          <div className={styles.title}>Вход</div>
          <form onSubmit={handleSubmit}>
            <input name="login" onChange={(e): void => handleChange(e.target)} placeholder="Логин" />
            <button>Войти</button>
          </form>
        </div>
      </div>
    );
  }

  return <Outlet />;
};
