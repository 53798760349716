import { InitCoreUser, UserBase, UserCreate, UserUpdate } from "@nagano_crm/shared/auth/user.interface";

import { userSocket } from "./user.socket";

const initCore: () => Promise<InitCoreUser> = async () => {
  return (await userSocket.emitWithAck("initCore")) as InitCoreUser;
};

const create: (data: UserCreate) => Promise<UserBase | undefined> = async (data: UserCreate) => {
  return (await userSocket.emitWithAck("create", data)) as UserBase | undefined;
};

const update: (data: UserUpdate) => Promise<UserUpdate | undefined> = async (data: UserUpdate) => {
  const updateUser: UserUpdate | undefined = (await userSocket.emitWithAck("update", data)) as UserUpdate | undefined;
  return updateUser;
};

const deleteOne: (data: string) => Promise<{ status: string; data: any }> = async data => {
  return (await userSocket.emitWithAck("delete", data)) as { status: string; data: any };
};

export default { initCore, create, update, deleteOne };
