import { NomenclatureBase, NomenclatureUpdate } from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import { createReducer, combineReducers, Reducer, PayloadAction, isAnyOf } from "@reduxjs/toolkit";
import {
  NomenclatureCategoriesList,
  NomenclatureCategoryBase
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";

import nomenclaturesActions from "./nomenclatures.actions";
import nomenclaturesCategoryActions from "./nomenclatures-category.actions";

export interface NomenclatureStore {
  list: Record<string, NomenclatureBase>;
  listCategories: Record<string, NomenclatureCategoryBase>;
  currentNomenclatureUid: string | null;
  currentNomenclatureCategoryUid: string | null;
  isOpenSidebarNomenclature: boolean;
  isOpenSidebarNomenclatureCategory: boolean;
}

const listReducer: Reducer<Record<string, NomenclatureBase>> = createReducer<Record<string, NomenclatureBase>>(
  {},
  builder => {
    builder
      .addCase(nomenclaturesActions.resetListNomenclatures, () => {})
      .addCase(
        nomenclaturesActions.setNomenclatureList,
        (_: Record<string, NomenclatureBase>, { payload }: { payload: NomenclatureBase[] }) => {
          return payload.reduce(
            (acc, item) => {
              acc[item.uid] = item;
              return acc;
            },
            {} as Record<string, NomenclatureBase>
          );
        }
      )
      .addCase(
        nomenclaturesActions.handleNomenclatureCreate,
        (state: Record<string, NomenclatureBase>, { payload }: PayloadAction<NomenclatureBase>) => ({
          ...state,
          [payload.uid]: payload
        })
      )
      .addCase(
        nomenclaturesActions.handleNomenclatureUpdate,
        (state: Record<string, NomenclatureBase>, { payload }: PayloadAction<NomenclatureUpdate>) => ({
          ...state,
          [payload.uid]: { ...state[payload.uid], ...payload }
        })
      )
      .addCase(
        nomenclaturesActions.handleNomenclatureUpdateMore,
        (state: Record<string, NomenclatureBase>, { payload }: PayloadAction<NomenclatureBase[]>) => {
          const newState: Record<string, NomenclatureBase> = { ...state };
          payload.forEach(item => {
            newState[item.uid] = item;
          });
          return newState;
        }
      )
      .addCase(
        nomenclaturesActions.handleNomenclatureDelete,
        (state: Record<string, NomenclatureBase>, { payload }: PayloadAction<string>) => {
          const newState: Record<string, NomenclatureBase> = { ...state };
          delete newState[payload];
          return newState;
        }
      );
  }
);

const listCategoriesReducer: Reducer<Record<string, NomenclatureCategoryBase>> = createReducer<
  Record<string, NomenclatureCategoryBase>
>({}, builder =>
  builder
    .addCase(
      nomenclaturesCategoryActions.setNomenclatureCategoriesList,
      (_: Record<string, NomenclatureCategoryBase>, { payload }: { payload: NomenclatureCategoriesList }) => {
        return payload.reduce(
          (acc, item) => {
            acc[item.uid] = item;
            return acc;
          },
          {} as Record<string, NomenclatureCategoryBase>
        );
      }
    )
    .addCase(
      nomenclaturesCategoryActions.handleNomenclatureCategoryCreate,
      (state: Record<string, NomenclatureCategoryBase>, { payload }: PayloadAction<NomenclatureCategoryBase>) => ({
        ...state,
        [payload.uid]: payload
      })
    )
    .addCase(
      nomenclaturesCategoryActions.handleNomenclatureCategoryUpdate,
      (state: Record<string, NomenclatureCategoryBase>, { payload }: PayloadAction<NomenclatureCategoryBase>) => ({
        ...state,
        [payload.uid]: { ...state[payload.uid], ...payload }
      })
    )
    .addCase(
      nomenclaturesCategoryActions.handleNomenclatureCategoryDelete,
      (state: Record<string, NomenclatureCategoryBase>, { payload }: PayloadAction<string>) => {
        const newState: Record<string, NomenclatureCategoryBase> = { ...state };
        delete newState[payload];
        return newState;
      }
    )
);

export const nomenclatureReducer: Reducer<NomenclatureStore> = combineReducers({
  list: listReducer,
  listCategories: listCategoriesReducer,
  currentNomenclatureUid: createReducer<string | null>(null, builder => {
    builder
      .addCase(
        nomenclaturesActions.changeCurrentNomenclature,
        (_, { payload }: PayloadAction<string | null>) => payload
      )
      .addCase(nomenclaturesActions.createNewNomenclature, () => "");
  }),
  currentNomenclatureCategoryUid: createReducer<string | null>(null, builder => {
    builder
      .addCase(
        nomenclaturesCategoryActions.changeCurrentNomenclatureCategory,
        (_, { payload }: PayloadAction<string | null>) => payload
      )
      .addCase(nomenclaturesCategoryActions.createNewNomenclatureCategory, () => "");
  }),
  isOpenSidebarNomenclature: createReducer<boolean>(false, builder => {
    builder
      .addCase(
        nomenclaturesActions.changeCurrentNomenclature,
        (_: boolean, { payload }: PayloadAction<string | null>) => !!payload
      )
      .addCase(nomenclaturesCategoryActions.changeCurrentNomenclatureCategory, () => false)
      .addCase(nomenclaturesActions.createNewNomenclature, () => true)
      .addMatcher(
        isAnyOf(
          nomenclaturesActions.createNomenclature,
          nomenclaturesActions.updateNomenclature,
          nomenclaturesActions.deleteNomenclature
        ),
        () => false
      );
  }),
  isOpenSidebarNomenclatureCategory: createReducer<boolean>(false, builder => {
    builder
      .addCase(
        nomenclaturesCategoryActions.changeCurrentNomenclatureCategory,
        (_: boolean, { payload }: PayloadAction<string | null>) => !!payload
      )
      .addCase(nomenclaturesActions.changeCurrentNomenclature, () => false)
      .addCase(nomenclaturesCategoryActions.createNewNomenclatureCategory, () => true)
      .addMatcher(
        isAnyOf(
          nomenclaturesCategoryActions.createNomenclatureCategory,
          nomenclaturesCategoryActions.updateNomenclatureCategory,
          nomenclaturesCategoryActions.deleteNomenclatureCategory
        ),
        () => false
      );
  })
});
