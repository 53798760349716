import { Action } from "redux";
import { EventChannel, Task } from "redux-saga";
import { Effect, put, all, takeEvery, fork, call, cancel, cancelled, take } from "redux-saga/effects";
import { Socket } from "socket.io-client";
import { CustomerBase, CustomerCreate } from "@nagano_crm/shared/customer/customers.types";
import { PayloadAction } from "@reduxjs/toolkit";
import { OrderList } from "@nagano_crm/shared/orders/order.types";

import { fetchOrdersByCustomerService } from "../order/order.service";

import customerActions from "./customer.actions";
import {
  createCustomerService,
  deleteCustomerService,
  fetchCustomerCore,
  searchCustomerByPhone,
  updateCustomerService
} from "./customer.service";
import { connectSocketCustomer, createSocketEventsCustomer, socketCustomer } from "./customer.sockets";

function* getOrdersByCustomer(uid: string | null): Generator<Effect, void> {
  if (uid !== null) {
    const response: OrderList = (yield call(fetchOrdersByCustomerService, uid)) as OrderList;

    yield put(customerActions.setOrdersCustomer(response));
  }
}

function* searchByPhone(phone: string): Generator<Effect, void> {
  const response: CustomerBase[] = (yield call(searchCustomerByPhone, phone)) as CustomerBase[];

  yield put(customerActions.setResultSearchByPhone(response));
}

function* connectSocketCustomerSaga(): Generator<Effect, void, Task | Socket> {
  let task: Task | null = null;

  try {
    yield call(connectSocketCustomer);

    task = (yield fork(socketWatchers)) as Task;
  } finally {
    if (yield cancelled()) {
      socketCustomer.close();
      if (task !== null) {
        yield cancel(task);
      }
    }
  }
}

export function* socketWatchers(): Generator<Effect | Generator, void> {
  const socketEventsCustomer: EventChannel<any> = (yield call(createSocketEventsCustomer)) as EventChannel<any>;

  while (true) {
    const action: Action<any> = (yield take(socketEventsCustomer)) as Action<any>;

    yield put(action);
  }
}

function* connectionSocket(): Generator<Effect | Generator, void> {
  const response: CustomerBase[] = (yield call(fetchCustomerCore)) as CustomerBase[];

  yield put(customerActions.setCustomerList(response));
}

function* customerWatchers(): Generator<Effect | Generator, void> {
  yield all([
    takeEvery(customerActions.handleConnectedCustomerSocket, () => connectionSocket()),
    takeEvery(customerActions.changeCurrentCustomer, ({ payload }: PayloadAction<string | null>) =>
      getOrdersByCustomer(payload)
    ),
    takeEvery(customerActions.createCustomer, ({ payload }: PayloadAction<CustomerCreate>) =>
      createCustomerService(payload)
    ),
    takeEvery(customerActions.updateCustomer, ({ payload }: PayloadAction<CustomerBase>) =>
      updateCustomerService(payload)
    ),
    takeEvery(customerActions.deleteCustomer, ({ payload }: PayloadAction<string>) => deleteCustomerService(payload)),
    takeEvery(customerActions.searchCustomerByPhone, ({ payload }: PayloadAction<string>) => searchByPhone(payload))
  ]);
}

export function* customerSaga(): Generator<Effect | Generator, void> {
  yield all([fork(connectSocketCustomerSaga), fork(customerWatchers)]);
}
