import React from "react";

const AccordionButtonIcon: React.FC = () => (
  <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 4.13375C8.91667 4.51865 8.91667 5.4809 8.25 5.8658L4.875 7.81435L1.5 9.76291C0.833334 10.1478 0 9.66669 0 8.89689V1.10266C0 0.332856 0.833333 -0.148269 1.5 0.236631L8.25 4.13375Z"
      fill="black"
    />
  </svg>
);
export default AccordionButtonIcon;
