import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import styles from "./auth.module.scss";
import { AuthComponentProps, AuthState } from "./auth.types";

const AuthComponent: React.FC<AuthComponentProps> = (props: AuthComponentProps) => {
  const [auth, setAuth]: [AuthState, React.Dispatch<React.SetStateAction<AuthState>>] = useState<AuthState>({
    login: "",
    password: ""
  });

  useEffect(() => {
    props.handleRunCheckIsAuthAction();
  }, []);

  const handleChange: (target: { name: string; value: string }) => void = (target: {
    name: string;
    value: string;
  }): void => {
    setAuth({ ...auth, [target.name]: target.value });
  };

  const handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    props.setUserAuthDataAction(auth);
    props.authenticate();
  };

  if (props.isAuth === null) {
    return <></>;
  }

  if (props.user === null && props.isAuth === false) {
    return (
      <div className={styles.authWrap}>
        <div className={styles.container}>
          <div className={styles.title}>Вход</div>
          <form onSubmit={handleSubmit}>
            <input name="login" onChange={(e): void => handleChange(e.target)} placeholder="Логин" />
            <input
              type="password"
              name="password"
              onChange={(e): void => handleChange(e.target)}
              placeholder="Пароль"
            />
            <button>Войти</button>
          </form>
        </div>
      </div>
    );
  }
  return <Outlet />;
};

export default AuthComponent;
