import React, { Dispatch, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { SidebarCoreComponent } from "../../sidebar-core/sidebar-core.component";
import sidebarStyles from "../../sidebar.module.scss";

import styles from "./selling-points.module.scss";
import { SellingPointsProps } from "./selling-points.types";
import { SellingPoint } from "./selling-point/selling-point.component";

import childStyles from "~/public/styles/child.module.scss";
import { ApplicationStore } from "~/app/application.store";
import settingsActions from "~/app/settings/settings.actions";

export const SellingPoints: React.FC<SellingPointsProps> = memo((props: SellingPointsProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const isSellingPointSidebarOpen: boolean = useSelector(
    (state: ApplicationStore) => state.settings.isSellingPointSidebarOpen
  );

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(settingsActions.handleClickCloseSidebar()),
    [dispatch]
  );

  // const [isSellingPointSidebarOpen, setIsSellingPointSidebarOpen]: [
  //   boolean,
  //   React.Dispatch<React.SetStateAction<boolean>>
  // ] = useState(false);

  const sellingPoints: { id: number; name: string; createdAt: string; updatedAt: string }[] = [
    {
      id: 1,
      name: "Сысерть",
      createdAt: moment().format("DD.MM.YY HH:mm"),
      updatedAt: moment().format("DD.MM.YY HH:mm")
    },
    {
      id: 2,
      name: "Ревда",
      createdAt: moment().format("DD.MM.YY HH:mm"),
      updatedAt: moment().format("DD.MM.YY HH:mm")
    },
    {
      id: 3,
      name: "Первоуральск",
      createdAt: moment().format("DD.MM.YY HH:mm"),
      updatedAt: moment().format("DD.MM.YY HH:mm")
    }
  ];

  return (
    <SidebarCoreComponent actionOnClickClose={handleCloseSidebar}>
      <div className={classNames(sidebarStyles.sidebarWrapper, styles.sidebarWrapper)}>
        <div className={classNames(childStyles.title_s_size)}>Подразделения</div>
        <div className={classNames(styles.sellingPointsWrapper)}>
          {sellingPoints.map((sellingPoint, index) => {
            return (
              <div key={index} className={classNames(styles.sellingPointItem)}>
                <div className={styles.sellingPointName}>{sellingPoint.name}</div>
                <div className={styles.sellingPointDate}></div>
              </div>
            );
          })}
        </div>
        <div
          className={sidebarStyles.btnOrderNew}
          onClick={(): void => {
            dispatch(settingsActions.handleClickNewSellingPoint());
            // setIsSellingPointSidebarOpen(true);
          }}
        >
          +
        </div>
      </div>
      {isSellingPointSidebarOpen && <SellingPoint />}
    </SidebarCoreComponent>
  );
});

SellingPoints.displayName = "SellingPoints";
