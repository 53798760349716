import React, { Dispatch } from "react";
import { Link, useLocation, Location } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Settings } from "../sidebars/settings/settings.component";

import styles from "./menu.module.scss";

import { ApplicationStore } from "~/app/application.store";
import settingsActions from "~/app/settings/settings.actions";

const Menu: React.FC = () => {
  const location: Location<any> = useLocation();
  // const [settingsSidebarOpen, setSettingsSidebarOpen]: [boolean, (open: boolean) => void] = useState<boolean>(false);
  const isSettingsSidebarOpen: boolean = useSelector((state: ApplicationStore) => state.settings.isSettingsSidebarOpen);
  const dispatch: Dispatch<any> = useDispatch();

  return (
    <div className={styles.menuWrapper}>
      <Link to="/" className={classNames(styles.menuLink, styles.logo)}>
        Нагано
      </Link>

      <Link to="/" className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/" })}>
        Заказы
      </Link>
      <Link
        to="/catalog"
        className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/catalog" })}
      >
        Номенклатура
      </Link>
      <Link
        to="/clients"
        className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/clients" })}
      >
        Клиенты
      </Link>
      <Link to="/users" className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/users" })}>
        Сотрудники
      </Link>
      <Link
        to="/kitchen"
        className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/kitchen" })}
      >
        Кухня
      </Link>
      <Link
        to="#"
        onClick={(): void => dispatch(settingsActions.handleClickOpenSettingsSidebar())}
        className={classNames(styles.menuLink, { [styles.active]: location.pathname === "/settings" })}
      >
        Настройки
      </Link>
      {isSettingsSidebarOpen && <Settings />}
    </div>
  );
};

export default Menu;
