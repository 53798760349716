import { eventChannel } from "redux-saga";
import { Socket } from "socket.io-client";
import { OrderBase, OrderUpdate } from "@nagano_crm/shared/orders/order.types";

import { setSocketErrorAction } from "../error/error.actions";
import { SocketError } from "../error/error.store";
import { manager } from "../socket.utils";

import kitchenActions from "./kitchen.actions";

export const createSocketEventsKitchen: any = (): any =>
  eventChannel(emit => {
    // socketKitchen.on("connect", () => {
    //   emit(handleConnectedOrderSocketAction());
    // });

    // socketKitchen.on("lists", (data: OrderList) => {
    //   emit(handleGetListOrders(data));
    // });

    socketKitchen.on("createOrder", (data: OrderBase) => {
      emit(kitchenActions.handleCreateOrder(data));
    });

    socketKitchen.on("updateOrder", (data: OrderUpdate) => {
      emit(kitchenActions.handleUpdateOrder(data));
    });

    socketKitchen.on("exception", (data: SocketError) => {
      emit(setSocketErrorAction(data));
    });

    return (): void => {
      socketKitchen.off("createOrder");
      socketKitchen.off("updateOrder");
      socketKitchen.off("exception");
    };
  });

export let socketKitchen: Socket;

export function connectSocketKitchen(): Socket {
  if (!socketKitchen) {
    socketKitchen = manager.socket(`/kitchen`);
  }

  return socketKitchen;
}
