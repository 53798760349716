import { ORDER_STATUSES } from "@nagano_crm/shared/orders/order.types";

export const TRANSLATE_DELIVERY_TYPE: { [name: string]: string } = {
  pickup: "Самовывоз",
  delivery: "Доставка"
};
export const ORDER_STATUS_INFO: Record<ORDER_STATUSES, { label: string; nextStatus: ORDER_STATUSES | null }> = {
  [ORDER_STATUSES.NEW]: { label: "Новый", nextStatus: ORDER_STATUSES.IN_PROGRESS },
  [ORDER_STATUSES.IN_PROGRESS]: { label: "В работе", nextStatus: ORDER_STATUSES.ASSEMBLY },
  [ORDER_STATUSES.ASSEMBLY]: { label: "Сборка", nextStatus: ORDER_STATUSES.FOR_DELIVERY },
  [ORDER_STATUSES.FOR_DELIVERY]: { label: "На доставку", nextStatus: ORDER_STATUSES.DELIVERED },
  [ORDER_STATUSES.DELIVERED]: { label: "Доставлен", nextStatus: ORDER_STATUSES.COMPLETED },
  [ORDER_STATUSES.COMPLETED]: { label: "Завершён", nextStatus: null },
  [ORDER_STATUSES.CANCELED]: { label: "Отменён", nextStatus: null }
};

// export const ORDER_STATUSES: { [name: string]: { index: number; nextStatus: string | null } } = {
//   NEW: { index: 1, nextStatus: "IN_PROGRESS" },
//   IN_PROGRESS: { index: 2, nextStatus: "ASSEMBLY" },
//   ASSEMBLY: { index: 3, nextStatus: "FOR_DELIVERY" },
//   FOR_DELIVERY: { index: 4, nextStatus: "DELIVERED" },
//   DELIVERED: { index: 5, nextStatus: "COMPLETED" },
//   COMPLETED: { index: 6, nextStatus: null }
// };

export const CITIES: {
  name: string;
}[] = [
  { name: "Первоуральск" },
  { name: "Ревда" },
  { name: "Сухой Лог" },
  { name: "Сысерть" },
  { name: "Заречный" },
  { name: "Каменск-Уральский" }
];

export const STATUS_RUSSIAN: { [name: string]: string } = {
  NEW: "Новый",
  IN_PROGRESS: "В работе",
  ASSEMBLY: "Сборка",
  FOR_DELIVERY: "На доставку",
  DELIVERED: "Доставлен",
  COMPLETED: "Завершён"
};
