import React, { Dispatch, useEffect, useState } from "react";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { PayloadAction } from "typesafe-actions";

import DatePickerButton from "../sidebars/order/date-picker-button.component";

import styles from "./filter.module.scss";
import { FiltersProps, FilterData } from "./filter.types";

import { fetchOrdersByDateAction } from "~/app/order/order.actions";

export const Filter: React.FC<FiltersProps> = ({
  filters,
  onFilterChange,
  activeFilters,
  className,
  isSingleSelect
}: FiltersProps) => {
  const getDefaultFilters: () => FilterData[] = (): FilterData[] => {
    return filters.filter(filter => filter.isDefault);
  };

  if (!activeFilters) {
    return "";
  }

  useEffect(() => {
    onDateChange();
  }, []);

  const [selectedFilters, setSelectedFilters]: [FilterData[], React.Dispatch<React.SetStateAction<FilterData[]>>] =
    useState<FilterData[]>([...activeFilters, ...getDefaultFilters()]);
  const [selectedDate, setSelectedDate]: [Date | null, React.Dispatch<React.SetStateAction<Date | null>>] =
    useState<Date | null>(new Date());

  const dispatch: Dispatch<PayloadAction<string, any>> = useDispatch();

  const onDateChange: (date?: Date) => void = (date = new Date()) => {
    const formattedSelectedDate: string = dayjs(date).format("MM.DD.YYYY");
    const updatedFilters: FilterData[] = selectedFilters.filter(filter => !filter.isDateFilter);
    const newDateFilter: FilterData = { label: "Дата", value: formattedSelectedDate, isDateFilter: true };
    const newFilters: FilterData[] = [...updatedFilters, newDateFilter];

    dispatch(fetchOrdersByDateAction(formattedSelectedDate));

    setSelectedDate(date);
    setSelectedFilters(newFilters);
    onFilterChange(newFilters);
  };

  const handleClick: (filter: FilterData) => void = filter => {
    if (!filter.isDateFilter) {
      let updatedFilters: FilterData[];
      const isSelected: boolean = selectedFilters.some(f => f.label === filter.label);

      if (isSingleSelect) {
        if (isSelected) {
          return;
        }
        updatedFilters = [filter];
      } else {
        if (isSelected) {
          updatedFilters = selectedFilters.filter(f => f.label !== filter.label);
        } else {
          updatedFilters = [...selectedFilters, filter];
        }
      }

      const dateFilters: FilterData[] = selectedFilters.filter(f => f.isDateFilter);
      updatedFilters = [...updatedFilters, ...dateFilters];

      setSelectedFilters(updatedFilters);
      onFilterChange(updatedFilters);
    }
  };

  return (
    <div className={classNames(styles.filterWrapper, className)}>
      {filters.map(filter =>
        filter.isDateFilter ? (
          <div key={filter.label} className={classNames(styles.filterItem)}>
            <DatePicker
              key={filter.label}
              dateFormat="dd.MM.yy"
              locale={ru}
              selected={selectedDate}
              onChange={(date: Date): void => onDateChange(date)}
              customInput={<DatePickerButton />}
            />
          </div>
        ) : (
          <div
            key={filter.label}
            className={classNames(styles.filterItem, {
              [styles.active]: selectedFilters.some(f => f.label === filter.label)
            })}
            onClick={(): void => handleClick(filter)}
          >
            <span>{filter.label}</span>
            <span>{filter.count}</span>
          </div>
        )
      )}
    </div>
  );
};

export default Filter;
