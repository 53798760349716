import { RoleBase, UserBase } from "@nagano_crm/shared/auth/user.interface";
import classNames from "classnames";
import React, { Dispatch, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserSidebar } from "../sidebar/user.sidebar";

import styles from "./user.module.scss";

import { ApplicationStore } from "~/app/application.store";
import userActions from "~/app/user/user.actions";
import SearchIcon from "~/view/assets/search-icon";

export const UserPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const users: UserBase[] = useSelector((state: ApplicationStore) => state.user.users);
  const roles: RoleBase[] = useSelector((state: ApplicationStore) => state.user.roles);
  const isSidebarOpen: boolean = useSelector((state: ApplicationStore) => state.user.isSidebarOpen);

  const changeCurrentUser: (userUid: string) => void = useCallback(
    (userUid: string) => {
      dispatch(userActions.changeCurrentUser(userUid));
    },
    [dispatch]
  );

  return (
    <div className={styles.userPageWrapper}>
      <div className={styles.titlePageSearchInput}>
        <input type="text" placeholder="Все сотрудники" disabled />
        <SearchIcon />
      </div>
      <div className={classNames(styles.userTableWrapper, styles.tableWrapper)}>
        <div className={styles.headerTable}>
          <div className={classNames(styles.cellTable, styles.name)}>Сотрудник</div>
          <div className={classNames(styles.cellTable, styles.role)}>Роль</div>
          <div className={classNames(styles.cellTable, styles.phone)}>Телефон</div>
          <div className={classNames(styles.cellTable, styles.login)}>Логин</div>
          <div className={classNames(styles.cellTable, styles.email)}>Эл. почта</div>
        </div>
        {users.map(user => (
          <div key={user.uid} className={styles.rowTable} onClick={(): void => changeCurrentUser(user.uid)}>
            <div className={classNames(styles.cellTable, styles.name)}>{user.name}</div>
            <div className={classNames(styles.cellTable, styles.role)}>
              {roles.find(role => role.uid === user.role_uid)?.name}
            </div>
            <div className={classNames(styles.cellTable, styles.phone)}>{user.phone}</div>
            <div className={classNames(styles.cellTable, styles.login)}>{user.login}</div>
            <div className={classNames(styles.cellTable, styles.email)}>{user.email}</div>
          </div>
        ))}
      </div>
      <div className={styles.btnOrderNew} onClick={(): void => dispatch(userActions.createNewUser())}>
        +
      </div>
      {isSidebarOpen && <UserSidebar />}
    </div>
  );
};
