import React from "react";

import styles from "./confirm-popup.module.scss";

import childStyles from "~/public/styles/child.module.scss";

interface ConfirmPopupProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = (props: ConfirmPopupProps) => {
  if (!props.isOpen) {
    return null;
  }

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <h2 className={childStyles.title_font_s_size}>{props.title}</h2>
        <p className={styles.description}>{props.description}</p>
        <div className={styles.popupActions}>
          <button className={styles.cancelButton} onClick={props.onCancel}>
            {props.cancelText}
          </button>
          <button className={styles.confirmButton} onClick={props.onConfirm}>
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
