import { OrderBase, OrderCreate, OrderList, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

export const handleConnectedOrderSocketAction: ActionCreatorWithoutPayload =
  createAction<void>("order/connectedSocket");

export const handleDisconnectedOrderSocket: ActionCreatorWithoutPayload =
  createAction<void>("order/disconnectedSocket");

export const handleGetListOrders: ActionCreatorWithPayload<OrderList> = createAction<OrderList>("orders/handleList");

export const handleOrderCreate: ActionCreatorWithPayload<OrderBase> = createAction<OrderBase>("orders/handleCreate");

export const handleOrderUpdate: ActionCreatorWithPayload<OrderUpdate> =
  createAction<OrderUpdate>("orders/handleUpdate");

export const setOrderList: ActionCreatorWithPayload<OrderList> = createAction<OrderList>("orders/setList");
export const setMethodPayments: ActionCreatorWithPayload<MethodPayment[]> =
  createAction<MethodPayment[]>("orders/setMethodPayments");
export const setRequestedOrdersDate: ActionCreatorWithPayload<string> =
  createAction<string>("orders/setRequestedDates");

//Actions
export const createOrderAction: ActionCreatorWithPayload<OrderCreate> = createAction<OrderCreate>("orders/create");

export const updateOrderAction: ActionCreatorWithPayload<OrderUpdate> = createAction<OrderUpdate>("orders/update");

export const changeCurrentOrderAction: ActionCreatorWithPayload<string | null> = createAction<string | null>(
  "orders/changeCurrent"
);

export const resetListOrdersActions: ActionCreatorWithoutPayload = createAction<void>("orders/reset");
export const getListOrdersActions: ActionCreatorWithoutPayload = createAction<void>("orders/getList");

export const createNewOrderAction: ActionCreatorWithoutPayload = createAction<void>("order/createNewOrder");

export const fetchOrdersByDateAction: ActionCreatorWithPayload<string> = createAction<string>("orders/fetchByDate");
