import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import { ApplicationStore, createApplicationStore } from "../app/application.store";

import { GlobalError } from "./error/global/global-error.container";
import { RoutesComponent } from "./routes.component";
import "~/public/styles/app.scss";
import "~/public/styles/form.scss";
export const App: React.FC = () => {
  const store: Store<ApplicationStore> = createApplicationStore();

  return (
    <Provider store={store}>
      <GlobalError>
        <RoutesComponent />
      </GlobalError>
    </Provider>
  );
};
