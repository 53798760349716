import { Effect, call } from "redux-saga/effects";

import { FetchError } from "../utils/api-fetch";

// import { navigate } from "./history";

type Fn<T> = (...params: any[]) => Promise<T>;

export function* apiCall<T>(fn: Fn<T>, ...args: any[]): Generator<Effect, T, T> {
  try {
    return yield call(fn, ...args);
  } catch (e) {
    if (e instanceof FetchError) {
      console.error(e.message);

      // if (e.status === 401) {
      //   // const currentUrl: URL = new URL(document.location.href);
      //   const loginUrl: URL = new URL(`${BASE_URL}`);
      //   // const redirect: string =
      //   //   loginUrl.origin === currentUrl.origin ? document.location.pathname : currentUrl.toString();
      //   // loginUrl.searchParams.append(LOGIN_REDIRECT_PARAM, redirect);
      //   yield call(navigate, loginUrl.toString());
      // } else if (e.status === 403 || e.status === 404) {
      //   yield put(setNetworkErrorAction({ status: e.status, statusText: e.message, details: e }));
      // }
    }
    throw e;
  }
}
