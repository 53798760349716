import { Action, EventChannel, Task } from "redux-saga";
import { all, call, cancel, cancelled, Effect, fork, put, select, take, takeEvery } from "redux-saga/effects";
import { Socket } from "socket.io-client";
import { PayloadAction } from "@reduxjs/toolkit";
import { OrderBase, OrderUpdate } from "@nagano_crm/shared/orders/order.types";

import { setLocalErrorAction } from "../error/error.actions";
import { FetchError } from "../utils/api-fetch";
import { ApplicationStore } from "../application.store";

import kitchenActions from "./kitchen.actions";
import { connectSocketKitchen, createSocketEventsKitchen, socketKitchen } from "./kitchen.sockets";
import { KitchenWorkplace } from "./kitchen.store";
import { fetchOrdersWorkplace, kitchenService, updateOrderService } from "./kitchen.service";

function* initKitchen(): Generator {
  try {
    const workplace: KitchenWorkplace = (yield select(
      (store: ApplicationStore) => store.kitchen.workplace
    )) as KitchenWorkplace;
    const workplaceOrders: OrderBase[] = (yield call(fetchOrdersWorkplace, workplace)) as OrderBase[];
    yield put(kitchenActions.setOrders(workplaceOrders));
  } catch (e) {
    console.error(e);
    yield put(
      setLocalErrorAction({
        message: e instanceof Error ? e.message : "Unknown error, while fetching user",
        details: e
      })
    );
  }
}

function* connectSocketKitchenSaga(): Generator<Effect, void, Task | Socket> {
  let task: Task | null = null;

  try {
    yield call(connectSocketKitchen);

    task = (yield fork(socketWatchers)) as Task;

    yield call(initKitchen);
  } finally {
    if (yield cancelled()) {
      socketKitchen.close();
      if (task !== null) {
        yield cancel(task);
      }
    }
  }
}

function* getSignInWorkplace(): Generator {
  try {
    const workplace: KitchenWorkplace = (yield call(kitchenService.me)) as KitchenWorkplace;

    yield put(kitchenActions.setWorkplace(workplace));
  } catch (e) {
    yield put(kitchenActions.setIsSignInWorkplace(false));
    if (!(e instanceof FetchError) || e.status !== 401) {
      console.error(e);
      yield put(
        setLocalErrorAction({
          message: e instanceof Error ? e.message : "Unknown error, while fetching user",
          details: e
        })
      );
    }
  }
}

function* signInWorkplace(data: KitchenWorkplace): Generator {
  try {
    const workplace: KitchenWorkplace = (yield call(kitchenService.auth, data)) as KitchenWorkplace;

    yield put(kitchenActions.setWorkplace(workplace));
  } catch (e) {
    yield put(kitchenActions.setIsSignInWorkplace(false));
    if (!(e instanceof FetchError) || e.status !== 401) {
      console.error(e);
      yield put(
        setLocalErrorAction({
          message: e instanceof Error ? e.message : "Unknown error, while fetching user",
          details: e
        })
      );
    }
  }
}

// export function* getOrdersSaga(date?: string): Generator {
//   const requestedDates: string[] = (yield select((store: ApplicationStore) => store.order.requestedDates)) as string[];
//   if (!date || !requestedDates.includes(date)) {
//     const orders: OrderList = (yield call(fetchOrderCore, date)) as OrderList;

//     yield put(setOrderList(orders));
//     yield put(setRequestedOrdersDate(date ?? dayjs(new Date()).format("YYYY-MM-DD")));
//   }
// }

export function* socketWatchers(): Generator<Effect | Generator, void> {
  const socketEventsKitchen: EventChannel<any> = (yield call(createSocketEventsKitchen)) as EventChannel<any>;

  while (true) {
    const action: Action<any> = (yield take(socketEventsKitchen)) as Action<any>;

    yield put(action);
  }
}

function* kitchenWatchers(): Generator<Effect | Generator, void> {
  yield all([
    takeEvery(kitchenActions.signInWorkplace, ({ payload }: PayloadAction<KitchenWorkplace>) =>
      signInWorkplace(payload)
    ),
    takeEvery(kitchenActions.runCheckIsSignInWorkplace, () => getSignInWorkplace()),
    takeEvery(kitchenActions.setWorkplace, ({ payload }: PayloadAction<KitchenWorkplace>) => {
      if (payload) {
        return connectSocketKitchenSaga();
      }
    }),
    takeEvery(kitchenActions.updateOrder, ({ payload }: PayloadAction<OrderUpdate>) => updateOrderService(payload))
  ]);
}

export function* kitchenSaga(): Generator<Effect | Generator, void> {
  yield all([fork(kitchenWatchers)]);
}
