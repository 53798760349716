import { OrderBase, OrderList, OrderUpdate } from "@nagano_crm/shared/orders/order.types";
import { PayloadAction, createReducer, combineReducers, Reducer } from "@reduxjs/toolkit";
import { OrderDeliveryBase } from "@nagano_crm/shared/orders/order-delivery.types";
import _ from "lodash";

import { mergeAndSortByDeliveryAt } from "../utils/helpers";

import kitchenActions from "./kitchen.actions";

export interface KitchenWorkplace {
  uid?: string;
  name?: string;
  login: string;
}

export interface KitchenStore {
  list: OrderList;
  ordersInWork: string[];
  readyNomenclatures: { [orderUid: string]: string[] };
  workplace: KitchenWorkplace | null;
  isSignInWorkpalce: boolean | null;
}

export const kitchenReducer: Reducer<KitchenStore> = combineReducers({
  list: createReducer<OrderList>([], builder => {
    builder
      .addCase(kitchenActions.setOrders, (state, { payload }: PayloadAction<OrderList>) =>
        mergeAndSortByDeliveryAt([...state, ...payload])
      )
      .addCase(kitchenActions.handleCreateOrder, (state, { payload }: PayloadAction<OrderBase>) =>
        mergeAndSortByDeliveryAt([...state, payload])
      )
      .addCase(kitchenActions.handleUpdateOrder, (state, { payload }: PayloadAction<OrderUpdate>) =>
        mergeAndSortByDeliveryAt(
          state.map(order => {
            return order.uid === payload.uid
              ? {
                  ...order,
                  ...payload,
                  delivery:
                    !_.isEmpty({ ...order.delivery, ...payload.delivery }) && payload.deliveryType !== "pickup"
                      ? ({ ...order.delivery, ...payload.delivery } as OrderDeliveryBase | null)
                      : null
                }
              : order;
          })
        )
      );
  }),
  ordersInWork: createReducer<string[]>([], builder => {
    builder.addCase(kitchenActions.setOrderInWorkAction, (state, { payload }: PayloadAction<string>) => {
      if (state.indexOf(payload) === -1) {
        return [...state, payload];
      }
      return state;
    });
  }),
  readyNomenclatures: createReducer<{ [orderUid: string]: string[] }>({}, builder => {
    builder.addCase(
      kitchenActions.setReadyNomenclatures,
      (state, { payload }: PayloadAction<{ orderUid: string; nomenclatureUid: string }>) => {
        const { orderUid, nomenclatureUid }: { orderUid: string; nomenclatureUid: string } = payload;
        const existingNomenclatures: string[] = state[orderUid] || [];

        if (existingNomenclatures.includes(nomenclatureUid)) {
          state[orderUid] = existingNomenclatures.filter(uid => uid !== nomenclatureUid);
        } else {
          state[orderUid] = [...existingNomenclatures, nomenclatureUid];
        }
      }
    );
  }),
  workplace: createReducer<KitchenWorkplace | null>(null, builder => {
    builder.addCase(kitchenActions.setWorkplace, (_, { payload }: PayloadAction<KitchenWorkplace>) => payload);
  }),
  isSignInWorkpalce: createReducer<boolean | null>(null, builder => {
    builder
      .addCase(kitchenActions.setWorkplace, () => true)
      .addCase(kitchenActions.setIsSignInWorkplace, (_, { payload }: PayloadAction<boolean>) => payload);
  })
});
