import { CustomerBase, CustomerCreate, CustomerUpdate } from "@nagano_crm/shared/customer/customers.types";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { OrderList } from "@nagano_crm/shared/orders/order.types";

import customerActions from "../../../../app/customer/customer.actions";
import { selectCustomerByCurrentUid } from "../../../../app/customer/customer.selectors";
import { SidebarCoreComponent } from "../sidebar-core/sidebar-core.component";
import styles from "../sidebar.module.scss";
import useValidation, { UseValidationResult } from "../../input-validation/useValidation";
import PhoneInput from "../../phone-input/phone-input.component";

import { OrderItemComponent } from "./order-item/order-item";

import childStyles from "~/public/styles/child.module.scss";
import { ApplicationStore } from "~/app/application.store";

export const CustomerSidebar: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const customer: CustomerBase | CustomerCreate | null = useSelector(selectCustomerByCurrentUid);

  if (!customer) {
    return <></>;
  }

  const [customerChangeForm, setCustomerChangeForm]: [
    CustomerUpdate | CustomerCreate,
    React.Dispatch<React.SetStateAction<CustomerUpdate | CustomerCreate>>
  ] = useState<CustomerUpdate | CustomerCreate>({} as CustomerUpdate | CustomerCreate);

  const [customerForm, setCustomerForm]: [
    CustomerUpdate | CustomerCreate,
    React.Dispatch<React.SetStateAction<CustomerUpdate | CustomerCreate>>
  ] = useState<CustomerUpdate | CustomerCreate>({ ...customer, ...customerChangeForm });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  useEffect(() => {
    setCustomerForm({ ...customer, ...customerChangeForm });
  }, [customerChangeForm, customer]);

  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = [
    { name: "name", value: customerForm?.name, error: "Введите имя" },
    {
      name: "phone",
      value: customerForm?.phone,
      error: "Введите номер телефона"
    }
  ];

  const ordersByCustomer: OrderList = useSelector((state: ApplicationStore) => state.customer.ordersCustomer);

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    customerForm
  );

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(customerActions.changeCurrentCustomer(null)),
    [dispatch]
  );

  const handleSaveCustomer: (customer: CustomerCreate | CustomerUpdate) => void = useCallback(
    (customer: CustomerCreate | CustomerUpdate) => {
      setShowErrors(true);
      if (validationErrors.length) {
        return;
      }
      if ((customer as CustomerUpdate).uid) {
        dispatch(customerActions.updateCustomer(customer as CustomerUpdate));
      } else {
        dispatch(customerActions.createCustomer(customer as CustomerCreate));
      }
    },
    [dispatch, validationErrors]
  );

  const handleChange: (target: { name: string; value: string }) => void = (target: { name: string; value: string }) => {
    setIsFormChanged(true);
    setCustomerChangeForm(prevState => {
      return {
        ...prevState,
        [target.name]: target.value
      } as CustomerUpdate | CustomerCreate;
    });
  };

  const handleDeleteCustomer: (customerUid: string) => void = useCallback(
    (customerUid: string) => {
      dispatch(customerActions.deleteCustomer(customerUid));
    },
    [dispatch]
  );

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveCustomer(customerForm)}
      actionOnClickClose={handleCloseSidebar}
      actionOnClickDelete={
        (customerForm as CustomerUpdate)?.uid
          ? (): void => handleDeleteCustomer((customerForm as CustomerUpdate).uid)
          : undefined
      }
    >
      <div className={styles.sidebarWrapper}>
        <header>
          <input
            type="text"
            name="name"
            placeholder="ФИО"
            value={customerForm.name ?? ""}
            onChange={(e): void => handleChange(e.target)}
          />
          {getErrorMessage("name") && <span>{getErrorMessage("name")}</span>}
        </header>
        <div className={styles.mainWrapper}>
          <div className={styles.inputWrappers}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Телефон</div>
              <div className={styles.inputBox}>
                <PhoneInput
                  type="text"
                  name="phone"
                  placeholder="Введите телефон"
                  value={customerForm.phone ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e.target)}
                  disableSearch={true}
                />
                {getErrorMessage("phone") && <span>{getErrorMessage("phone")}</span>}
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Эл. почта</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="email"
                  placeholder="Введите почту"
                  value={customerForm.email ?? ""}
                  onChange={(e): void => handleChange(e.target)}
                />
              </div>
            </div>
          </div>
          {(customer as CustomerBase).uid && ordersByCustomer.length > 0 && (
            <div className={classNames(styles.orderTableWrapper)}>
              <div className={classNames(childStyles.title_font_xs_size)}>Заказы</div>
              <div
                className={classNames(
                  styles.customerOrdersHeaderTable,
                  styles.headerTable,
                  childStyles.title_font_xxs_size
                )}
              >
                <div className={styles.date}>Дата</div>
                <div className={styles.nomenclatures}>Состав</div>
                <div className={styles.total}>Сумма</div>
              </div>
              {[...ordersByCustomer].reverse().map(order => {
                return <OrderItemComponent order={order} key={order.uid} />;
              })}
            </div>
          )}
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
