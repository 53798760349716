import React, { RefObject, useEffect, useRef, useState } from "react";

import styles from "./confirm-popup.module.scss";

import childStyles from "~/public/styles/child.module.scss";

interface ConfirmPopupProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = (props: ConfirmPopupProps) => {
  const [isOpen, setIsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(props.isOpen);

  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
      props.onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent} ref={ref}>
        <h2 className={childStyles.title_font_s_size}>{props.title}</h2>
        {props.description && <p className={styles.description}>{props.description}</p>}
        <div className={styles.popupActions}>
          <button className={styles.cancelButton} onClick={props.onCancel}>
            {props.cancelText}
          </button>
          <button className={styles.confirmButton} onClick={props.onConfirm}>
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
