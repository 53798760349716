import { eventChannel } from "redux-saga";
import { Socket } from "socket.io-client";
import { OrderBase, OrderList, OrderUpdate } from "@nagano_crm/shared/orders/order.types";

import { setSocketErrorAction } from "../error/error.actions";
import { SocketError } from "../error/error.store";
import { manager } from "../socket.utils";

import {
  handleOrderCreate,
  handleGetListOrders,
  handleConnectedOrderSocketAction,
  handleOrderUpdate
} from "./order.actions";

export const createSocketEventsOrders: any = (): any =>
  eventChannel(emit => {
    socketOrders.on("connect", () => {
      emit(handleConnectedOrderSocketAction());
    });

    socketOrders.on("lists", (data: OrderList) => {
      emit(handleGetListOrders(data));
    });

    socketOrders.on("create", (data: OrderBase) => {
      emit(handleOrderCreate(data));
    });

    socketOrders.on("update", (data: OrderUpdate) => {
      emit(handleOrderUpdate(data));
    });

    socketOrders.on("exception", (data: SocketError) => {
      emit(setSocketErrorAction(data));
    });

    return (): void => {
      socketOrders.off("connect");
      socketOrders.off("lists");
      socketOrders.off("create");
      socketOrders.off("handleUpdate");
      socketOrders.off("exception");
    };
  });

export let socketOrders: Socket;

export function connectSocketOrders(): Socket {
  if (!socketOrders) {
    socketOrders = manager.socket(`/orders`);
  }

  return socketOrders;
}
