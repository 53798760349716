import {
  NomenclatureBase,
  NomenclatureCreate,
  NomenclatureUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import {
  NomenclatureCategoryCreate,
  NomenclatureCategory,
  NomenclatureCategoryUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";

import { socketNomenclature } from "./nomenclatures.sockets";

export const fetchNomenclatureCore: () => Promise<NomenclatureBase[]> = async () => {
  return (await socketNomenclature.emitWithAck("getList")) as NomenclatureBase[];
};

export const fetchNomenclatureCategoriesCore: () => Promise<NomenclatureCategory[]> = async () => {
  return (await socketNomenclature.emitWithAck("getListNomenclatureCategories")) as NomenclatureCategory[];
};

//Nomenclature
export const createNomenclatureService: (data: NomenclatureCreate) => void = (data: NomenclatureCreate) =>
  socketNomenclature.emit("createOne", data);

export const updateNomenclatureService: (data: NomenclatureUpdate) => void = (data: NomenclatureUpdate) =>
  socketNomenclature.emit("updateOne", data);

export const deleteNomenclatureService: (data: string) => void = (data: string) =>
  socketNomenclature.emit("deleteOne", data);

//Nomenclature Category
export const createNomenclatureCategoryService: (data: NomenclatureCategoryCreate) => void = (
  data: NomenclatureCategoryCreate
) => socketNomenclature.emit("createOneCategory", data);

export const updateNomenclatureCategoryService: (data: NomenclatureCategoryUpdate) => void = (
  data: NomenclatureCategoryUpdate
) => socketNomenclature.emit("updateOneCategory", data);

export const deleteNomenclatureCategoryService: (data: string) => void = (data: string) =>
  socketNomenclature.emit("deleteOneCategory", data);
