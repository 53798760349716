import React, { Dispatch, memo, useCallback, useMemo } from "react";
import { ORDER_STATUSES, OrderBase } from "@nagano_crm/shared/orders/order.types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { MethodPayment } from "@nagano_crm/shared/orders/method-payment.types";

import ordersTableStyles from "../orders-table.module.scss";
import typography from "../../../../../public/styles/typography.module.scss";

import { OrderItemProps } from "./order-item.types";
import styles from "./order-item.module.scss";

import { ApplicationStore } from "~/app/application.store";
import { makeSelectOrderByOrderUid } from "~/app/order/order.selector";
import { changeCurrentOrderAction, updateOrderAction } from "~/app/order/order.actions";
import { ORDER_STATUS_INFO, TRANSLATE_DELIVERY_TYPE } from "~/typings/constants";
import { formatPhoneNumber } from "~/app/utils/helpers";

export const OrderItemComponent: React.FC<OrderItemProps> = memo((props: OrderItemProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const selectOrderByOrderUid: (state: ApplicationStore, orderUid: string) => OrderBase | undefined = useMemo(
    makeSelectOrderByOrderUid,
    []
  );

  const orderItem: OrderBase | undefined = useSelector((state: ApplicationStore) =>
    selectOrderByOrderUid(state, props.uid)
  );

  const methodPayments: MethodPayment[] = useSelector((state: ApplicationStore) => state.order.methodPayments);

  const handleChangeCurrentOrder: (currentOrderUid: string) => void = useCallback(
    (currentOrderUid: string) => {
      dispatch(changeCurrentOrderAction(currentOrderUid));
    },
    [dispatch]
  );

  if (!orderItem) {
    return;
  }

  let orderDeliveryString: string = `${orderItem.delivery?.city},  ${orderItem.delivery?.street}`;
  orderDeliveryString += `${orderItem.delivery?.entrance ? ", п. " + orderItem.delivery?.entrance : ""}${
    orderItem.delivery?.floor ? ", этаж " + orderItem.delivery?.floor : ""
  }${orderItem.delivery?.flat ? ", кв." + orderItem.delivery?.flat : ""}`;

  const dateDeliveryAt: string = moment(orderItem.deliveryAt).format("HH:mm");

  const total: string = new Intl.NumberFormat("ru-RU").format(orderItem.total);

  const currentStatusLabel: string = ORDER_STATUS_INFO[orderItem.status].label;
  const nextStatusLabel: ORDER_STATUSES | null = ORDER_STATUS_INFO[orderItem.status].nextStatus;

  const handleUpdateStatus: (event: React.MouseEvent) => void = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (nextStatusLabel) {
        dispatch(updateOrderAction({ uid: orderItem.uid, status: nextStatusLabel }));
      }
    },
    [nextStatusLabel]
  );

  return (
    <div
      className={classNames(styles.orderItemWrapper, typography.fs_20)}
      onClick={(): void => handleChangeCurrentOrder(orderItem.uid)}
    >
      <div className={ordersTableStyles.order}>
        <div>{dateDeliveryAt}</div>
        <div className={typography.fs_15}>{currentStatusLabel}</div>
        <div className={typography.fs_15}>{orderItem.orderNumber}</div>
      </div>
      <div>
        <div className={ordersTableStyles.price}>{total}&nbsp;₽</div>
        <div className={classNames(ordersTableStyles.pay, typography.text_font_s_size)}>
          {
            methodPayments.find((methodPayment: MethodPayment) => methodPayment.uid === orderItem.methodPaymentUid)
              ?.paymentType
          }
          {methodPayments.find(methodPayment => methodPayment.uid === orderItem.methodPaymentUid)?.isPaymentChange &&
            orderItem.paymentChange && (
              <div className={classNames(typography.fs_15)}>с {orderItem.paymentChange} ₽</div>
            )}
        </div>
      </div>
      <div className={ordersTableStyles.customer}>
        <div>{formatPhoneNumber(orderItem.customer?.phone)}</div>
        <div className={typography.fs_15}>{orderItem.customer?.name}</div>
      </div>
      <div className={classNames(ordersTableStyles.delivery)}>
        {orderItem.delivery ? (
          <div className={typography.fs_15}>{orderDeliveryString}</div>
        ) : (
          TRANSLATE_DELIVERY_TYPE[orderItem.deliveryType ?? ""]
        )}
      </div>
      <div className={ordersTableStyles.courier}>{orderItem.delivery?.courier?.name}</div>
      <div className={ordersTableStyles.comment}>
        <div className={typography.fs_15}>
          <span style={{ color: "#FF5C00" }}>{orderItem.comment}</span>
        </div>
      </div>

      {nextStatusLabel && (
        <div
          className={ordersTableStyles.buttonNextStatus}
          onClick={handleUpdateStatus}
          // onClick={(): void => handleUpdateStatus(nextStatusLabel)}
          // className={classNames(styles.btn, styles.primary)}
        >
          {ORDER_STATUS_INFO[nextStatusLabel].label}
        </div>
      )}
    </div>
  );
});

OrderItemComponent.displayName = "OrderItemComponent";
