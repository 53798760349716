import "reflect-metadata";

import React from "react";
import { createRoot, Root } from "react-dom/client";

import { App } from "./view/app.component";

// if (module.hot) {
//   module.hot.accept();
// }
const root: Root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);
