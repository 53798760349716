/* eslint-disable @typescript-eslint/no-base-to-string */
import { ComponentType } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { ApplicationStore } from "~/app/application.store";
import { resetErrorAction } from "~/app/error/error.actions";
import { ErrorStore, LocalError, NetworkError } from "~/app/error/error.store";
import { GlobalErrorComponent } from "~/view/error/global/global-error.component";
import {
  GlobalErrorDispatchProps,
  GlobalErrorProps,
  GlobalErrorStateProps
} from "~/view/error/global/global-error.types";

function stringifyDetails(details: unknown): string | null {
  if (details === null || details === undefined) {
    return null;
  }
  if (typeof details === "string") {
    return details;
  }
  if (typeof details === "boolean") {
    return details ? "true" : "false";
  }
  if (typeof details === "number" || typeof details === "bigint") {
    return `${details}`;
  }
  if (typeof details === "object") {
    if (details instanceof Error) {
      return `${details.name}: ${details.message}
        ${details.stack ?? ""}
      `;
    }
    return details?.toString() ?? null;
  }
  return "";
}

function mapStateToProps(state: ApplicationStore): GlobalErrorStateProps {
  const { localError, networkError }: ErrorStore = state.error;
  if (localError !== null) {
    const { message, details }: LocalError = localError;
    return {
      error: {
        title: "Извините, что-то пошло не так",
        subtitle: "Произошла ошибка при работе приложения",
        techMessage: message,
        techDetails: stringifyDetails(details)
      }
    };
  }
  if (networkError !== null) {
    const { status, statusText, details }: NetworkError = networkError;
    return {
      error: {
        title: "Извините, что-то пошло не такc",
        subtitle: "Произошла ошибка при работе приложения",
        techMessage: `${status}: ${statusText}`,
        techDetails: stringifyDetails(details)
      }
    };
  }
  return {
    error: null
  };
}

function mapDispatchToProps(dispatch: Dispatch): GlobalErrorDispatchProps {
  return {
    resetError(): void {
      dispatch(resetErrorAction());
    }
  };
}

export const GlobalError: ComponentType<GlobalErrorProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalErrorComponent);
