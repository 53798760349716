import { PayloadAction, createReducer, combineReducers, Reducer, isAnyOf } from "@reduxjs/toolkit";
import { SellingPointBase } from "@nagano_crm/shared/settings/settings.types";

import settingsActions from "../settings/settings.actions";

export interface SettingsStore {
  isSettingsSidebarOpen: boolean;
  isSellingPointsSidebarOpen: boolean;
  isSellingPointSidebarOpen: boolean;
  sellingPointsList: Record<string, SellingPointBase>;
  currentSellingPointUid: string | null;
}

export const settingsReducer: Reducer<SettingsStore> = combineReducers({
  sellingPointsList: createReducer<Record<string, SellingPointBase>>({}, builder =>
    builder
      .addCase(settingsActions.resetListSellingPoint, () => {})
      .addCase(settingsActions.setSellingPointList, (_, { payload }: PayloadAction<SellingPointBase[]>) =>
        payload.reduce(
          (acc, item) => {
            acc[item.uid] = item;
            return acc;
          },
          {} as Record<string, SellingPointBase>
        )
      )
      .addCase(settingsActions.createSellingPoint, (state, { payload }: PayloadAction<SellingPointBase>) => ({
        ...state,
        [payload.uid]: payload
      }))
      .addCase(settingsActions.updateSellingPoint, (state, { payload }: PayloadAction<SellingPointBase>) => ({
        ...state,
        [payload.uid]: { ...state[payload.uid], ...payload }
      }))
  ),
  currentSellingPointUid: createReducer<string | null>(null, builder =>
    builder
      .addCase(settingsActions.changeCurrentSellingPoint, (_, { payload }: PayloadAction<string | null>) => payload)
      .addCase(settingsActions.handleClickNewSellingPoint, () => "")
  ),
  isSettingsSidebarOpen: createReducer<boolean>(false, builder =>
    builder
      .addCase(settingsActions.handleClickOpenSettingsSidebar, () => true)
      .addCase(settingsActions.handleClickCloseSettingsSidebar, () => false)
  ),
  isSellingPointsSidebarOpen: createReducer<boolean>(false, builder =>
    builder
      .addCase(settingsActions.handleClickNewSellingPoint, () => true)
      .addCase(settingsActions.handleClickOpenSellingPointsSidebar, () => true)
      .addCase(settingsActions.handleClickCloseSellingPointsSidebar, () => false)
  ),
  isSellingPointSidebarOpen: createReducer<boolean>(false, builder =>
    builder
      .addCase(settingsActions.handleClickNewSellingPoint, () => true)
      .addMatcher(
        isAnyOf(
          settingsActions.createSellingPoint,
          settingsActions.updateSellingPoint,
          settingsActions.deleteSellingPoint
        ),
        () => false
      )
  )
});
