import { NomenclatureCategoryBase } from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";
import {
  NomenclatureBase,
  NomenclatureCreate,
  NomenclatureUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import _ from "lodash";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DropDownList } from "../../dropdown-list/dropdown-list.component";
import { SidebarCoreComponent } from "../sidebar-core/sidebar-core.component";
import styles from "../sidebar.module.scss";
import useValidation, { UseValidationResult } from "../../input-validation/useValidation";
import useIsFormsFieldsEmpty from "../../check-fields/useIsFormsFieldsEmpty";

import { ApplicationStore } from "~/app/application.store";
import { selectNomenclatureByCurrentUid } from "~/app/nomenclatures/nomenclature.selectors";
import nomenclaturesActions from "~/app/nomenclatures/nomenclatures.actions";

export const NomenclatureSidebar: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const nomenclaturesCategoriesList: Record<string, NomenclatureCategoryBase> = useSelector(
    (state: ApplicationStore) => state.nomenclature.listCategories
  );
  const nomenclature: NomenclatureBase | NomenclatureCreate | null = useSelector(selectNomenclatureByCurrentUid);

  const [nomenclatureChangeForm, setNomenclatureChangeForm]: [
    NomenclatureUpdate | NomenclatureCreate | null,
    React.Dispatch<React.SetStateAction<NomenclatureUpdate | NomenclatureCreate | null>>
  ] = useState<NomenclatureUpdate | NomenclatureCreate | null>(null);

  const [nomenclatureForm, setNomenclatureForm]: [
    NomenclatureUpdate | NomenclatureCreate | null,
    React.Dispatch<React.SetStateAction<NomenclatureUpdate | NomenclatureCreate | null>>
  ] = useState<NomenclatureUpdate | NomenclatureCreate | null>(() => {
    if (nomenclature) {
      return {
        ...nomenclature,
        ...(nomenclatureChangeForm ?? {})
      } as NomenclatureUpdate | NomenclatureCreate;
    }
    return null;
  });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = [
    { name: "name", value: nomenclatureForm?.name, error: "Введите название" }
  ];

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    nomenclatureForm
  );

  useEffect(() => {
    setNomenclatureForm(nomenclature ? { ...nomenclature, ...(nomenclatureChangeForm ?? {}) } : null);
  }, [nomenclature, nomenclatureChangeForm]);

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(nomenclaturesActions.changeCurrentNomenclature(null)),
    [dispatch]
  );

  const handleSaveNomenclature: (nomenclature: NomenclatureCreate | NomenclatureUpdate) => void = useCallback(
    (nomenclature: NomenclatureCreate | NomenclatureUpdate) => {
      setShowErrors(true);
      if (validationErrors.length) {
        return;
      }
      if ((nomenclature as NomenclatureUpdate).uid) {
        dispatch(nomenclaturesActions.updateNomenclature(nomenclature as NomenclatureUpdate));
      } else {
        dispatch(nomenclaturesActions.createNomenclature(nomenclature as NomenclatureCreate));
      }
    },
    [dispatch, validationErrors]
  );

  if (_.isEmpty(nomenclatureForm)) {
    return <></>;
  }

  const handleNomenclatureChange: (target: { name: string; value: string | null }) => void = target => {
    setNomenclatureForm(prevNomenclature => {
      switch (target.name) {
        case "price": {
          const priceValue: string = (target.value ?? "0").replace(/[^0-9.,]/g, "");
          return { ...prevNomenclature, price: priceValue } as NomenclatureUpdate | NomenclatureCreate;
        }
        case "balance": {
          const balanceValue: number = Number(target.value);
          if (!isNaN(balanceValue)) {
            return { ...prevNomenclature, balance: balanceValue } as NomenclatureUpdate | NomenclatureCreate;
          }
          return prevNomenclature;
        }
        default:
          return { ...prevNomenclature, [target.name]: target.value ? target.value : null } as
            | NomenclatureUpdate
            | NomenclatureCreate;
      }
    });
  };

  const handleNomenclatureDelete: (nomenclature: NomenclatureCreate | NomenclatureUpdate) => void = useCallback(
    (nomenclature: NomenclatureCreate | NomenclatureUpdate) => {
      dispatch(nomenclaturesActions.deleteNomenclature((nomenclature as NomenclatureBase).uid));
    },
    [dispatch]
  );

  useEffect(() => {
    setIsFormChanged(!useIsFormsFieldsEmpty(nomenclatureChangeForm));
  }, [nomenclatureChangeForm]);

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveNomenclature(nomenclatureForm)}
      actionOnClickClose={(): void => handleCloseSidebar()}
      actionOnClickDelete={
        (nomenclature as NomenclatureUpdate)?.uid ? (): void => handleNomenclatureDelete(nomenclatureForm) : undefined
      }
    >
      <div className={styles.sidebarWrapper}>
        <header>
          <input
            type="text"
            name="name"
            placeholder="Название блюда"
            value={nomenclatureForm?.name ?? ""}
            onChange={(e): void => handleNomenclatureChange(e.target)}
          />
          {getErrorMessage("name") && <span>{getErrorMessage("name")}</span>}
        </header>
        <div className={styles.mainWrapper}>
          <div className={styles.inputWrappers}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Цена, ₽</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="price"
                  placeholder="Цена"
                  value={nomenclatureForm?.price ?? ""}
                  onChange={(e): void => handleNomenclatureChange(e.target)}
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Остаток</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="balance"
                  placeholder="Остаток"
                  value={nomenclatureForm?.balance ?? ""}
                  onChange={(e): void => handleNomenclatureChange(e.target)}
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Описание</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="description"
                  placeholder="Расскажите о блюде"
                  value={nomenclatureForm?.description ?? ""}
                  onChange={(e): void => handleNomenclatureChange(e.target)}
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Категория</div>
              <div className={styles.inputBox}>
                <DropDownList
                  displayValue={
                    nomenclaturesCategoriesList[nomenclatureForm?.categoryUid ?? ""]?.name ?? "Без категории"
                  }
                  data={[
                    { name: "Без категории", value: "" },
                    ...Object.entries(nomenclaturesCategoriesList).map(
                      ([key, value]: [string, { name: string }]): { name: string; value: string } => ({
                        name: value?.name ?? "",
                        value: key
                      })
                    )
                  ]}
                  value={nomenclatureForm?.categoryUid ?? ""}
                  name="categoryUid"
                  handleChange={(data): void => handleNomenclatureChange(data)}
                  color="default"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
