import { useMask } from "@react-input/mask";
import classNames from "classnames";
import React, { Dispatch, InputHTMLAttributes, RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerBase } from "@nagano_crm/shared/customer/customers.types";

import styles from "./phone-input.module.scss";

import styleTypography from "~/public/styles/typography.module.scss";
import { formatPhoneNumber } from "~/app/utils/helpers";
import { ApplicationStore } from "~/app/application.store";
import customerActions from "~/app/customer/customer.actions";

interface PhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string | undefined;

  handleChangeValue?: (value: { name: string; value: string | null }) => void;
  disableSearch?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = props => {
  const dispatch: Dispatch<any> = useDispatch();
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);
  const [isFocused, setIsFocused]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);
  const customerByPhone: CustomerBase[] = useSelector((state: ApplicationStore) => state.customer.resultSearchByPhone);

  const handleChangePhone: (value: string) => void = value => {
    const query: string = value.replace(/\D/g, "");
    if (query.length > 4 && !props.disableSearch) {
      dispatch(customerActions.searchCustomerByPhone(query));
    } else {
      setIsOpen(false);
    }
  };

  const handleSelectValue: () => void = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (customerByPhone.length && !props.disableSearch && isFocused) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [customerByPhone]);

  const inputRef: React.MutableRefObject<HTMLInputElement | null> = useMask({
    mask: "+7 (___) ___-__-__",
    replacement: { _: /\d/ }
  });

  const handleClickOutside: (event: MouseEvent) => void = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node) && inputRef.current !== event.target) {
      setIsOpen(false);
    }
  };

  const handleFocus: () => void = (): void => {
    setIsFocused(true);
    if (!customerByPhone.length) {
      return;
    }
    setIsOpen(true);
  };

  const handleBlur: () => void = (): void => {
    setIsFocused(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <input
        name="phone"
        placeholder="Телефон"
        value={formatPhoneNumber(props.value)}
        onChange={(e): void => {
          if (props.onChange) {
            props.onChange(e);
          }
          handleChangePhone(e.target.value);
        }}
        disabled={props.disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete={"off"}
        ref={inputRef}
      />
      {isOpen && (
        <div className={classNames(styles.phoneSelectionWrapper)} ref={ref}>
          {customerByPhone.map((value, index) => (
            <div
              className={styles.selectionFieldWrapper}
              onClick={(): void => {
                if (props.handleChangeValue) {
                  props.handleChangeValue({ name: value.name, value: value.uid });
                }
                handleSelectValue();
              }}
              key={index}
            >
              <span className={classNames(styles.selectionField, styleTypography.text_font_m_size, styles.phone)}>
                {formatPhoneNumber(value.phone)}
              </span>
              <span className={classNames(styles.selectionField, styleTypography.text_font_s_size, styles.name)}>
                {value.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PhoneInput;
