import { CustomerBase, CustomerCreate, CustomerUpdate } from "@nagano_crm/shared/customer/customers.types";

import { socketCustomer } from "./customer.sockets";

export const fetchCustomerCore: () => Promise<CustomerBase[]> = async () => {
  return (await socketCustomer.emitWithAck("getList")) as CustomerBase[];
};

export const createCustomerService: (data: CustomerCreate) => void = (data: CustomerCreate) => {
  socketCustomer.emit("createOne", data);
};

export const updateCustomerService: (data: CustomerUpdate) => void = (data: CustomerUpdate) => {
  socketCustomer.emit("updateOne", data);
};

export const deleteCustomerService: (data: string) => void = (data: string) => {
  socketCustomer.emit("deleteOne", data);
};

export const searchCustomerByPhone: (phone: string) => Promise<CustomerBase[]> = async (phone: string) => {
  return (await socketCustomer.emitWithAck("searchByPhone", phone)) as CustomerBase[];
};
