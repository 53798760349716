import { UsersAuthData, UserData } from "@nagano_crm/shared/auth/user.interface";
import { createAction, EmptyAction, PayloadAction } from "typesafe-actions";

export const handleRunCheckIsAuthAction: () => EmptyAction<"auth/checkIsAuthenticated"> =
  createAction("auth/checkIsAuthenticated")();

export const setIsAuth: (data: boolean) => PayloadAction<"auth/setIsAuth", boolean> =
  createAction("auth/setIsAuth")<boolean>();

export const setUserAuthDataAction: (user: UsersAuthData) => PayloadAction<"auth/setUser", UsersAuthData> =
  createAction("auth/setUser")<UsersAuthData>();

export const authenticate: () => EmptyAction<"auth/run"> = createAction("auth/run")();

export const getUser: () => EmptyAction<"auth/getUser"> = createAction("auth/getUser")();

export const setUserAction: (error: UserData) => PayloadAction<"auth/set-user", UserData> =
  createAction("auth/set-user")<UserData>();

export const resetUserAction: () => EmptyAction<"auth/reset-user"> = createAction("auth/reset-user")();
