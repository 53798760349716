import { UsersAuthData, UserData } from "@nagano_crm/shared/auth/user.interface";
import { Action, combineReducers, Reducer } from "redux";
import { createReducer, EmptyAction, PayloadAction } from "typesafe-actions";

import { resetUserAction, setIsAuth, setUserAction, setUserAuthDataAction } from "~/app/auth/auth.actions";

export interface AuthStore {
  userAuthData: UsersAuthData;
  isAuth: boolean | null;
  user: UserData | null;
}

export const authReducer: Reducer<AuthStore> = combineReducers({
  userAuthData: createReducer<UsersAuthData, Action>({ login: "", password: "" }).handleAction(
    setUserAuthDataAction,
    (_: UsersAuthData, { payload }: PayloadAction<string, UsersAuthData>) => payload
  ),
  isAuth: createReducer<boolean | null, Action>(null).handleAction(
    setIsAuth,
    (_: boolean | null, { payload }: PayloadAction<string, boolean>) => payload
  ),
  user: createReducer<UserData | null, Action>(null)
    .handleAction(setUserAction, (_: UserData | null, { payload }: PayloadAction<string, UserData>) => payload)
    .handleAction(resetUserAction, (_: UserData | null, __: EmptyAction<string>) => null)
});
