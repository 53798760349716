import { Selector, createSelector } from "reselect";
import { ORDER_STATUSES, OrderBase, OrderCreate, OrderList } from "@nagano_crm/shared/orders/order.types";
import dayjs from "dayjs";
import { UserBase } from "@nagano_crm/shared/auth/user.interface";
import { useSelector } from "react-redux";
import moment from "moment";
import { OrderNomenclatureBase } from "@nagano_crm/shared/orders/order-nomenclatures.types";

import { ApplicationStore } from "../application.store";
import { selectCouriersUser } from "../user/user.selector";
import { sortByStatus } from "../utils/helpers";

import { FilterData } from "~/view/components/filter/filter.types";

export const defaultOrder: Partial<OrderCreate> = {
  status: ORDER_STATUSES.NEW,
  deliveryType: "pickup",
  deliveryAt: moment().add(1, "hour").toDate(),
  methodPaymentUid: "a7585ceb-e926-477a-b733-20329b2bd208",
  paymentChange: null,
  customerUid: null,
  numberPersons: 1
};

// export class EmptyOrderData {
//   static createEmptyOrderData(): OrderCreate {
//     return {
//       customerUid: null,
//       comment: null,
//       total: 0,
//       status: ORDER_STATUSES.NEW,
//       deliveryAt: moment().add(1, "hour").toDate(),
//       deliveryType: "pickup",
//       customer: EmptyCustomerData.createEmptyCustomerData(),
//       nomenclatures: [],
//       delivery: null
//     };
//   }
// }

const filterOrders: (orders: OrderBase[], activeFilters: FilterData[], courierId: string | null) => OrderBase[] = (
  orders: OrderBase[],
  activeFilters: FilterData[],
  courierId: string | null
) => {
  const filteredOrders: OrderBase[] = orders.filter(order => {
    const dateDeliveryAt: string = dayjs(order.deliveryAt).format("MM.DD.YYYY");
    const isAllSelected: boolean = activeFilters.some(filter => filter.value === "ALL");
    const dateFilter: FilterData | undefined = activeFilters.find(filter =>
      dayjs(filter.value, "MM.DD.YYYY", true).isValid()
    );
    const dateFilterValue: string | null = dateFilter?.value ? dateFilter.value : null;

    if (activeFilters.length > 0 && !isAllSelected) {
      const hasSelectedStatus: boolean = activeFilters.some(status => status.value === order.status);
      if (!hasSelectedStatus) {
        return false;
      }
    }

    if (
      (courierId && order.delivery?.courier?.courierUid !== courierId) ||
      (dateFilterValue && dateDeliveryAt !== dateFilterValue)
    ) {
      return false;
    }

    return true;
  });

  return sortByStatus(filteredOrders);
};

export const selectAllRequestedDates: () => Selector<ApplicationStore, string[]> = (): Selector<
  ApplicationStore,
  string[]
> =>
  createSelector(
    (state: ApplicationStore): string[] => state.order.requestedDates,
    requestedDates => {
      const dates: string[] = requestedDates.map(date => dayjs(date).format("MM.DD.YYYY"));
      return dates;
    }
  );

export const selectOrdersByFilters: (
  activeFilters: FilterData[],
  courierId: string
) => Selector<ApplicationStore, Record<string, string[]>> = (activeFilters: FilterData[], courierId: string) =>
  createSelector(
    (state: ApplicationStore): OrderList => state.order.list,
    orders => {
      const filteredOrders: OrderBase[] = filterOrders(orders, activeFilters, courierId);
      const ordersByDate: Record<string, string[]> = filteredOrders.reduce(
        (acc, order) => {
          const dateDeliveryAt: string = dayjs(order.deliveryAt).format("MM.DD.YYYY");

          if (!acc[dateDeliveryAt]) {
            acc[dateDeliveryAt] = [];
          }

          acc[dateDeliveryAt].push(order.uid);

          return acc;
        },
        {} as Record<string, string[]>
      );

      return ordersByDate;
    }
  );

export const selectOrdersForKitchen: () => Selector<ApplicationStore, string[]> = () =>
  createSelector(
    (state: ApplicationStore): OrderList => state.order.list,
    orders => {
      return orders
        .filter(order => order.status === ORDER_STATUSES.IN_PROGRESS || order.status === ORDER_STATUSES.ASSEMBLY)
        .map(order => order.uid);
    }
  );

export const selectOrderCountByStatus: (
  activeFilters: FilterData[],
  courierId: string
) => Selector<ApplicationStore, { total: number; orderCountByStatus: Record<string, number> }> = (
  activeFilters: FilterData[],
  courierId: string
) =>
  createSelector(
    (state: ApplicationStore): OrderList => state.order.list,
    orders => {
      const filteredOrders: OrderBase[] = orders.filter(order => {
        const dateDeliveryAt: string = dayjs(order.deliveryAt).format("MM.DD.YYYY");
        const dateFilter: FilterData | undefined = activeFilters.find(filter =>
          dayjs(filter.value, "MM.DD.YYYY", true).isValid()
        );
        const dateFilterValue: string | null = dateFilter?.value ? dateFilter.value : null;

        if (
          (courierId && order.delivery?.courier?.courierUid !== courierId) ||
          (dateFilterValue && dateDeliveryAt !== dateFilterValue)
        ) {
          return false;
        }

        return true;
      });

      const orderCountByStatus: Record<string, number> = filteredOrders.reduce(
        (acc, order) => {
          const status: string = order.status;
          if (!acc[status]) {
            acc[status] = 0;
          }

          acc[status] += 1;

          return acc;
        },
        {} as Record<string, number>
      );

      return {
        total: filteredOrders.length,
        orderCountByStatus
      };
    }
  );

export const selectFilteredCouriers: (
  activeFilters: FilterData[]
) => Selector<ApplicationStore, { name: string; uid: string }[]> = (activeFilters: FilterData[]) =>
  createSelector(
    (state: ApplicationStore): OrderList => state.order.list,
    orders => {
      const filteredOrders: OrderBase[] = filterOrders(orders, activeFilters, null);
      const couriers: UserBase[] = useSelector(selectCouriersUser);

      const matchingCourierUids: (string | null | undefined)[] = filteredOrders
        .filter(order => order?.delivery?.courier?.courierUid)
        .map(order => order?.delivery?.courier?.courierUid);

      const uniqueCourierUids: (string | null | undefined)[] = Array.from(new Set(matchingCourierUids));

      const filteredCouriers: { name: string; uid: string }[] = couriers
        .filter((courier: UserBase) => uniqueCourierUids.includes(courier.uid))
        .map(courier => ({
          name: courier.name,
          uid: courier.uid
        }));

      return filteredCouriers;
    }
  );

export const selectOrderBySelectOrderUid: Selector<ApplicationStore, Partial<OrderBase> | null> = createSelector(
  [
    (state: ApplicationStore): OrderList => state.order.list,
    (state: ApplicationStore): string | null => state.order.currentOrderUid
  ],
  (orders, currentOrderUid) => {
    if (currentOrderUid === null) {
      return null;
    }

    const order: OrderBase | undefined = orders.find(order => order.uid === currentOrderUid);

    if (order === undefined) {
      return {};
    }

    return order;
  }
);

export const selectOrderNomenclatures: (
  orderUid: string
) => Selector<ApplicationStore, OrderNomenclatureBase[] | null> = orderUid =>
  createSelector(
    (state: ApplicationStore): OrderList => state.order.list,
    orders => {
      if (orderUid === null) {
        return null;
      }

      const order: OrderBase | undefined = orders.find(order => order.uid === orderUid);

      if (order === undefined) {
        return null;
      }

      return (order.nomenclatures as OrderNomenclatureBase[]) || [];
    }
  );

export const makeSelectOrderByOrderUid: () => Selector<ApplicationStore, OrderBase | undefined> = (): Selector<
  ApplicationStore,
  OrderBase | undefined
> =>
  createSelector(
    [
      (state: ApplicationStore): OrderList => state.order.list,
      (_: ApplicationStore, orderUid: string): string => orderUid
    ],
    (orders: OrderList, orderUid: string) => orders.find(order => order.uid == orderUid)
  );
