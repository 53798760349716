import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import React, { ForwardRefExoticComponent, ButtonHTMLAttributes, forwardRef, ForwardedRef } from "react";

import styles from "./order.module.scss";

const DatePickerButton: ForwardRefExoticComponent<ButtonHTMLAttributes<HTMLButtonElement>> = forwardRef(
  ({ value, onClick }: ButtonHTMLAttributes<HTMLButtonElement>, ref: ForwardedRef<HTMLButtonElement>) => {
    let dateValue: string = value as string;
    const day: Dayjs = dayjs(value as string, "DD.MM.yy");
    if (day.isToday()) {
      dateValue = "Сегодня";
    }

    if (day.isTomorrow()) {
      dateValue = "Завтра";
    }

    return (
      <button className={classNames(styles.btnDate)} onClick={onClick} ref={ref}>
        {dateValue}
      </button>
    );
  }
);

DatePickerButton.displayName = "DatePickerButton";

export default DatePickerButton;
