import { CustomerBase, CustomerCreate } from "@nagano_crm/shared/customer/customers.types";
import { Selector, createSelector } from "reselect";

import { ApplicationStore } from "../application.store";

export class EmptyCustomerData {
  static createEmptyCustomerData(): CustomerCreate {
    return {
      name: "",
      phone: "",
      bonuses: null,
      email: null
    };
  }
}

export const selectCustomerByCurrentUid: Selector<ApplicationStore, CustomerBase | CustomerCreate | null> =
  createSelector(
    [
      (state: ApplicationStore): Record<string, CustomerBase> => state.customer.list,
      (state: ApplicationStore): string | null => state.customer.currentCustomerUid
    ],
    (customers, currentCustomerUid) => {
      if (currentCustomerUid === null) {
        return null;
      }

      const customer: CustomerBase | undefined = customers[currentCustomerUid];

      if (customer === undefined) {
        return EmptyCustomerData.createEmptyCustomerData();
      }

      return customer;
    }
  );
