import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";
import {
  NomenclatureBase,
  NomenclatureCreate,
  NomenclatureUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclatures.types";

//Handlers Socket
const handleConnectedSocket: ActionCreatorWithoutPayload = createAction<void>("nomenclature/connectedSocket");
const handleDisconnectedSocket: ActionCreatorWithoutPayload = createAction<void>("nomenclature/disconnectedSocket");
//  const handleGetListNomenclatures: ActionCreatorWithPayload<NomenclatureBase[]> =
//   createAction<NomenclatureBase[]>("nomenclature/handleList");
const handleNomenclatureCreate: ActionCreatorWithPayload<NomenclatureBase> =
  createAction<NomenclatureBase>("nomenclature/handleCreate");
const handleNomenclatureUpdate: ActionCreatorWithPayload<NomenclatureBase> =
  createAction<NomenclatureBase>("nomenclature/handleUpdate");
const handleNomenclatureUpdateMore: ActionCreatorWithPayload<NomenclatureBase[]> = createAction<NomenclatureBase[]>(
  "nomenclature/handleUpdateMore"
);
const handleNomenclatureDelete: ActionCreatorWithPayload<string> = createAction<string>("nomenclature/handleDelete");

//Set Data
const setNomenclatureList: ActionCreatorWithPayload<NomenclatureBase[]> =
  createAction<NomenclatureBase[]>("nomenclature/setList");
const resetListNomenclatures: ActionCreatorWithoutPayload = createAction<void>("nomenclature/reset");

//Actions
const changeCurrentNomenclature: ActionCreatorWithPayload<string | null, string> = createAction<string | null>(
  "nomenclature/changeCurrent"
);
const createNomenclature: ActionCreatorWithPayload<NomenclatureCreate> =
  createAction<NomenclatureCreate>("nomenclature/create");

const updateNomenclature: ActionCreatorWithPayload<NomenclatureUpdate> =
  createAction<NomenclatureUpdate>("nomenclature/update");

const deleteNomenclature: ActionCreatorWithPayload<string> = createAction<string>("nomenclature/delete");

const createNewNomenclature: ActionCreatorWithoutPayload = createAction<void>("nomenclature/createNew");

export default {
  handleConnectedSocket,
  handleDisconnectedSocket,
  handleNomenclatureCreate,
  handleNomenclatureUpdate,
  handleNomenclatureUpdateMore,
  handleNomenclatureDelete,
  setNomenclatureList,
  resetListNomenclatures,
  changeCurrentNomenclature,
  createNomenclature,
  updateNomenclature,
  deleteNomenclature,
  createNewNomenclature
};
