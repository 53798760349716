import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import { configureStore, Middleware, Store } from "@reduxjs/toolkit";

import { orderReducer, OrderStore } from "./order/order.store";
import { authReducer, AuthStore } from "./auth/auth.store";
import { errorReducer, ErrorStore } from "./error/error.store";
import rootSaga from "./saga";
import { nomenclatureReducer, NomenclatureStore } from "./nomenclatures/nomenclatures.store";
import { customerReducer, CustomerStore } from "./customer/customer.store";
import { UserReducer, UserStore } from "./user/user.store";
import { kitchenReducer, KitchenStore } from "./kitchen/kitchen.store";
import { settingsReducer, SettingsStore } from "./settings/settings.store";

export interface ApplicationStore {
  error: ErrorStore;
  auth: AuthStore;
  user: UserStore;
  customer: CustomerStore;
  order: OrderStore;
  kitchen: KitchenStore;
  nomenclature: NomenclatureStore;
  settings: SettingsStore;
}

export function createApplicationStore(): Store<ApplicationStore> {
  const sagaMiddleware: SagaMiddleware = createSagaMiddleware();
  const store: Store<ApplicationStore> = configureStore({
    reducer: {
      error: errorReducer,
      auth: authReducer,
      user: UserReducer,
      customer: customerReducer,
      order: orderReducer,
      nomenclature: nomenclatureReducer,
      kitchen: kitchenReducer,
      settings: settingsReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware as Middleware)
  });
  sagaMiddleware.run(rootSaga);
  return store;
}
