import { RoleBase, UserBase, UserCreate, UserUpdate } from "@nagano_crm/shared/auth/user.interface";
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

const handleConnectSocket: ActionCreatorWithoutPayload = createAction<void>("user/handleConnectSocket");
const handleDisconnectSocket: ActionCreatorWithoutPayload = createAction<void>("user/handleDisconnectSocket");

const handleCreateUser: ActionCreatorWithPayload<UserBase> = createAction<UserBase>("user/handleCreate");
const handleUpdateUser: ActionCreatorWithPayload<UserUpdate> = createAction<UserUpdate>("user/handleUpdate");
const handleDeleteUser: ActionCreatorWithPayload<string> = createAction<string>("user/handleDelete");

const createUser: ActionCreatorWithPayload<UserCreate> = createAction<UserCreate>("user/create");
const successCreateUser: ActionCreatorWithPayload<UserBase> = createAction<UserBase>("user/successCreate");
const updateUser: ActionCreatorWithPayload<UserUpdate> = createAction<UserUpdate>("user/update");
const successUpdateUser: ActionCreatorWithPayload<UserUpdate> = createAction<UserUpdate>("user/successUpdate");
const deleteUser: ActionCreatorWithPayload<string> = createAction<string>("user/delete");

const setUserList: ActionCreatorWithPayload<UserBase[]> = createAction<UserBase[]>("user/setList");
const setRoleList: ActionCreatorWithPayload<RoleBase[]> = createAction<RoleBase[]>("user/setRoleList");

const changeCurrentUser: ActionCreatorWithPayload<string | null, string> = createAction<string | null>(
  "user/changeCurrent"
);
const createNewUser: ActionCreatorWithoutPayload = createAction<void>("user/createNew");

export default {
  handleConnectSocket,
  handleDisconnectSocket,
  handleCreateUser,
  handleUpdateUser,
  handleDeleteUser,
  createUser,
  successCreateUser,
  updateUser,
  successUpdateUser,
  deleteUser,
  setUserList,
  setRoleList,
  changeCurrentUser,
  createNewUser
};
