import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./order-menu.module.scss";
import { OrderMenuProps } from "./order-menu.types";

import {
  CategoriesWithNomenclatures,
  selectCategoryWithNomenclatures
} from "~/app/nomenclatures/nomenclature.selectors";

export const OrderMenuComponent: React.FC<OrderMenuProps> = (props: OrderMenuProps) => {
  const [activeCategory, setActiveCategory]: [string | null, React.Dispatch<React.SetStateAction<string | null>>] =
    useState<string | null>(null);
  const [searchNomenclature, setSearchNomenclature]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");

  const categoriesWithNomenclatures: CategoriesWithNomenclatures = useSelector(
    selectCategoryWithNomenclatures(searchNomenclature)
  );

  return (
    <div className={styles.orderMenuWrapper}>
      <div className={styles.headerOrderMenuWrapper}>
        <div className={styles.searchBox}>
          <input
            className={styles.searchInput}
            placeholder="Все блюда"
            onChange={(e): void => setSearchNomenclature(e.target.value)}
          />
        </div>
        <div className={styles.selectMenuBox}>
          <span style={{ color: "#FF5C00" }}>Прайс 22.09.22</span>
        </div>
      </div>
      <div className={styles.nomenclaturesOrderMenu}>
        {categoriesWithNomenclatures
          .filter(category => category.nomenclatures.length > 0)
          .map(category => (
            <div className={styles.categoriesNomenclatureWrapper} key={category.uid}>
              <div
                className={classNames(styles.cardBox, styles.nomenclatureCategoryBox)}
                onClick={(): void => setActiveCategory(activeCategory == category.uid ? null : category.uid)}
              >
                {category.name}
              </div>
              <div
                className={classNames(styles.nomenclaturesWrapper, activeCategory === category.uid && styles.active)}
              >
                {category.nomenclatures.map(nomenclature => (
                  <div
                    className={classNames(styles.cardBox, styles.nomenclatureBox)}
                    key={nomenclature.uid}
                    onClick={(): void =>
                      props.onAdd({
                        nomenclatureUid: nomenclature.uid,
                        price: Number(nomenclature.price),
                        name: nomenclature.name
                      })
                    }
                  >
                    <div className={styles.name}>{nomenclature.name}</div>
                    <div className={styles.price}>{nomenclature.price}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
