import classNames from "classnames";
import React, { Dispatch, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./nomenclature.module.scss";

import { ApplicationStore } from "~/app/application.store";
import {
  CategoriesWithNomenclatures,
  selectCategoryWithNomenclatures
} from "~/app/nomenclatures/nomenclature.selectors";
import nomenclaturesCategoryActions from "~/app/nomenclatures/nomenclatures-category.actions";
import nomenclaturesActions from "~/app/nomenclatures/nomenclatures.actions";
import AccordionButtonIcon from "~/view/assets/accordion-button-icon";
import { NomenclatureCategorySidebar } from "~/view/components/sidebars/nomenclature-category/nomenclature-category.component";
import { NomenclatureSidebar } from "~/view/components/sidebars/nomenclature/nomenclature.component";
import SearchIcon from "~/view/assets/search-icon";
import SettingsIcon from "~/view/assets/settings-icon";

const NomenclaturesPage: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [searchNomenclature, setSearchNomenclature]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");
  const categoriesWithNomenclatures: CategoriesWithNomenclatures = useSelector(
    selectCategoryWithNomenclatures(searchNomenclature)
  );

  const changeCurrentNomenclatureCategory: (categoryUid: string) => void = useCallback(
    (categoryUid: string) => dispatch(nomenclaturesCategoryActions.changeCurrentNomenclatureCategory(categoryUid)),
    [dispatch]
  );

  const changeCurrentNomenclature: (nomenclatureUid: string) => void = useCallback(
    (nomenclatureUid: string) => dispatch(nomenclaturesActions.changeCurrentNomenclature(nomenclatureUid)),
    [dispatch]
  );

  const isOpenNomenclatureCategorySidebar: boolean = useSelector(
    (state: ApplicationStore) => state.nomenclature.isOpenSidebarNomenclatureCategory
  );

  const isOpenNomenclatureSidebar: boolean = useSelector(
    (state: ApplicationStore) => state.nomenclature.isOpenSidebarNomenclature
  );

  const handleAccordionIconClick: (e: React.MouseEvent<HTMLDivElement>) => void = (
    e: React.MouseEvent<HTMLDivElement>
  ): void => {
    e.stopPropagation();
    e.preventDefault();
    const parentElement: HTMLElement = e.currentTarget.parentElement!;
    parentElement.classList.toggle(styles.closed);
    // e.currentTarget.classList.toggle(styles.closed);
    if (parentElement) {
      const categoryWrapper: HTMLElement = parentElement.parentElement!;
      if (categoryWrapper) {
        const nomenclaturesWrapper: HTMLElement | null = categoryWrapper.querySelector(
          `.${styles.nomenclaturesWrapper}`
        );
        if (nomenclaturesWrapper) {
          nomenclaturesWrapper.classList.toggle(styles.closed);
        }
      }
    }
  };

  return (
    <div className={styles.nomenclaturesPageWrapper}>
      <div className={styles.titlePageSearchInput}>
        <input type="text" placeholder="Номенклатура" onChange={(e): void => setSearchNomenclature(e.target.value)} />
        <SearchIcon />
      </div>
      <div className={styles.categoriesWithNomenclaturesWrapper}>
        <div className={styles.nomenclatureHeader}>
          <div className={styles.name}>Наименование</div>
          <div className={styles.price}>Цена, ₽</div>
          <div className={styles.balance}>Остаток, шт.</div>
        </div>
        {categoriesWithNomenclatures.map((category, i) => {
          return (
            <div key={i} className={styles.categoryWithNomenclaturesWrapper}>
              <div
                className={classNames(styles.categoryWrapper)}
                // onClick={(): void => changeCurrentNomenclatureCategory(category.uid)}
              >
                {category.nomenclatures.length > 0 && (
                  <div className={styles.accordionIconWrapper}>
                    <AccordionButtonIcon />
                  </div>
                )}
                <div className={styles.categoryTitle} onClick={handleAccordionIconClick}>
                  {category.name}
                </div>
                {categoriesWithNomenclatures.length - 1 !== i && (
                  <div
                    className={styles.settingsIconWrapper}
                    onClick={(): void => changeCurrentNomenclatureCategory(category.uid)}
                  >
                    <SettingsIcon />
                  </div>
                )}
              </div>
              <div className={styles.nomenclaturesWrapper}>
                {category.nomenclatures.map((nomenclature, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(): void => changeCurrentNomenclature(nomenclature.uid)}
                      className={classNames({ [styles.evenBlock]: i % 2 !== 0 }, styles.nomenclatureWrapper)}
                    >
                      <div className={styles.name}>{nomenclature.name}</div>
                      <div className={styles.price}>{nomenclature.price}</div>
                      <div className={styles.balance}>{nomenclature.balance}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.btnNew}>
        <div onClick={(): void => dispatch(nomenclaturesCategoryActions.createNewNomenclatureCategory())}>
          Раздел блюд
        </div>
        <div onClick={(): void => dispatch(nomenclaturesActions.createNewNomenclature())}>Блюдо</div>
      </div>
      {isOpenNomenclatureCategorySidebar && <NomenclatureCategorySidebar />}
      {isOpenNomenclatureSidebar && <NomenclatureSidebar />}
    </div>
  );
};

export default NomenclaturesPage;
