import React, { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PayloadAction } from "typesafe-actions";
import classNames from "classnames";
import { OrderNomenclatureBase } from "@nagano_crm/shared/orders/order-nomenclatures.types";
import { ORDER_STATUSES } from "@nagano_crm/shared/orders/order.types";

import { NomenclatureItem } from "./nomenclature-item/nomenclature-item";
import { OrderItemComponent } from "./order-item/order-item.component";
import styles from "./kitchen.module.scss";
import ConfirmationPopup from "./confirm-popup/confirm-popup.component";

import childStyles from "~/public/styles/child.module.scss";
import {
  selectOrderKitchenNomenclatures,
  selectOrdersForKitchen,
  selectOrdersKitchenInWork,
  selectReadyNomenclatures
} from "~/app/kitchen/kitchen.selector";
import ArrowIcon from "~/view/assets/arrow-icon";
import kitchenActions from "~/app/kitchen/kitchen.actions";

const KitchenView: React.FC = () => {
  const [selectedOrderUid, setSelectedOrderUid]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");
  const [orderIndex, setOrderIndex]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(0);
  const [nomenclatureIndex, setNomenclatureIndex]: [number, React.Dispatch<React.SetStateAction<number>>] =
    useState<number>(0);
  const [showReadyConfirmation, setShowReadyConfirmation]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);

  const orderListRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const orderListContainer: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const nomenclatureTableRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const nomenclatureWrapperRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const orderHeight: number = 83;
  const nomenclatureWidth: number = 254;
  const nomenclaturesOnscreen: number = 16;

  const orderUids: string[] = useSelector(selectOrdersForKitchen());

  const nomenclatures: OrderNomenclatureBase[] | null = useSelector(selectOrderKitchenNomenclatures(selectedOrderUid));
  const ordersInWork: string[] = useSelector(selectOrdersKitchenInWork());
  const ordersWithReadyNomenclatures: { [orderUid: string]: string[] } = useSelector(selectReadyNomenclatures());

  const dispatch: Dispatch<PayloadAction<string, any>> = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchOrdersByDateAction(formattedDate));
  // }, [dispatch, formattedDate]);

  useEffect(() => {
    if (orderListRef.current) {
      orderListRef.current.style.transform = `translateY(-${orderIndex * orderHeight}px)`;
    }
  }, [orderIndex]);

  useEffect(() => {
    if (nomenclatureTableRef.current) {
      nomenclatureTableRef.current.style.transform = `translateX(-${nomenclatureIndex * nomenclatureWidth}px)`;
    }
  }, [nomenclatureIndex]);

  const isScrollDownDisabled: () => boolean = useCallback(() => {
    if (!orderListContainer.current || !orderListRef.current || !orderUids.length) {
      return true;
    }
    const containerBottom: number = orderListContainer.current.clientHeight;
    const listBottom: number = orderListRef.current.clientHeight - orderIndex * orderHeight;
    return listBottom > 0 && containerBottom - listBottom >= orderHeight * 2;
  }, [orderUids, orderIndex]);

  const isScrollRightDisabled: () => boolean = useCallback(() => {
    if (!nomenclatureWrapperRef.current || !nomenclatureTableRef.current || !nomenclatures) {
      return true;
    }
    if (nomenclatures.length <= nomenclaturesOnscreen) {
      return true;
    }
    return nomenclatureIndex === Math.ceil(nomenclatures.length / nomenclaturesOnscreen);
  }, [nomenclatures, nomenclatureIndex]);

  const handleScrollOrderItemsUp: () => void = () => {
    setOrderIndex(prev => Math.max(0, prev - 1));
  };

  const handleScrollOrderItemsDown: () => void = () => {
    if (isScrollDownDisabled()) {
      return;
    }
    setOrderIndex(prev => Math.min(orderUids.length - 1, prev + 1));
  };

  const handleScrollNomenclatureItemsLeft: () => void = (): void => {
    setNomenclatureIndex(prev => Math.max(0, prev - 1));
  };

  const handleScrollNomenclatureItemsRight: () => void = (): void => {
    if (!nomenclatures || isScrollRightDisabled()) {
      return;
    }
    setNomenclatureIndex(prev => Math.min(nomenclatures.length - 1, prev + 1));
  };

  const handleOrderChangeStatus: (event: React.MouseEvent) => void = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (selectedOrderUid) {
        if (!ordersInWork.includes(selectedOrderUid)) {
          dispatch(kitchenActions.setOrderInWorkAction(selectedOrderUid));
        } else {
          setShowReadyConfirmation(true);
        }
      }
    },
    [dispatch, selectedOrderUid, ordersInWork]
  );

  const handleReadyConfirm: () => void = useCallback(() => {
    if (selectedOrderUid) {
      setShowReadyConfirmation(false);
      dispatch(kitchenActions.updateOrder({ uid: selectedOrderUid, status: ORDER_STATUSES.ASSEMBLY }));
      setSelectedOrderUid("");
    }
  }, [dispatch, selectedOrderUid]);

  const handleOrderClick: (orderUid: string) => void = useCallback((orderUid: string) => {
    setSelectedOrderUid(orderUid);
    setNomenclatureIndex(0);
  }, []);

  const handleNomenclatureClick: (orderUid: string, nomenclatureUid: string) => void = (
    orderUid,
    nomenclatureUid: string
  ) => {
    dispatch(kitchenActions.setReadyNomenclatures({ orderUid, nomenclatureUid }));
  };

  return (
    <div style={{ display: "flex" }} className={classNames(styles.kitchenView)}>
      <ConfirmationPopup
        isOpen={showReadyConfirmation}
        onConfirm={handleReadyConfirm}
        onCancel={(): void => setShowReadyConfirmation(false)}
        title="Подтвердите готовность"
        description=""
        confirmText="Готово"
        cancelText="Отмена"
      />
      <div className={classNames(styles.orderListContainer)} ref={orderListContainer}>
        <div className={classNames(styles.orderUpWrapper)}></div>
        <button
          className={classNames(styles.ordersUpButton, {
            [styles.disabledButton]: orderIndex === 0
          })}
          onClick={handleScrollOrderItemsUp}
        >
          <ArrowIcon />
        </button>
        <div className={classNames(styles.orderList)} ref={orderListRef}>
          {orderUids.map(orderUid => (
            <OrderItemComponent
              uid={orderUid}
              key={orderUid}
              onOrderClick={handleOrderClick}
              isSelected={selectedOrderUid === orderUid}
              isOrderInWork={ordersInWork.includes(orderUid)}
            />
          ))}
        </div>
        <div className={classNames(styles.orderDownWrapper)}></div>
        <button
          className={classNames(styles.orderDownButton, {
            [styles.disabledButton]: isScrollDownDisabled()
          })}
          onClick={handleScrollOrderItemsDown}
        >
          <ArrowIcon />
        </button>
      </div>
      <div
        className={classNames(
          childStyles.d_flex,
          childStyles.flex_column,
          childStyles.justify_content_between,
          styles.nomenclatureWrapper
        )}
        ref={nomenclatureWrapperRef}
      >
        <div className={classNames(styles.nomenclatureTable)} ref={nomenclatureTableRef}>
          {nomenclatures
            ? nomenclatures.map(nomenclature => (
                <NomenclatureItem
                  uid={nomenclature.uid}
                  key={nomenclature.uid}
                  title={nomenclature.name}
                  quantity={nomenclature.quantity}
                  comment={""}
                  onNomenclatureClick={(): void => {
                    handleNomenclatureClick(selectedOrderUid, nomenclature.uid);
                  }}
                  isSelected={
                    ordersWithReadyNomenclatures[selectedOrderUid] &&
                    ordersWithReadyNomenclatures[selectedOrderUid].includes(nomenclature.uid)
                  }
                />
              ))
            : null}
        </div>
        <div className={classNames(styles.bottomButtons, childStyles.d_flex, childStyles.gap_1)}>
          <button
            className={classNames(styles.button, styles.statusButton, childStyles.title_font_s_size, {
              [styles.readyButton]: ordersInWork.includes(selectedOrderUid),
              [styles.disabledButton]: !selectedOrderUid
            })}
            onClick={handleOrderChangeStatus}
          >
            {ordersInWork.includes(selectedOrderUid) ? "Готово" : "Взять в работу"}
          </button>
          <div className={classNames(childStyles.d_flex, childStyles.gap_1, childStyles.flex_grow_2)}>
            <button
              className={classNames(
                styles.button,
                styles.pageUpButton,
                childStyles.title_font_s_size,
                childStyles.flex_grow_1,
                { [styles.disabledButton]: nomenclatureIndex === 0 }
              )}
              onClick={handleScrollNomenclatureItemsLeft}
            >
              <ArrowIcon />
            </button>
            <button
              className={classNames(
                styles.button,
                styles.pageDownButton,
                childStyles.title_font_s_size,
                childStyles.flex_grow_1,
                {
                  [styles.disabledButton]: isScrollRightDisabled()
                }
              )}
              onClick={handleScrollNomenclatureItemsRight}
            >
              <ArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitchenView;
