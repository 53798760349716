import { Selector, createSelector } from "reselect";
import { NomenclatureBase, NomenclatureCreate } from "@nagano_crm/shared/nomenclatures/nomenclatures.types";
import {
  NomenclatureCategoryBase,
  NomenclatureCategoryCreate
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";

import { ApplicationStore } from "../application.store";

export type CategoriesWithNomenclatures = (NomenclatureCategoryBase & { nomenclatures: NomenclatureBase[] })[];

export class EmptyNomenclatureCategoryData {
  static createEmptyNomenclatureCategoryData(): NomenclatureCategoryCreate {
    return {
      name: "",
      description: null
    };
  }
}

export class EmptyNomenclatureData {
  static createEmptyNomenclatureData(): NomenclatureCreate {
    return {
      name: "",
      description: null,
      categoryUid: "",
      price: "0",
      balance: 0,
      status: ""
    };
  }
}

export const selectCategoryWithNomenclatures: (
  search: string
) => Selector<ApplicationStore, CategoriesWithNomenclatures> = (
  search
): Selector<ApplicationStore, CategoriesWithNomenclatures> =>
  createSelector(
    [
      (state: ApplicationStore): Record<string, NomenclatureBase> => state.nomenclature.list,
      (state: ApplicationStore): Record<string, NomenclatureCategoryBase> => state.nomenclature.listCategories
    ],
    (nomenclatures, categories) => {
      const categoriesWithNomenclatures: CategoriesWithNomenclatures = [];

      Object.values(categories).forEach(category => {
        const filteredNomenclatures: NomenclatureBase[] = Object.values(nomenclatures)
          .filter(nomenclature => nomenclature.categoryUid === category.uid)
          .filter(nomenclature =>
            search ? nomenclature.name.toLocaleLowerCase().includes(search.trim().toLowerCase()) : true
          );

        if (filteredNomenclatures.length > 0 || !search) {
          categoriesWithNomenclatures.push({
            ...category,
            nomenclatures: filteredNomenclatures
          });
        }
      });

      const uncategorizedNomenclatures: NomenclatureBase[] = Object.values(nomenclatures)
        .filter(nomenclature => nomenclature.categoryUid === "")
        .filter(nomenclature =>
          search ? nomenclature.name.toLocaleLowerCase().includes(search.trim().toLowerCase()) : true
        );

      if (uncategorizedNomenclatures.length > 0 || !search) {
        categoriesWithNomenclatures.push({
          uid: "",
          description: "",
          createdAt: new Date(),
          updatedAt: new Date(),
          name: "Без категории",
          nomenclatures: uncategorizedNomenclatures
        });
      }

      return categoriesWithNomenclatures;
    }
  );

export const selectNomenclatureCategoryWithoutCurrentUid: Selector<
  ApplicationStore,
  Record<string, NomenclatureCategoryBase>
> = createSelector(
  [
    (state: ApplicationStore): Record<string, NomenclatureCategoryBase> => state.nomenclature.listCategories,
    (state: ApplicationStore): string | null => state.nomenclature.currentNomenclatureCategoryUid
  ],
  (categories, currentCategoryUid) => {
    return Object.values(categories).reduce(
      (result, category) => {
        if (category.uid !== currentCategoryUid) {
          result[category.uid] = category;
        }
        return result;
      },
      {} as Record<string, NomenclatureCategoryBase>
    );
  }
);

export const selectAllNomenclatureCategories: Selector<
  ApplicationStore,
  Record<string, NomenclatureCategoryBase>
> = createSelector(
  [(state: ApplicationStore): Record<string, NomenclatureCategoryBase> => state.nomenclature.listCategories],
  categories => categories
);

export const selectNomenclatureCategoryByCurrentUid: Selector<
  ApplicationStore,
  NomenclatureCategoryBase | NomenclatureCategoryCreate | null
> = createSelector(
  [
    (state: ApplicationStore): Record<string, NomenclatureCategoryBase> => state.nomenclature.listCategories,
    (state: ApplicationStore): string | null => state.nomenclature.currentNomenclatureCategoryUid
  ],
  (categories, currentCategoryUid) => {
    if (currentCategoryUid === null) {
      return null;
    }

    const category: NomenclatureCategoryBase | undefined = categories[currentCategoryUid];

    if (category === undefined) {
      return EmptyNomenclatureCategoryData.createEmptyNomenclatureCategoryData();
    }

    return category;
  }
);

export const selectNomenclatureByCurrentUid: Selector<ApplicationStore, NomenclatureBase | NomenclatureCreate | null> =
  createSelector(
    [
      (state: ApplicationStore): Record<string, NomenclatureBase> => state.nomenclature.list,
      (state: ApplicationStore): string | null => state.nomenclature.currentNomenclatureUid
    ],
    (nomenclatures, currentNomenclatureUid) => {
      if (currentNomenclatureUid === null) {
        return null;
      }

      const nomenclature: NomenclatureBase | undefined = nomenclatures[currentNomenclatureUid];

      if (nomenclature === undefined) {
        return EmptyNomenclatureData.createEmptyNomenclatureData();
      }

      return nomenclature;
    }
  );
