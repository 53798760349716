import React, { Dispatch, memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import sideBar from "../../sidebar.module.scss";

import styles from "./order-item.module.scss";
import { OrderItemSidebarProps } from "./order-item.types";

import childStyles from "~/public/styles/child.module.scss";

export const OrderItemComponent: React.FC<OrderItemSidebarProps> = memo((props: OrderItemSidebarProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const { order }: OrderItemSidebarProps = props;

  if (!order) {
    return;
  }

  const dateDeliveryAt: string = moment(order.deliveryAt).format("DD.MM.YY HH:mm");

  const total: string = new Intl.NumberFormat("ru-RU").format(order.total);

  const handleRepeatOrder: () => void = useCallback(() => {
    console.log("repeat");
  }, [dispatch]);

  return (
    <div className={classNames(styles.orderItemWrapper)}>
      <div className={sideBar.date}>{dateDeliveryAt}</div>
      <div className={classNames(sideBar.nomenclatures, childStyles.d_flex, childStyles.flex_wrap)}>
        {order.nomenclatures.map((nomenclature, index) => {
          return (
            <div className={childStyles.text_font_s_size} key={nomenclature.nomenclatureUid}>
              {nomenclature.name ?? ""} (x{nomenclature.quantity})
              {index !== order.nomenclatures.length - 1 ? ",\u00A0" : ""}
            </div>
          );
        })}
      </div>
      <div className={classNames(sideBar.total)}>{total}&nbsp;₽</div>
      <div
        className={classNames(childStyles.regular_link, childStyles.text_font_s_size, childStyles.ml_auto)}
        onClick={handleRepeatOrder}
      >
        Повторить
      </div>
    </div>
  );
});

OrderItemComponent.displayName = "OrderItemComponent";
