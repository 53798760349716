import { CustomerBase, CustomerCreate, CustomerUpdate } from "@nagano_crm/shared/customer/customers.types";
import { OrderList } from "@nagano_crm/shared/orders/order.types";
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload, createAction } from "@reduxjs/toolkit";

//Handlers
const handleConnectedCustomerSocket: ActionCreatorWithoutPayload = createAction<void>("customer/connectedSocket");

const handleCustomerCreate: ActionCreatorWithPayload<CustomerBase> =
  createAction<CustomerBase>("customer/handleCreate");

const handleCustomerUpdate: ActionCreatorWithPayload<CustomerBase> =
  createAction<CustomerBase>("customer/handleUpdate");

const handleCustomerDelete: ActionCreatorWithPayload<{ uid: string }> = createAction<{ uid: string }>(
  "customer/handleDelete"
);

//Set Data
const setCustomerList: ActionCreatorWithPayload<CustomerBase[]> = createAction<CustomerBase[]>("customer/setList");
const setOrdersCustomer: ActionCreatorWithPayload<OrderList> = createAction<OrderList>("customer/setOrders");
const setResultSearchByPhone: ActionCreatorWithPayload<CustomerBase[]> =
  createAction<CustomerBase[]>("customer/setResultSearch");
const resetResultSearchByPhone: ActionCreatorWithoutPayload = createAction<void>("customer/resetResultSearch");

const resetListCustomer: ActionCreatorWithoutPayload = createAction<void>("customer/reset");
const getListCustomer: ActionCreatorWithoutPayload = createAction<void>("customer/getList");
const searchCustomerByPhone: ActionCreatorWithPayload<string> = createAction<string>("customer/searchByPhone");

const changeCurrentCustomer: ActionCreatorWithPayload<string | null, string> = createAction<string | null>(
  "customer/changeCurrent"
);

const createCustomer: ActionCreatorWithPayload<CustomerCreate> = createAction<CustomerCreate>("customer/create");

const updateCustomer: ActionCreatorWithPayload<CustomerUpdate> = createAction<CustomerUpdate>("customer/update");

const deleteCustomer: ActionCreatorWithPayload<string> = createAction<string>("customer/delete");

const handleClickNewCustomer: ActionCreatorWithoutPayload = createAction<void>("customer/clickNew");

export default {
  handleConnectedCustomerSocket,
  handleCustomerCreate,
  handleCustomerUpdate,
  handleCustomerDelete,
  setCustomerList,
  setOrdersCustomer,
  setResultSearchByPhone,
  resetResultSearchByPhone,
  resetListCustomer,
  getListCustomer,
  searchCustomerByPhone,
  changeCurrentCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  handleClickNewCustomer
};
