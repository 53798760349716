import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NomenclatureCategoryBase,
  NomenclatureCategoryCreate,
  NomenclatureCategoryUpdate
} from "@nagano_crm/shared/nomenclatures/nomenclature-category.types";
import _ from "lodash";

import { SidebarCoreComponent } from "../sidebar-core/sidebar-core.component";
import { DropDownList } from "../../dropdown-list/dropdown-list.component";
import styles from "../sidebar.module.scss";
import useValidation, { UseValidationResult } from "../../input-validation/useValidation";
import useIsFormsFieldsEmpty from "../../check-fields/useIsFormsFieldsEmpty";

import {
  selectNomenclatureCategoryByCurrentUid,
  selectNomenclatureCategoryWithoutCurrentUid
} from "~/app/nomenclatures/nomenclature.selectors";
import nomenclaturesCategoryActions from "~/app/nomenclatures/nomenclatures-category.actions";

export const NomenclatureCategorySidebar: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const nomenclaturesCategoriesList: Record<string, NomenclatureCategoryBase> = useSelector(
    selectNomenclatureCategoryWithoutCurrentUid
  );
  const nomenclatureCategory: NomenclatureCategoryBase | NomenclatureCategoryCreate | null = useSelector(
    selectNomenclatureCategoryByCurrentUid
  );

  const [nomenclatureCategoryChangeForm, setNomenclatureCategoryChangeForm]: [
    NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null,
    React.Dispatch<React.SetStateAction<NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null>>
  ] = useState<NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null>(null);

  const [nomenclatureCategoryForm, setNomenclatureCategoryForm]: [
    NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null,
    React.Dispatch<React.SetStateAction<NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null>>
  ] = useState<NomenclatureCategoryUpdate | NomenclatureCategoryCreate | null>(() => {
    if (!nomenclatureCategory) {
      return null;
    }
    return { ...nomenclatureCategory, ...nomenclatureCategoryChangeForm } as
      | NomenclatureCategoryUpdate
      | NomenclatureCategoryCreate;
  });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  useEffect(() => {
    setNomenclatureCategoryForm(
      nomenclatureCategory ? { ...nomenclatureCategory, ...nomenclatureCategoryChangeForm } : null
    );
  }, [nomenclatureCategory, nomenclatureCategoryChangeForm]);

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(nomenclaturesCategoryActions.changeCurrentNomenclatureCategory(null)),
    [dispatch]
  );

  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = [
    { name: "name", value: nomenclatureCategoryForm?.name, error: "Введите название" }
  ];

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    nomenclatureCategoryForm
  );

  const handleSaveNomenclatureCategory: (
    nomenclatureCategory: NomenclatureCategoryCreate | NomenclatureCategoryUpdate
  ) => void = useCallback(
    (nomenclatureCategory: NomenclatureCategoryCreate | NomenclatureCategoryUpdate) => {
      setShowErrors(true);
      if (validationErrors.length) {
        return;
      }
      if ((nomenclatureCategory as NomenclatureCategoryUpdate).uid) {
        dispatch(
          nomenclaturesCategoryActions.updateNomenclatureCategory(nomenclatureCategory as NomenclatureCategoryUpdate)
        );
      } else {
        dispatch(
          nomenclaturesCategoryActions.createNomenclatureCategory(nomenclatureCategory as NomenclatureCategoryCreate)
        );
      }
    },
    [dispatch, validationErrors]
  );

  const handleDeleteNomenclatureCategory: (uid: string) => void = useCallback(
    (uid: string) => dispatch(nomenclaturesCategoryActions.deleteNomenclatureCategory(uid)),
    [dispatch]
  );

  if (_.isEmpty(nomenclatureCategoryForm)) {
    return <></>;
  }

  useEffect(() => {
    setIsFormChanged(!useIsFormsFieldsEmpty(nomenclatureCategoryChangeForm));
  }, [nomenclatureCategoryChangeForm]);

  const handleNomenclatureCategoryChange: (target: { name: string; value: string }) => void = (target: {
    name: string;
    value: string;
  }) => {
    setNomenclatureCategoryChangeForm(prevNomenclatureCategory => {
      return { ...prevNomenclatureCategory, [target.name]: target.value ? target.value : null } as
        | NomenclatureCategoryCreate
        | NomenclatureCategoryUpdate;
    });
  };

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveNomenclatureCategory(nomenclatureCategoryForm)}
      actionOnClickClose={(): void => handleCloseSidebar()}
      actionOnClickDelete={
        (nomenclatureCategoryForm as NomenclatureCategoryUpdate)?.uid
          ? (): void => handleDeleteNomenclatureCategory((nomenclatureCategoryForm as NomenclatureCategoryUpdate).uid)
          : undefined
      }
    >
      <div className={styles.sidebarWrapper}>
        <header>
          <input
            type="text"
            name="name"
            placeholder="Название раздела"
            value={nomenclatureCategoryForm?.name ?? ""}
            onChange={(e): void => handleNomenclatureCategoryChange(e.target)}
          />
          {getErrorMessage("name") && <span>{getErrorMessage("name")}</span>}
        </header>
        <div className={styles.mainWrapper}>
          <div className={styles.inputWrappers}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Описание</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="description"
                  placeholder="Расскажите о разделе"
                  value={nomenclatureCategoryForm?.description ?? ""}
                  onChange={(e): void => handleNomenclatureCategoryChange(e.target)}
                />
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>Категория</div>
              <div className={styles.inputBox}>
                <DropDownList
                  displayValue={
                    nomenclaturesCategoriesList[nomenclatureCategoryForm.categoryParentId ?? ""]?.name ?? "Пусто"
                  }
                  data={[
                    { name: "Пусто", value: "" },
                    ...Object.entries(nomenclaturesCategoriesList).map(
                      ([key, value]: [string, { name: string }]): { name: string; value: string } => ({
                        name: value?.name ?? "",
                        value: key
                      })
                    )
                  ]}
                  value={nomenclatureCategoryForm.categoryParentId ?? ""}
                  name="categoryParentId"
                  handleChange={(data): void => handleNomenclatureCategoryChange(data)}
                  color="default"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
