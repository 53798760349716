import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Layout from "./layout";
import { Settings } from "./components/sidebars/settings/settings.component";
import ErrorPage from "./error/error-page/error-page.component";
import NomenclaturesPage from "./pages/nomenclature/nomenclature.component";
import { CustomerPageComponent } from "./pages/customer/customer.component";
import OrderPageComponent from "./pages/order/orders.component";
import { UserPage } from "./pages/user/page/user.page";
import KitchenView from "./pages/kitchen/kitchen.page";
import { Auth } from "./auth/auth.container";
import { AuthKitchenComponent } from "./auth/auth-kitchen.component";
export const RoutesComponent: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route element={<AuthKitchenComponent />}>
          <Route path="/kitchen" element={<KitchenView />} />
        </Route>
        <Route element={<Auth />}>
          <Route element={<Layout />}>
            <Route path="/" element={<OrderPageComponent />} />
            <Route path="catalog" element={<NomenclaturesPage />} />
            <Route path="/clients" element={<CustomerPageComponent />} />
            <Route path="/users" element={<UserPage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<ErrorPage message="Ничего не найдено по данному адресу" />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
