import { UserData, UsersAuthData } from "@nagano_crm/shared/auth/user.interface";
import { ComponentType } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import AuthComponent from "./auth.component";
import { AuthProps } from "./auth.types";

import { ApplicationStore } from "~/app/application.store";
import { authenticate, getUser, handleRunCheckIsAuthAction, setUserAuthDataAction } from "~/app/auth/auth.actions";

interface Props {
  user: UserData | null;
  isAuth: boolean | null;
}

const mapStateToProps: (store: ApplicationStore) => Props = store => ({
  user: store.auth.user,
  isAuth: store.auth.isAuth
});
const mapDispatchToProps: (dispatch: Dispatch) => {
  handleRunCheckIsAuthAction: () => void;
  setUserAuthDataAction: (authData: UsersAuthData) => void;
  authenticate: () => void;
  getUser: () => void;
} = dispatch => ({
  handleRunCheckIsAuthAction: (): void => {
    dispatch(handleRunCheckIsAuthAction());
  },
  setUserAuthDataAction: (authData): void => {
    dispatch(setUserAuthDataAction(authData));
  },
  authenticate: (): void => {
    dispatch(authenticate());
  },
  getUser: (): void => {
    dispatch(getUser());
  }
});

export const Auth: ComponentType<AuthProps> = connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
