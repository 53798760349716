import React, { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";

import { OrdersTableComponent } from "./orders-table/orders-table.component";
import styles from "./order.module.scss";

import { createNewOrderAction } from "~/app/order/order.actions";
import { ApplicationStore } from "~/app/application.store";
import { OrderSidebar } from "~/view/components/sidebars/order/order.component";

const OrderPageComponent: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const isOpenOrderSidebar: boolean = useSelector((state: ApplicationStore) => state.order.isOpenSidebarOrder);
  return (
    <>
      <OrdersTableComponent />
      <div className={styles.btnOrderNew} onClick={(): void => dispatch(createNewOrderAction())}>
        +
      </div>
      {isOpenOrderSidebar && <OrderSidebar />}
    </>
  );
};

export default OrderPageComponent;
