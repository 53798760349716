import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { SellingPointBase } from "@nagano_crm/shared/settings/settings.types";

import { SidebarCoreComponent } from "../../../sidebar-core/sidebar-core.component";
import styles from "../../../sidebar.module.scss";
import useValidation, { UseValidationResult } from "../../../../input-validation/useValidation";
import useIsFormsFieldsEmpty from "../../../../check-fields/useIsFormsFieldsEmpty";

import pointStyles from "./selling-point.module.scss";

import { DropDownList } from "~/view/components/dropdown-list/dropdown-list.component";
import PhoneInput from "~/view/components/phone-input/phone-input.component";
import { selectSellingPointByCurrentUid } from "~/app/settings/settings.selector";
import settingsActions from "~/app/settings/settings.actions";
import nomenclaturesActions from "~/app/nomenclatures/nomenclatures.actions";

export const SellingPoint: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  // const nomenclaturesCategoriesList: Record<string, NomenclatureCategoryBase> = useSelector(
  //   (state: ApplicationStore) => state.nomenclature.listCategories
  // );
  const sellingPoint: SellingPointBase | null = useSelector(selectSellingPointByCurrentUid);

  const [sellingPointChangeForm, setSellingPointChangeForm]: [
    SellingPointBase | null,
    React.Dispatch<React.SetStateAction<SellingPointBase>>
  ] = useState<SellingPointBase>({} as SellingPointBase);

  const [sellingPointForm, setSellingPointForm]: [
    SellingPointBase,
    React.Dispatch<React.SetStateAction<SellingPointBase>>
  ] = useState<SellingPointBase>({ ...sellingPoint, ...sellingPointChangeForm });

  const [isFormChanged, setIsFormChanged]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);

  const requiredFields: { name: string; value: string | undefined | null; error: string }[] = [
    { name: "name", value: sellingPointChangeForm?.name, error: "Введите название" }
  ];

  const { validationErrors, setShowErrors, getErrorMessage }: UseValidationResult = useValidation(
    requiredFields,
    sellingPointChangeForm
  );

  useEffect(() => {
    setSellingPointForm({ ...sellingPoint, ...sellingPointChangeForm });
  }, [sellingPoint, sellingPointChangeForm]);

  const handleCloseSidebar: () => void = useCallback(
    () => dispatch(settingsActions.changeCurrentSellingPoint(null)),
    [dispatch]
  );

  const handleSaveSellingPoint: (sellingPoint: SellingPointBase) => void = useCallback(
    (sellingPoint: SellingPointBase) => {
      setShowErrors(true);
      if (validationErrors.length) {
        return;
      }
      if (sellingPoint.uid) {
        dispatch(settingsActions.updateSellingPoint(sellingPoint));
      } else {
        dispatch(settingsActions.createSellingPoint(sellingPoint));
      }
    },
    [dispatch, validationErrors]
  );

  const handleSellingPointChange: (target: { name: string; value: string | null }) => void = (target: {
    name: string;
    value: string | null;
  }) => {
    setIsFormChanged(true);
    setSellingPointChangeForm(prevState => {
      return {
        ...prevState,
        [target.name]: target.value
      } as SellingPointBase;
    });
  };

  const handleSellingPointDelete: (sellingPoint: SellingPointBase) => void = useCallback(
    (sellingPoint: SellingPointBase) => {
      dispatch(nomenclaturesActions.deleteNomenclature(sellingPoint.uid));
    },
    [dispatch]
  );

  useEffect(() => {
    setIsFormChanged(!useIsFormsFieldsEmpty(sellingPointChangeForm));
  }, [sellingPointChangeForm]);

  const directors: string[] = [
    "Кардашин Вячеслав Витальевич",
    "Веселов Максим Сергеевич",
    "Кардашина Елена Александровна",
    "Тихомирова Татьяна Ивановна",
    "Веселова Ульяна Львовна"
  ];

  return (
    <SidebarCoreComponent
      isFormChanged={isFormChanged}
      actionOnClickSave={(): void => handleSaveSellingPoint(sellingPointForm)}
      actionOnClickClose={(): void => handleCloseSidebar()}
      actionOnClickDelete={sellingPoint?.uid ? (): void => handleSellingPointDelete(sellingPointForm) : undefined}
    >
      <div className={classNames(styles.sidebarWrapper, pointStyles.sidebarWrapper)}>
        <header>
          <input
            type="text"
            name="name"
            placeholder="Название точки"
            value={sellingPointForm?.name ?? ""}
            onChange={(e): void => handleSellingPointChange(e.target)}
          />
          {getErrorMessage("name") && <span>{getErrorMessage("name")}</span>}
        </header>
        <div className={styles.mainWrapper}>
          <div className={styles.inputWrappers}>
            <div className={classNames(styles.inputWrapper, pointStyles.inputWrapper)}>
              <div className={styles.inputLabel}>Руководитель</div>
              <div className={styles.inputBox}>
                <DropDownList
                  displayValue={sellingPointForm?.director ?? "Выберите"}
                  data={directors.map(director => ({
                    name: director,
                    value: director
                  }))}
                  value={sellingPointForm?.director ?? ""}
                  name="director"
                  handleChange={(data): void => handleSellingPointChange(data)}
                  color="default"
                />
              </div>
            </div>
            <div className={classNames(styles.inputWrapper, pointStyles.inputWrapper)}>
              <div className={styles.inputLabel}>График работы</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="workingHours"
                  placeholder="График работы"
                  value={sellingPointForm?.workingHours ?? ""}
                  onChange={(e): void => handleSellingPointChange(e.target)}
                />
              </div>
            </div>
            <div className={classNames(styles.inputWrapper, pointStyles.inputWrapper)}>
              <div className={styles.inputLabel}>Адрес</div>
              <div className={styles.inputBox}>
                <input
                  type="text"
                  name="address"
                  placeholder="Адрес"
                  value={sellingPointForm?.address ?? ""}
                  onChange={(e): void => handleSellingPointChange(e.target)}
                />
              </div>
            </div>
            <div className={classNames(styles.inputWrapper, pointStyles.inputWrapper)}>
              <div className={styles.inputLabel}>Телефон</div>
              <div className={styles.inputBox}>
                <PhoneInput
                  type="text"
                  name="phone"
                  placeholder="Введите телефон"
                  value={sellingPointForm?.phone ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => handleSellingPointChange(e.target)}
                  disableSearch={true}
                />
                {getErrorMessage("phone") && <span>{getErrorMessage("phone")}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidebarCoreComponent>
  );
};
