import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";

import Menu from "./components/menu/menu.component";

export type ComponentPropTypes = {
  children?: React.ReactNode;
  className?: string;
};

const Layout: React.FC<ComponentPropTypes> = (): ReactElement => (
  <>
    <Menu />
    <Outlet />
  </>
);
export default Layout;
